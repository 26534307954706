export default {
  blinkSlogan: 'Blink - додаток для прибиральників будівель',
  all: 'все',
  hello: 'Привіт',
  description: 'опис',
  ok: 'в порядку',
  cancel: 'Скасувати',
  reset: 'Скинути до замовчування',
  edit: 'Редагувати',
  finish: 'Повний',
  notice: 'Повідомлення',
  content: 'Зміст',
  back: 'Назад',
  close: 'Закрити',
  backToHome: 'Повернутися на головну сторінку',
  proceed: 'Далі',
  done: 'Готовий',
  delete: 'Видалити',
  successful: 'Успішний',
  error: 'помилка',
  ERROR_OCCURRED: 'Сталася помилка або дані неправильні. Будь ласка спробуйте ще раз.',
  internetRequired: 'Потрібен Інтернет',
  configuration: 'конфігурація',
  logo: 'логотип',
  uploadLogo: 'Завантажити логотип',
  deleteLogo: 'Видалити логотип',
  duplicate: 'дублікат',
  importExport: 'Імпорт-експорт',
  import: 'імпорт',
  doImport: 'Імпорт',
  export: 'експорт',
  doExport: 'Експорт',
  excelExport: 'Експорт Excel',
  excelExportDownloaded: 'Експорт Excel завантажено',
  excelExportError: 'Помилка експорту в Excel',
  pdfDownload: 'Завантажити PDF',
  pdfDownloaded: 'PDF завантажено',
  configSaved: 'Конфігурацію збережено.',
  send: 'Надіслати',
  connecting: 'Підключити...',
  or: 'або',
  select: 'Виберіть',
  today: 'Сьогодні',
  invalidValue: 'Недійсне значення',
  camera: 'камера',
  gallery: 'галерея',
  files: 'файли',
  upload: 'Завантажте файли',
  chooseFiles: 'вибрати файли',
  filesTransferring: 'Надіслати файли...',
  filesTransferringWait: 'Зачекайте, поки файли будуть передані.',
  filesTransferred: 'Файли передано успішно.',
  download: 'Завантажити',
  showInactive: 'Показати неактивні',
  copyLink: 'Копіювати посилання',
  linkCopied: 'Посилання скопійовано в буфер обміну.',
  permissions: 'Дозволи',
  email: 'електронна пошта',
  emailConfig: 'Конфігурація електронної пошти',
  title: 'назва',
  user: 'користувача',
  logout: 'Вийти',
  system: 'система',
  appVersion: 'Версія (програма)',
  apiVersionCore: 'версія\n(Core API)',
  apiVersionCheck: 'версія\n(Перевірте API)',
  deviceInfo: 'Інформація про пристрій',
  installBlinkTime: 'Встановити Blink Time',
  installBlinkMe: 'Встановіть Blink Me',
  installing: 'Встановлення версії {{version}}...',
  companyRequired: 'Виберіть клієнта',
  companyRequiredInfo: 'Ви повинні вибрати клієнта, щоб продовжити як системний адміністратор.',
  pleaseChoose: 'Будь-ласка оберіть',
  pleaseFill: 'Будь ласка, заповніть',
  noEntitiesFound: 'Не вдалося знайти предмети.',
  addCustom: 'Створити елемент:',
  typeToSearchText: 'Будь ласка, введіть два або більше символів...',
  loading: 'Дані завантажуються...',
  offline: 'Офлайн',
  youAreOffline: 'Ви офлайн!',
  by: 'від',
  date: 'Дата',
  time: 'час',
  searchTitle: 'шукати',
  searchTerm: 'термін пошуку',
  searchLoginUser: 'Шукає працівників',
  searchLoginUserPlaceholder: "Пошук за прізвищем або ім'ям",
  location: "об'єкт",
  searchLocation: "Об'єкт пошуку",
  searchLocationPlaceholder: "Пошук за назвою об'єкта, номером або тегами",
  deactivate: 'Дезактивувати',
  active: 'Активний',
  inactive: 'Неактивний',
  tag: 'День',
  tags: 'теги',
  color: 'колір',
  icon: 'значок',
  language: 'Мова',
  general: 'Загалом',
  yes: 'Так',
  no: 'Немає',
  searchThrough: 'Пошук',
  name: 'Прізвище',
  create: 'Створити',
  save: 'зберегти',
  home: 'додому',
  profile: 'профіль',
  templates: 'шаблони',
  noAssignment: 'нерозподілений',
  sort: {
    change: 'змінити порядок',
    save: 'Зберегти замовлення',
    saved: 'Замовлення збережено.'
  },
  inProgress: 'В процесі',
  video_offline: 'Відео не можна переглядати в автономному режимі, підключіться до Інтернету.',
  location_offline: 'Об’єкт не зберігається в автономному режимі, підключіться до Інтернету, щоб отримати QR-код.',
  invalidObjectCode: "Немає дійсного об'єктного коду.",
  signature: {
    signature: 'Підпис',
    pleaseSign: 'Будь ласка, підпишіть',
    clickHereToSign: 'Натисніть тут, щоб підписати',
    addSignature: 'Додайте підпис',
    save: 'Взяти на себе',
    delete: 'Видалити'
  },
  formError: {
    min: 'Значення має бути більше ніж {{value}}.',
    min2: 'Значення має бути більше або дорівнювати {{value}}.',
    max: 'Значення має бути менше або дорівнювати {{value}}.',
    requiredBecause: 'Необхідне значення, оскільки {{name}} заповнено.',
    isRequired: '{{name}} є обов’язковим.',
    numberInUse: 'Табельний номер вже використовується.',
    maxlength: '{{name}} не може містити більше {{value}} символів.',
    email: 'Будь ласка, введіть дійсну адресу електронної пошти.',
    faultyBlinkId: 'Blink ID неправильний'
  },
  scan: {
    choose: 'Призначте новий Blink ID',
    text: 'Відскануйте QR-код на реєстраційній картці.',
    scan_now: 'Сканувати зараз',
    enter_manually: 'Введіть вручну'
  },
  removeImage: 'Чітке зображення',
  tickets: { assignee: 'редактор', dueTo: 'Дійсний до' },
  ngSelect: {
    typeToSearch: 'Будь ласка, введіть 3 літери або більше.',
    loading: 'завантаження даних...',
    notFound: '{{title}} не знайдено.',
    clearAll: 'Скинути до замовчування'
  },
  help: {
    pageTitle: 'Довідка',
    text1: 'Наша служба підтримки буде рада допомогти вам, якщо у вас виникнуть запитання:',
    phone: 'телефон',
    mail: 'електронна пошта',
    information: 'інформація',
    documentation: 'Онлайн документація',
    documentationText: 'У нашій онлайн-документації ви знайдете інструкції та відео для всіх важливих областей Blink.',
    support: 'Підтримка'
  },
  REGISTRATION: {
    WELCOME: 'Ласкаво просимо!',
    chooseMethod: 'Який метод ви б хотіли використати для входу?',
    withBlinkId: 'Blink ID card',
    withCredentials: "Ім'я користувача Пароль",
    REGISTER: 'реєструвати',
    ALREADY_REGISTERED: 'У мене є обліковий запис Blink.',
    INVALID_CARD: 'Картка недійсна.',
    noBlinkId: 'Відсканований QR-код не є дійсним Blink ID',
    scanBlinkId: 'Сканувати Blink ID',
    ERROR: 'Не вдалося завершити реєстрацію. Укажіть іншу електронну адресу або номер мобільного телефону.',
    NO_INTERNET_EXPLORER: 'На жаль, Blink не підтримує Internet Explorer. Використовуйте Microsoft Edge, Firefox або Chrome. Дякую за розуміння.',
    loginNow: 'Зареєструватися зараз',
    help: 'Допомога з реєстрацією',
    helpText: "Існує два способи входу:<br/><br/><b>Увійти за допомогою картки Blink ID</b><br/><br/>Якщо у вас є картка Blink ID, виберіть цей метод. Під час першої реєстрації вас запитають адресу електронної пошти або номер мобільного телефону. Це нам потрібно, щоб надіслати вам одноразовий пароль для реєстрації. Вам не потрібно пам'ятати одноразовий пароль. Якщо ви ввійдете знову, ми надішлемо вам новий одноразовий пароль.<br/><br/><b>Увійдіть за допомогою імені користувача та пароля</b><br/><br/>Ви отримаєте лише свій ім'я користувача та пароль за запитом системного адміністратора. Цей метод доступний лише для користувачів із правами адміністратора.<br/><br/><b>Підтримка</b><br/><br/>Якщо у вас виникли проблеми з входом, напишіть електронний лист до служби підтримки @blink- time.de або за телефоном 0911 240 330 0.",
    userOffline: 'Щоб використовувати Blink me, підключіться до Інтернету.',
    login: 'Зареєструватися зараз',
    codeError: 'Перевірте введені дані та повторіть спробу.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Щиро вітаю!',
    YOU_DID_IT: 'Ти зробив це.',
    SIGNED_IN: 'Зареєстрований',
    SIGNED_IN_TEXT: 'Ви успішно ввійшли в систему і тепер можете користуватися програмою.',
    START_USAGE: 'Тепер ви можете використовувати додаток.',
    START: 'почати'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Вам було надіслано електронний лист, за допомогою якого ви можете змінити свої дані для входу.',
    FORGOT_PASSWORD: 'Забули свій пароль?',
    FORGOT_PASSWORD_TEXT: 'Будь ласка, введіть адресу електронної пошти та цільову систему, щоб скинути пароль.',
    FORGOT_PASSWORD_PLACEHOLDER: 'електронна пошта',
    errors: {
      notAllowed: 'Неможливо скинути пароль для цього користувача.',
      invalidToken: 'Термін дії коду для скидання пароля закінчився. Будь ласка, надішліть запит на новий код.'
    },
    reset: {
      backToRoot: 'Назад на сторінку входу',
      renewCode: 'Запит на новий код',
      resetPassword: 'скинути пароль',
      success: 'Ваш пароль успішно скинуто.',
      password1: 'ваш новий пароль',
      password2: 'Повторіть пароль',
      validationError: 'Пароль не відповідає нашим інструкціям щодо паролів!',
      passwordsNotEqual: 'Паролі не ідентичні! Будь ласка, перевірте свій запис.',
      passwordValidation: 'Пароль має бути не менше 6 символів і містити букву, цифру та спеціальний символ.'
    }
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Перевірте дані',
    TEXT: 'Якщо нам знадобиться змінити ваш пароль, нам знадобиться ваша адреса електронної пошти та, можливо, номер мобільного телефону.',
    EMAIL: 'Адреса електронної пошти',
    MOBILE: 'Номер мобільного телефону'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Введіть код',
    EMAIL_TEXT: 'Введіть код, який ми надіслали на таку електронну адресу:',
    SMS_TEXT: 'Введіть код, який ми надіслали на наступний мобільний номер:',
    NO_CODE: 'Я не отримав код.',
    CODE_LENGTH_ERROR: 'Введений код містить більше шести символів. Введіть свій код ще раз.',
    CODE_SENT: 'Код надіслано.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Немає коду',
    TEXT1: 'Не отримали код?',
    RESEND_CODE: 'Надіслати код знову',
    TEXT2: 'Якщо ваша адреса електронної пошти або номер мобільного телефону, збережені в системі, змінилися, зверніться до адміністратора.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Будь ласка, введіть свій Blink ID і код. Ви знайдете всю інформацію у своїй реєстраційній картці.',
    textCredentials: "Ваш системний адміністратор повинен надати вам ім'я користувача та пароль.",
    BLINK_ID_INVALID: 'Blink ID неправильний',
    loginCardNotFound: 'Blink ID або вже зайнято, або не існує',
    WRONG_CREDENTIALS: 'Введені дані неправильні. Будь ласка, перевірте свій запис.',
    loginViaBlinkId: 'Увійдіть за допомогою BlinkID',
    loginViaCredentials: 'Реєстрація з даними доступу',
    CODE: 'код',
    codePlaceholder: 'Ваш персональний код доступу',
    company: 'система',
    password: 'пароль',
    FORGOT_PASSWORD: 'Я забув свій пароль',
    username: 'електронна пошта'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'ID',
    TEXT_1: 'Щоб ми могли чітко вас ідентифікувати, ми надішлемо вам одноразовий код через SMS або електронну пошту.',
    TEXT_2: 'Будь ласка, вкажіть свій номер мобільного телефону або електронну адресу.'
  },
  update: {
    update: 'Оновити',
    latestVersion: 'Доступна нова версія. Оновіть програму, щоб скористатися всіма перевагами.',
    warnVersion: 'Ви використовуєте застарілу версію цього додатка. Оновіть якомога швидше.',
    blockVersion: 'Ви використовуєте застарілу версію цієї програми, яка більше не підтримується. Виконайте оновлення зараз.'
  },
  ERROR: {
    ERROR_400: 'Сталася помилка. Будь-ласка спробуйте пізніше. (Код помилки: 400)',
    ERROR_403: 'Ви не маєте дозволу на виконання цієї дії',
    REFRESH_TOKEN_403: 'Ваші дані доступу більше недійсні. Увійдіть знову!',
    ERROR_500: 'Сталася помилка. Будь-ласка спробуйте пізніше. (Код помилки: 500)'
  },
  list: {
    entityPluralDefault: 'Результати',
    emptyState: '{{entityPlural}} не існує.',
    noResults: '{{entityPlural}} не знайдено.',
    selectAll: 'Вибрати все {{entityPlural}}',
    massAction: 'Акція на {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Вибрати все {{count}}',
    massActionDeSelectAllGlobal: 'Скасувати вибір усіх {{count}}'
  },
  languages: {
    ar: 'арабська',
    de: 'Німецький',
    es: 'Іспанська',
    fr: 'французька',
    hr: 'Хорватська',
    it: 'італійська',
    pt: 'португальська',
    ru: 'російський',
    bg: 'Болгарська',
    en: 'англійська',
    fa: 'перська',
    el: 'грецька',
    hu: 'угорська',
    pl: 'польський',
    ro: 'румунська',
    tr: 'турецька',
    uk: 'українська',
    cs: 'чеська',
    sk: 'словацький'
  },
  now: 'Зараз',
  allCompanies: 'Всі клієнти',
  createDate: 'Дата створення',
  company: 'клієнт',
  new: 'новий',
  of: 'від',
  request: {
    deleteTitle: 'Видалити {{item}}',
    confirmDelete: 'Ви дійсно хочете видалити запис "{{itemName}}"?',
    itemAdded: '{{item}} створено.',
    itemAddedError: '{{item}} не вдалося створити.',
    itemSaved: '{{item}} збережено.',
    itemSavedError: '{{item}} не вдалося зберегти.',
    itemRemoved: '{{item}} видалено.',
    itemRemovedError: '{{item}} не вдалося видалити.'
  },
  news: { pageTitle: 'Новини', edit: 'Редагувати новини' },
  profiles: {
    loginProfiles: 'Профілі входу',
    add: 'Профіль входу',
    logout: 'Якщо ви вийдете, усі локальні дані та профілі будуть видалені! Ви хочете скасувати підписку?',
    login: 'зареєструватися',
    deleteConfirmation: 'Ви справді хочете видалити профіль?',
    changed: 'Профіль успішно змінено',
    deleted: 'Профіль успішно видалено'
  },
  deleteAttachment: {
    confirmTitle: 'Видалити вкладення',
    confirmText: 'Ви впевнені, що хочете видалити цей вкладений файл?',
    deleted: 'Вкладення видалено'
  },
  comments: {
    addComment: 'додати коментар',
    addCommentInfo: 'Коментар доступний для всіх, хто може переглядати цей квиток.',
    comment: 'коментар',
    deleteComment: 'Видалити коментар',
    deleteCommentConfirmation: 'Ви справді хочете видалити коментар?',
    commentCreated: 'Коментар збережено.',
    commentDeleted: 'Коментар видалено'
  },
  confirm: 'Підтвердити',
  firstName: "ім'я",
  lastName: 'Прізвище',
  status: 'статус',
  attachment: 'вкладення',
  timeFramePicker: {
    title: 'Виберіть період',
    today: 'Сьогодні',
    currentWeek: 'Поточний тиждень',
    currentMonth: 'Поточний місяць',
    custom: 'Custom',
    startDate: 'почати',
    endDate: 'Кінець',
    lastWeek: 'Минулого тижня',
    lastMonth: 'Останній місяць'
  },
  cw: 'кВт',
  contextMenu: { openInNewTab: 'Відкрити посилання в новій вкладці' }
};
