import { Injectable } from "@angular/core";
import { HttpClient, HttpContext, HttpContextToken, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import buildQuery, { QueryOptions } from "odata-query";
import { Observable } from "rxjs";
import { UiLoadingService } from "@blink/ui";
import { SessionRepository } from '../core';

export enum BlinkService {
  None,
  Core,
  Check,
  Active,
  BaseData,
  Automation,
  Ai,
  ELearning,
  Insight,
  Toolsense,
  Invoicing,
  WorkOrder,
  Order
}

export const BLINK_SERVICE_HTTP_TOKEN =
  new HttpContextToken<BlinkService>(() => BlinkService.None);

export const HANDLE_ERROR =
  new HttpContextToken<boolean>(() => true);

export const SKIP_AUTH =
  new HttpContextToken<boolean>(() => false);

function clearViewProperties<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map(o => clearViewProperties(o))  as T;
  }

  const body = { ...obj };
  const isFirstLetterLowerCase = (str: string) => /[a-z]/.test(str[0]);

  const removeKeys = Object.keys(obj)
    .filter(key => isFirstLetterLowerCase(key));

  removeKeys.forEach(rk => delete body[rk]);

  return body;
}

/**
 * For API Safety
 * `api/v${number}` | `odata/v${number}`
 */
export type ApiTypeAndVersion = `api/v${number}` | `odata/v${number}`;

export interface ListResponse {
  value: any;
}

export interface TypedQuery<T> {
  select?: (keyof T)[];

  [x: string]: any;
}

// improve create some cool typing for (query / request) options
// improve: api and odata behave different, odata-query is not really helping for api requests,
//          so add optimized params support
@Injectable({ providedIn: "root" })
export class ApiManager {
  constructor(public http: HttpClient,
              private loading: UiLoadingService,
              private sessionRepository: SessionRepository) {
  }

  createApiForEndpoint<T>(api: ApiTypeAndVersion, url: string, service: BlinkService = BlinkService.Core) {
    return new Endpoint<T>(this, api, url, service);
  }

  get(api: ApiTypeAndVersion, url: string, options?, count = false): Observable<any> {
    const converted = this.convertOptions(options);
    if (converted.single) {
      return this.loading.showReadLoaderUntilCompleted(
        this.http.get(`${api}/${url}${converted.query}`, converted.httpOptions).pipe(
          map(response => {
            delete response['@odata.context']
            delete response['@odata.type']
            return response;
          })
        )
      );
    } else {
      return this.loading.showReadLoaderUntilCompleted(
        this.http.get<ListResponse>(`${api}/${url}${converted.query}`, converted.httpOptions)
      ).pipe(map(response => {
          if (count) {
            return {
              count: response['@odata.count'],
              data: response.value
            }
          } else {
            return countMapper(options, response.value);
          }
        })
      );
    }
  }

  post(api: ApiTypeAndVersion, url: string, options, showLoader = false): Observable<any> {
    const converted = this.convertOptions(options);
    return this.loading.showWriteLoaderUntilCompleted(
      this.http.post(`${api}/${url}${converted.query}`, converted.httpOptions.body, converted.httpOptions),
      showLoader
    ).pipe(
      // startWithTap(() => this.loadingService.present()),
      // finalize(() => this.loadingService.dismiss())
    );
  }

  delete(api: ApiTypeAndVersion, url: string, options): Observable<any> {
    const converted = this.convertOptions(options);
    return this.loading.showWriteLoaderUntilCompleted(
      this.http.delete(`${api}/${url}${converted.query}`, converted.httpOptions)
    );
  }

  request(type, api: ApiTypeAndVersion, url: string, options) {
    const converted = this.convertOptions(options);
    const requestOptions = converted.httpOptions;
    const request$ = this.http.request<any>(type, `${api}/${url}${converted.query}`, requestOptions);
    if (type === "GET") {
      return this.loading.showReadLoaderUntilCompleted(request$);
    }
    return this.loading.showWriteLoaderUntilCompleted(request$);
  }

  convertOptions(options) {
    const result = {
      query: "",
      httpOptions: {
        body: {},
        responseType: undefined,
        context: new HttpContext()
          .set(HANDLE_ERROR, true),
        headers: new HttpHeaders()
          .set('Accept', '*; charset=utf-8')
          .set('Accept-Language', this.sessionRepository.language)
          .set('Accept-Language', this.sessionRepository.language)
      },
      single: options && options.key
    };

    if (options) {
      if (options.key === "single") {
        delete options.key;
      }
      if (options.body) {
        result.httpOptions.body = clearViewProperties(options.body);
        delete options.body;
      }
      if (options.rawBody) {
        result.httpOptions.body = options.rawBody;
        delete options.rawBody;
      }
      if (options.service) {
        result.httpOptions.context.set(BLINK_SERVICE_HTTP_TOKEN, options.service);
        delete options.service;
      }
      if (options.handleError !== undefined && !options.handleError) {
        result.httpOptions.context.set(HANDLE_ERROR, options.handleError);
        delete options.handleError;
      }
      if (options.skipAuth) {
        result.httpOptions.context.set(SKIP_AUTH, true);
      }
      if (options.oDataReturnRepresentation) {
        result.httpOptions.headers = result.httpOptions.headers.append('Prefer', 'return=representation')
      }
      if (options.httpOptions) {
        result.httpOptions = {
          ...result.httpOptions,
          ...options.httpOptions
        };
        delete options.httpOptions;
      }
      result.query = buildQuery(options);
    }
    return result;
  }
}

// improve: those generics are terrible
export class Endpoint<T> {
  constructor(private apiManager: ApiManager,
              private api: ApiTypeAndVersion,
              private url: string,
              private service: BlinkService) {
  }

  get<R extends T | Array<T>>(options?: Partial<QueryOptions<any>>): Observable<R> {
    (options as any).service = this.service;
    return this.apiManager.get(this.api, this.url, options, !!options?.count);
  }

  post(options, showLoader = false): Observable<T> {
    (options as any).service = this.service;
    return this.apiManager.post(this.api, this.url, options, showLoader) as unknown as Observable<T>;
  }

  patch(options) {
    return this.request("PATCH", options);
  }

  put(options) {
    return this.request("PUT", options);
  }

  delete(options) {
    return this.request("DELETE", options);
  }

  private request(type, options) {
    (options as any).service = this.service;
    return this.apiManager.request(type, this.api, this.url, options);
  }
}

const countMapper = (options, entities) => {
  const countProperties = [];
  if (options && options.expand) {
    for (const key of Object.keys(options.expand)) {
      if (options.expand[key].count) {
        countProperties.push(key);
      }
    }
    return entities.map(e => {
      countProperties.forEach(cp => {
        e[cp] = new Array(e[`${cp}@odata.count`]);
      });
      return e;
    });
  }
  return entities;
};
