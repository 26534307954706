import { Component, forwardRef, Input } from '@angular/core';
import { AbstractInputComponent } from '../abstracts/input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-input',
  templateUrl: 'ui-input.component.html',
  styleUrls: ['ui-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiInputComponent),
    multi: true
  }]
})
export class UiInputComponent extends AbstractInputComponent {
  /**
   * When true only whole numbers (integers) are possible.
   */
  @Input() wholeNumber = false;
  /**
   * Enter unit appends like 'minutes'. Use translation tag.
   */
  @Input() labelAppend: string;
  @Input() marginBottom = true;
  @Input() labelPlacement: 'stacked' | 'floating' = 'stacked';


  rows = 5;

  constructor() {
    super();
    window['inputComp'] = this;
  }

  get validationErrors() {
    return this.control?.touched && this.control.errors;
  }

  override get value() {
    return this._value;
  }

  override set value(value) {
    this._value = this.type === 'number' && value !== '' ? Number(value) : value;
    this.notifyValueChange();
  }

  numberOnlyValidation(event: KeyboardEvent) {
    const key = event.key;
    if (
      this.wholeNumber
      && key !== 'Backspace'
      && key !== 'Tab'
    ) {
      if (isNaN(Number(key))) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }
}
