import { Component, ElementRef, Inject, InjectionToken, Input, ViewChild } from "@angular/core";
import { logComponent } from "@blink/util";
import H from "@here/maps-api-for-javascript";

export const HERE_API_KEY = new InjectionToken<string>('environment.hereMaps.apiKey');

const svgMarker = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="30.000000pt" height="30.000000pt" viewBox="0 0 200.000000 200.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
fill="{FILL}" stroke="{STROKE}">
<path d="M825 1911 c-108 -31 -175 -71 -261 -156 -128 -126 -184 -257 -184
-435 0 -47 7 -112 15 -145 17 -64 502 -1052 527 -1072 8 -7 28 -15 44 -18 63
-12 70 -2 340 525 140 272 262 520 271 550 12 38 18 89 18 165 0 94 -4 122
-28 190 -35 102 -92 189 -170 261 -69 62 -191 126 -275 143 -79 16 -227 12
-297 -8z m216 -341 c77 -22 140 -124 124 -201 -19 -92 -104 -155 -196 -147
-122 12 -196 147 -140 256 23 45 46 66 91 86 41 18 72 19 121 6z"/>
</g>
</svg>`;

@Component({
  selector: "ui-map",
  templateUrl: "./b-map.component.html",
  styleUrls: ["b-map.component.scss"]
})
export class BMapComponent {
  // apiLoaded: Observable<boolean>;

  @ViewChild("map") mapDiv?: ElementRef;
  mapLoaded = false;
  @Input() tolerance: number;
  private map?: H.Map;

  constructor(@Inject(HERE_API_KEY) private apiKey: string) {
    logComponent(this);
    window.addEventListener('resize',
      () => this.map.getViewPort().resize());
  }

  _latitude: number;

  get latitude() {
    return this._latitude;
  }

  @Input() set latitude(value) {
    this._latitude = value;
    this.initMap();
  }

  _longitude: number;

  get longitude() {
    return this._longitude;
  }

  @Input() set longitude(value) {
    this._longitude = value;
    this.initMap();
  }

  initMap() {
    setTimeout(() => {
      if (!this.map && this.mapDiv != null && this.latitude && this.longitude) {
        // instantiate a platform, default layers and a map as usual
        const platform = new H.service.Platform({
          apikey: this.apiKey
        });

        const defaultLayers = platform.createDefaultLayers();
        // console.log('defaultLayers', defaultLayers);
        // const layers = platform.createDefaultLayers();
        const map = new H.Map(
          this.mapDiv.nativeElement,
          defaultLayers['vector'].normal.map,
          {
            center: { lat: this.latitude, lng: this.longitude },
            zoom: 15
          }
        );
        H.ui.UI.createDefault(map, defaultLayers, "de-DE");

        // ui.getControl("scalebar").setVisibility(true);

        // Create a marker icon from an image URL:
        const icon = new H.map.Icon(svgMarker.replace("{FILL}", "#000").replace("{STROKE}", "#000"));

        // Create a marker using the previously instantiated icon:
        const marker = new H.map.Marker({ lat: this.latitude, lng: this.longitude }, { data: null, icon: icon });

        // Add the marker to the map:
        map.addObject(marker);

        if (this.tolerance) {
          this.addCircleToMap(map)
        }

        this.map = map;
        this.mapLoaded = true;
        document.querySelector('canvas').style.borderRadius = "10px";
      }
    }, 500);

  }

  addCircleToMap(map) {
    map.addObject(new H.map.Circle(
      // The central point of the circle
      { lat: this.latitude, lng: this.longitude },
      // The radius of the circle in meters
      100,
      {
        data: null,
        style: {
          strokeColor: 'rgba(241, 199, 22, 0.9)', // Color of the perimeter
          lineWidth: 2,
          fillColor: 'rgba(241, 199, 22, 0.2)',  // Color of the circle
          equals: function (other) {
            return this === other;
          },
          getCopy: function () {
            return this;
          },
          lineCap: 'round',
          lineJoin: 'round',
          miterLimit: 10,
          lineDash: [],
          lineDashOffset: 0,
        }
      }
    ));
  }
}
