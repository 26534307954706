import { InjectionToken } from '@angular/core';

export const ENV_PROD = new InjectionToken<boolean>('environment.prod');
export const ENV_VARS_SYSTEM = new InjectionToken<string>('environment.variables.system');

export const getSystemFromCheckUrl = (url: string) => {
  if (!url.includes('-check.blink.online') && !url.includes('.blink-check.de')) {
    return '';
  }
  if (url.includes('-check.blink.online')) {
    return removeProtocol(url.split('-check.blink.online')[0]);
  }
  return removeProtocol(url.split('.blink-check.de')[0]);
}

export const systemFromCheckUrl = (isProduction: boolean, fallbackSystem: string) => {
  if (isProduction) {
    const url = String(window.location.hostname);
    return getSystemFromCheckUrl(url);
  } else {
    return fallbackSystem
  }
};

export const blinkUrl = (system: string) => `https://${system}.blink.online`;
export const blinkApiUrl = (system: string) => `https://${system}-api.blink.online`;
export const activeServiceUrl = (system: string) => `https://${system}-active-service.blink.online`

export const checkUrl = (system: string) => `https://${system}-check.blink.online`;
export const checkServiceUrl = (system: string) => `https://${system}-check-service.blink.online`;

export const orderUrl = (system: string, auth_token: string, returnUrl?: string) => `https://${system}-order.blink.online/api/v1/Access/LoginExternalRedirect?auth=${auth_token}${returnUrl ? '&returnUrl=' + returnUrl : ''}`;

export const baseDataServiceUrl = (system: string) => `https://${system}-basedata-service.blink.online`;
export const automationServiceUrl = (system: string) => `https://${system}-automation-service.blink.online`;
export const aiServiceUrl = (system: string) => `https://${system}-ai-service.blink.online`;
export const insightServiceUrl = (system: string) => `https://${system}-insight-service.blink.online`;
export const baseDataUrl = (system: string) => `https://${system}-basedata-service.blink.online`;
export const eLearningServiceUrl = (system: string) => `https://${system}-elearning-service.blink.online`;
export const toolsenseServiceUrl = (system: string) => `https://${system}-toolsense-service.blink.online`;
export const invoicingServiceUrl = (system: string) => `https://${system}-invoicing-service.blink.online`;
export const workOrderServiceUrl = (system: string) => `https://${system}-workorder-service.blink.online`;
export const orderServiceUrl = (system: string) => `https://${system}-order.blink.online`;


export const blinkUrls = (system: string) => ({
  blink: blinkUrl(system),
  blinkApi: blinkApiUrl(system),
  check: checkServiceUrl(system),
  baseData: baseDataServiceUrl(system),
  automation: automationServiceUrl(system),
  ai: aiServiceUrl(system),
  eLearning: eLearningServiceUrl(system),
  active: activeServiceUrl(system),
  insight: insightServiceUrl(system),
  toolsense: toolsenseServiceUrl(system),
  invoicing: invoicingServiceUrl(system),
  workOrder: workOrderServiceUrl(system),
  order: orderServiceUrl(system)
});

export interface BlinkUrls {
  blink: string;
  blinkApi: string;
  active: string;
  check: string;
  baseData: string;
  automation: string;
  ai: string;
  eLearning: string;
  insight: string;
  toolsense: string;
  invoicing: string;
  workOrder: string;
  order: string;
}

export const onlineDokuUrl = 'https://doku.blink.de/';
export const privacyPolicyUrl = 'https://www.blink.de/privacy/blink-apps/';
export const supportUrl = 'mailto:support@blink.de';

function removeProtocol(url) {
  return url.replace(/^https?:\/\//, '');
}
