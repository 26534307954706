export default {
  cancel: 'Anulare',
  blinkSlogan: 'Blink - Aplicația pentru curățătorii de clădiri',
  all: 'Toate',
  hello: 'Bună ziua',
  description: 'Descriere',
  ok: 'Ok',
  reset: 'Resetare',
  edit: 'Editați',
  finish: 'Încuietoare',
  notice: 'Notă',
  content: 'Conținut',
  back: 'Înapoi',
  close: 'Închideți',
  backToHome: 'Înapoi la pagina principală',
  proceed: 'Mai departe',
  done: 'Gata',
  delete: 'Ștergeți',
  successful: 'Succes',
  error: 'Eroare',
  ERROR_OCCURRED: 'A apărut o eroare sau datele nu sunt corecte. Vă rugăm să încercați din nou.',
  internetRequired: 'Internet necesar',
  configuration: 'Configurație',
  logo: 'Logo',
  uploadLogo: 'Încărcați logo-ul',
  deleteLogo: 'Ștergeți logo-ul',
  duplicate: 'Duplicat',
  importExport: 'Import / Export',
  import: 'Import',
  doImport: 'Import',
  export: 'Export',
  doExport: 'Export',
  excelExport: 'Export Excel',
  excelExportDownloaded: 'Export Excel descărcat',
  excelExportError: 'Exportul Excel a eșuat',
  pdfDownload: 'Descărcare PDF',
  pdfDownloaded: 'PDF descărcat',
  configSaved: 'Configurația a fost salvată.',
  send: 'Trimiteți',
  connecting: 'Conectați-vă...',
  or: 'sau',
  select: 'Selectați',
  today: 'Astăzi',
  camera: 'Camera',
  gallery: 'Galerie',
  files: 'Fișiere',
  upload: 'Încărcați fișiere',
  chooseFiles: 'Selectați fișierele',
  filesTransferring: 'Transmiteți fișiere...',
  filesTransferringWait: 'Vă rugăm să așteptați în timp ce fișierele sunt transmise.',
  filesTransferred: 'Fișierele au fost transmise cu succes.',
  download: 'Descărcați',
  showInactive: 'Arată inactiv',
  copyLink: 'Copiați link-ul',
  linkCopied: 'Linkul a fost copiat în clipboard.',
  permissions: 'Autorizații',
  email: 'E-mail',
  emailConfig: 'Configurație e-mail',
  title: 'Titlu',
  user: 'Utilizator',
  logout: 'Deconectați-vă',
  system: 'Sistem',
  appVersion: 'Versiune (App)',
  apiVersionCore: 'Versiunea\n(API de bază)',
  apiVersionCheck: 'Versiunea\n(Verificați API)',
  deviceInfo: 'Informații despre dispozitiv',
  installBlinkTime: 'Instalați timpul de clipire',
  installBlinkMe: 'Instalați Blink Me',
  installing: 'Versiunea {{version}} este instalată ...',
  companyRequired: 'Selectați clientul',
  companyRequiredInfo: 'Trebuie să selectați un client pentru a continua ca administrator de sistem.',
  pleaseChoose: 'Vă rugăm să selectați',
  pleaseFill: 'Vă rugăm să completați',
  noEntitiesFound: 'Nu a fost găsit niciun element.',
  addCustom: 'Creați elementul:',
  typeToSearchText: 'Vă rugăm să introduceți două sau mai multe caractere...',
  loading: 'Datele sunt încărcate...',
  offline: 'Offline',
  youAreOffline: 'Sunteți offline!',
  by: 'de la',
  date: 'Data',
  time: 'Timp',
  searchTitle: 'Căutare',
  searchTerm: 'Cuvânt cheie de căutare',
  searchLoginUser: 'Căutarea angajaților',
  searchLoginUserPlaceholder: 'Căutare după nume sau prenume',
  location: 'Obiect',
  searchLocation: 'Obiect de căutare',
  searchLocationPlaceholder: 'Căutare după numele obiectului, număr sau etichete',
  deactivate: 'Dezactivați',
  active: 'Activ',
  inactive: 'Inactiv',
  tag: 'Ziua',
  tags: 'Etichete',
  color: 'Culoare',
  icon: 'Icoana',
  language: 'Limba',
  general: 'General',
  yes: 'Da',
  no: 'Nu',
  searchThrough: 'Răsfoiți',
  name: 'Nume',
  create: 'Creați',
  save: 'Salvați',
  home: 'Acasă',
  profile: 'Profil',
  templates: 'Șabloane',
  noAssignment: 'Neatribuite',
  sort: {
    change: 'Ordin de modificare',
    save: 'Salvează secvența',
    saved: 'Secvența a fost salvată.'
  },
  signature: {
    signature: 'Semnătura',
    pleaseSign: 'Vă rugăm să semnați',
    clickHereToSign: 'Click aici pentru a semna',
    addSignature: 'Adăugați semnătura',
    save: 'Preia controlul',
    delete: 'Ștergeți'
  },
  formError: {
    min: 'Valoarea trebuie să fie mai mare decât {{value}}.',
    min2: 'Valoarea trebuie să fie mai mare sau egală cu {{valoare}}.',
    max: 'Valoarea trebuie să fie mai mică sau egală cu {{valoare}}.',
    requiredBecause: 'Valoare necesară deoarece {{name}} este completat.',
    isRequired: '{{name}} este obligatoriu.',
    numberInUse: 'Numărul personal este deja utilizat.',
    maxlength: '{{name}} nu trebuie să conțină mai mult de {{value}} caractere.',
    email: 'Vă rugăm să introduceți o adresă de e-mail validă.',
    faultyBlinkId: 'Blink ID defect'
  },
  scan: {
    choose: 'Atribuiți un nou ID de clipire',
    text: 'Scanați codul QR de pe cardul de înregistrare.',
    scan_now: 'Scanați acum',
    enter_manually: 'Introduceți manual'
  },
  removeImage: 'Ștergeți imaginea',
  tickets: { assignee: 'Editor', dueTo: 'Cu scadență până la' },
  ngSelect: {
    typeToSearch: 'Vă rugăm să introduceți 3 sau mai multe litere.',
    loading: 'date de încărcare...',
    notFound: 'Nu s-a găsit niciun {{titlu}}.',
    clearAll: 'Resetare'
  },
  help: {
    pageTitle: 'Ajutor',
    text1: 'Echipa noastră de asistență va fi bucuroasă să vă răspundă la orice întrebare pe care o aveți:',
    phone: 'Telefon',
    mail: 'E-mail',
    information: 'Informații',
    documentation: 'Documentație online',
    documentationText: 'În documentația noastră online veți găsi instrucțiuni și videoclipuri pentru toate domeniile importante ale Blink.',
    support: 'Suport'
  },
  REGISTRATION: {
    WELCOME: 'Bine ați venit!',
    chooseMethod: 'Ce metodă doriți să utilizați pentru a vă înregistra?',
    withBlinkId: 'Carte de identitate BLINK',
    withCredentials: 'Nume de utilizator / Parolă',
    REGISTER: 'Înregistrare',
    ALREADY_REGISTERED: 'Am un cont Blink.',
    INVALID_CARD: 'Cardul nu este valabil.',
    noBlinkId: 'Codul QR scanat nu este un ID Blink valid',
    scanBlinkId: 'Scanare ID intermitentă',
    ERROR: 'Înregistrarea nu a putut fi finalizată. Vă rugăm să introduceți o altă adresă de e-mail sau un alt număr de telefon mobil.',
    NO_INTERNET_EXPLORER: 'Din păcate, Blink nu acceptă Internet Explorer. Vă rugăm să folosiți Microsoft Edge, Firefox sau Chrome. Vă mulțumim pentru înțelegere.',
    loginNow: 'Înregistrați-vă acum',
    help: 'Ajutor cu înregistrarea',
    helpText: 'Aplicația Blink Manager vă permite să gestionați orele, personalul și configurația sistemului. Blink Manager este numai pentru supraveghetori, administratori de proprietăți, management și administrație! Pentru înregistrarea pur și simplu a timpului, utilizați "Blink Time App"!<br/><br/>Există două metode pe care le puteți utiliza pentru a vă înregistra:<br/><br/><b>Înregistrează-te cu cardul de identitate Blink</b><br/><br/>Dacă ai un card de identitate Blink, atunci alege această metodă. Când vă înregistrați prima dată, vi se va cere o adresă de e-mail sau un număr de telefon mobil. Avem nevoie de acestea pentru a vă trimite o parolă unică pentru înregistrare. Nu trebuie să vă amintiți parola unică. Atunci când vă înregistrați din nou, vă vom trimite o nouă parolă unică.<br/><br/><b>Înregistrarea cu nume de utilizator și parolă</b><br/><br/><br/>Vă veți primi numele de utilizator și parola doar la cerere de la administratorul de sistem. Această metodă este disponibilă numai pentru utilizatorii cu drepturi administrative.<br/><br/><b>Suport</b><br/><br/><br/>Dacă aveți probleme la logare, scrieți un e-mail la support@blink-time.de sau sunați la 0911 240 330 0.',
    userOffline: 'Vă rugăm să vă conectați la internet pentru a utiliza Blink me.',
    login: 'Înregistrați-vă acum',
    codeError: 'Vă rugăm să vă verificați înscrierea și să încercați din nou.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Felicitări!',
    YOU_DID_IT: 'Ați reușit.',
    SIGNED_IN: 'Înregistrat',
    SIGNED_IN_TEXT: 'V-ați înregistrat cu succes și acum puteți utiliza aplicația.',
    START_USAGE: 'Acum puteți utiliza aplicația.',
    START: 'Start'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Ați primit un e-mail prin care vă puteți modifica datele de acces.',
    FORGOT_PASSWORD: 'Ați uitat parola?',
    errors: {
      notAllowed: 'Nu se poate reseta nicio parolă pentru acest utilizator.',
      invalidToken: 'Codul pentru resetarea parolei a expirat. Vă rugăm să solicitați un nou cod.'
    },
    reset: {
      backToRoot: 'Înapoi la pagina de autentificare',
      renewCode: 'Solicită un nou cod',
      resetPassword: 'Resetare parolă',
      success: 'Parola dvs. a fost resetată cu succes.',
      password1: 'Noua dvs. parolă',
      password2: 'Repetați parola',
      validationError: 'Parola nu este conformă cu instrucțiunile noastre privind parolele!',
      passwordsNotEqual: 'Parolele nu sunt identice! Vă rugăm să vă verificați intrarea.',
      passwordValidation: 'Parola trebuie să aibă cel puțin 6 caractere și să conțină o literă, un număr și un caracter special.'
    },
    FORGOT_PASSWORD_TEXT: 'Vă rugăm să introduceți adresa dvs. de e-mail și sistemul țintă pentru a vă reseta parola.',
    FORGOT_PASSWORD_PLACEHOLDER: 'e-mail'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Verificați datele',
    TEXT: 'Dacă trebuie să vă resetați parola, vom avea nevoie de adresa dvs. de e-mail și, opțional, de numărul de telefon mobil.',
    EMAIL: 'Adresa de e-mail',
    MOBILE: 'Număr de telefon mobil'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Introduceți codul',
    EMAIL_TEXT: 'Introduceți codul pe care vi l-am trimis la următoarea adresă de e-mail:',
    SMS_TEXT: 'Introduceți codul pe care vi l-am trimis la următorul număr de telefon mobil:',
    NO_CODE: 'Nu am primit niciun cod.',
    CODE_LENGTH_ERROR: 'Codul introdus are mai mult de șase caractere. Vă rugăm să introduceți din nou codul.',
    CODE_SENT: 'Codul a fost trimis.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Fără cod',
    TEXT1: 'Nu ați primit un cod?',
    RESEND_CODE: 'Trimiteți din nou codul',
    TEXT2: 'În cazul în care adresa de e-mail sau numărul de telefon mobil stocat în sistem s-a schimbat, vă rugăm să contactați administratorul dumneavoastră.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Vă rugăm să introduceți ID-ul și codul Blink. Veți găsi toate informațiile pe cardul de înregistrare.',
    textCredentials: 'Administratorul de sistem trebuie să vă furnizeze numele de utilizator și parola.',
    BLINK_ID_INVALID: 'Blink ID defect',
    loginCardNotFound: 'ID-ul Blink este deja atribuit sau nu există.',
    WRONG_CREDENTIALS: 'Datele introduse nu sunt corecte. Vă rugăm să verificați datele introduse.',
    loginViaBlinkId: 'Înregistrare cu BlinkID',
    loginViaCredentials: 'Autentificare cu date de acces',
    CODE: 'Cod',
    codePlaceholder: 'Codul dumneavoastră personal de acces',
    company: 'Sistem',
    password: 'Parola',
    FORGOT_PASSWORD: 'Mi-am uitat parola',
    username: 'e-mail'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'Identificare',
    TEXT_1: 'Pentru a vă putea identifica în mod unic, vă vom trimite un cod unic prin SMS sau e-mail.',
    TEXT_2: 'Vă rugăm să introduceți numărul de telefon mobil sau adresa de e-mail în acest scop.'
  },
  update: {
    update: 'Actualizare',
    latestVersion: 'Există o nouă versiune disponibilă. Vă rugăm să vă actualizați aplicația pentru a profita pe deplin de ea.',
    warnVersion: 'Folosiți o versiune depășită a acestei aplicații. Vă rugăm să o actualizați cât mai curând posibil.',
    blockVersion: 'Folosiți o versiune depășită a acestei aplicații, care nu mai este compatibilă. Vă rugăm să o actualizați acum.'
  },
  inProgress: 'În curs',
  video_offline: 'Videoclipurile nu pot fi vizionate în modul offline, vă rugăm să vă conectați la Internet.',
  location_offline: 'Obiectul nu este salvat offline, vă rugăm să vă conectați la Internet pentru a prelua codul QR.',
  invalidObjectCode: 'Niciun cod obiect valid.',
  invalidValue: 'valoare invalida',
  ERROR: {
    ERROR_400: 'A avut loc o eroare. Vă rugăm să încercați din nou mai târziu. (Cod de eroare: 400)',
    ERROR_403: 'Nu aveți permisiunea de a efectua această acțiune',
    REFRESH_TOKEN_403: 'Datele dvs. de acces nu mai sunt valide. Vă rugăm să vă conectați din nou!',
    ERROR_500: 'A avut loc o eroare. Vă rugăm să încercați din nou mai târziu. (Cod de eroare: 500)'
  },
  list: {
    entityPluralDefault: 'Rezultate',
    emptyState: 'Nu există {{entityPlural}}.',
    noResults: 'Nu a putut fi găsit niciun {{entityPlural}}.',
    selectAll: 'Selectați toate {{entityPlural}}',
    massAction: 'Promoție pentru {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Selectați toate {{count}}',
    massActionDeSelectAllGlobal: 'Deselectați toate {{count}}'
  },
  languages: {
    ar: 'arabic',
    de: 'limba germana',
    es: 'Spaniolă',
    fr: 'limba franceza',
    hr: 'croat',
    it: 'Italiană',
    pt: 'portugheză',
    ru: 'Rusă',
    bg: 'bulgară',
    en: 'Engleză',
    fa: 'persană',
    el: 'greacă',
    hu: 'maghiară',
    pl: 'Lustrui',
    ro: 'Română',
    tr: 'turc',
    uk: 'ucrainean',
    cs: 'ceh',
    sk: 'slovacă'
  },
  now: 'Acum',
  allCompanies: 'Toți clienții',
  createDate: 'Data crearii',
  company: 'client',
  new: 'Nou',
  of: 'din',
  request: {
    deleteTitle: 'Ștergeți {{item}}',
    confirmDelete: 'Chiar doriți să ștergeți intrarea „{{itemName}}”?',
    itemAdded: '{{item}} a fost creat.',
    itemAddedError: '{{item}} nu a putut fi creat.',
    itemSaved: '{{item}} a fost salvat.',
    itemSavedError: '{{item}} nu a putut fi salvat.',
    itemRemoved: '{{item}} a fost șters.',
    itemRemovedError: '{{item}} nu a putut fi șters.'
  },
  news: { pageTitle: 'Știri', edit: 'Editați rapoartele de știri' },
  profiles: {
    loginProfiles: 'Profiluri de autentificare',
    add: 'Profil de autentificare',
    logout: 'Dacă vă deconectați, toate datele și profilurile locale vor fi șterse! Doriți să vă dezabonați?',
    login: 'Inregistreaza-te',
    deleteConfirmation: 'Chiar vrei să ștergi profilul?',
    changed: 'Profilul a fost schimbat cu succes',
    deleted: 'Profilul a fost șters cu succes'
  },
  deleteAttachment: {
    confirmTitle: 'Ștergeți atașamentul',
    confirmText: 'Sigur doriți să ștergeți acest atașament?',
    deleted: 'Atașamentul a fost șters'
  },
  comments: {
    addComment: 'adauga comentariu',
    addCommentInfo: 'Comentariul este vizibil pentru oricine poate vedea acest bilet.',
    comment: 'cometariu',
    deleteComment: 'Șterge comentariul',
    deleteCommentConfirmation: 'Chiar vrei să ștergi comentariul?',
    commentCreated: 'Comentariul a fost salvat.',
    commentDeleted: 'Comentariul a fost șters'
  },
  confirm: 'Confirma',
  firstName: 'Prenume',
  lastName: 'Nume',
  status: 'starea',
  attachment: 'Atașament',
  timeFramePicker: {
    title: 'Vă rugăm să selectați o perioadă',
    today: 'Astăzi',
    currentWeek: 'Săptămâna aceasta',
    currentMonth: 'Luna curentă',
    custom: 'Personalizat',
    startDate: 'ÎNCEPE',
    endDate: 'Sfârşit',
    lastWeek: 'Săptămâna trecută',
    lastMonth: 'Luna trecută'
  },
  cw: 'KW',
  contextMenu: { openInNewTab: 'Deschideți linkul într-o filă nouă' }
};
