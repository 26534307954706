<ion-app>
  <div class="fake-header"></div>
  <div class="fake-header-line"></div>
  <ion-split-pane contentId="main-content"
                  when="(min-width: 992px)"
                  class="animate-opacity"
                  [class.hide-tabs]="!showTabs"
                  [class.small-width]="isLogin">
    <ion-menu contentId="main-content"
              menuId="main-menu"
              class="ui-main-menu"
              [class.hide]="!showMenu || isLogin">
      <ui-main-menu [class.ios-offset]="showIosOffset()"
                    [newVersionAvailable]="newVersionAvailable"
                    [tabs]="tabs"
                    [companies]="companies$ | async"
                    [selectedCompanyId]="selectedCompanyId$ | async"
                    [items]="uiMenuItems"
                    [externalLinks]="externalLinks"
                    (customMenuItemClicked)="customMenuItemClicked.emit($event)"
                    (companySelected)="companySelected.emit($event)"
                    [loginProfiles]="loginProfiles$ | async"
                    [activeProfile]="activeProfile$ | async"
                    (loginProfileSelected)="loginProfileSelected.emit($event)">
      </ui-main-menu>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <ui-mobile-tabs *ngIf="showTabs"
                  [tabs]="tabs">
  </ui-mobile-tabs>
</ion-app>
