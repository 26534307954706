export default {
  home: {
    pageTitle: 'Home page',
    welcome: 'Welcome to the Blink me app. Have fun chatting,\n\nYour Blink team',
    startChat: 'Start',
    ticket: 'Ticket',
    timeLicenseMissing: 'You have not been assigned a Blink Time license!'
  },
  conversations: { pageTitle: 'news', yourChats: 'Your chats', chat: 'Chat' },
  settings: {
    settings: 'settings',
    system: 'System',
    languageSelection: 'language selection',
    currentSettings: 'Current settings',
    user: 'User',
    appVersion: 'Version (App)',
    apiVersion: 'Version (Api)',
    deviceInfo: 'Device version',
    buildVersion: 'Version (Build)',
    helpAndDataPolicy: 'Help and data policy',
    text1: 'Our support team will be happy to answer any questions you may have:',
    phone: 'Phone',
    mail: 'E-mail',
    information: 'Info',
    documentation: 'Online documentation',
    documentationText: 'In our online documentation, you will find instructions and videos on all important aspects of Blink.',
    dataPolicy: 'Data policy',
    support: 'Support',
    moreActions: 'More actions',
    protocol: 'Protocol',
    showChatUserSecret: 'Share chat with different device',
    scanCodeAnotherDevice: 'Scan the QR-Code or enter the chat code manually on your new personal device!',
    yourPersonalCode: 'Your personal chat code',
    pushEnabled: 'Receive push notifications',
    permissions: {
      title: 'Permissions',
      grantPermission: 'grant permission',
      camera: 'camera',
      cameraHint: 'Necessary for time recording via object code.',
      nfc: 'NFC',
      nfcHint: 'Necessary for time recording via NFC tag.',
      media: 'Take image and image from library',
      mediaHint: 'Necessary to capture images and documents related to your times and tickets.',
      location: 'Location',
      locationHint: 'Necessary to record your location at the time of time recording.',
      notifications: 'Push notifications',
      notificationsHint: 'Necessary to receive push notifications for chat messages, blink notifications and stickers.',
      locationDisabledOnSystem: 'Location services are disabled. Please activate this in the settings of your device.'
    }
  },
  connectionState: {
    connecting: 'Connecting ...',
    connected: 'Connected',
    disconnecting: 'Disconnecting ...',
    disconnected: 'Connection lost',
    denied: 'Connection could not be established'
  },
  noOneToFind: 'No matches found',
  nothingToFind: 'No matches found',
  searchPlaceholder: 'Search...',
  employeeSearchPlaceholder: 'Lastname, Firstname',
  typeToSearch: 'Tap to search ...',
  typeToSearchEmployee: 'Enter a name to start a chat.',
  createChatWithEmployee: 'Select employee',
  createChatWithLocationTeam: 'Find object',
  createTeamleaderChat: 'All of my co-workers',
  yourLanguageSelection: 'Your language selection:',
  loginInfo: 'To register, please scan your personal QR code:',
  updating: 'Blink me has been updated.',
  intro: {
    slide1: { header: 'Welcome', text: 'Have fun with your Blink me app.' },
    slide2: {
      header: 'Chat',
      text: 'Chat directly with your colleagues or find out the latest news from interesting groups.'
    },
    slide3: {
      header: 'Tickets',
      text: 'Here you can find your tasks and submit requests.'
    }
  },
  pleaseWait: 'Please wait...',
  skip: 'Skip',
  startAgain: 'Restart',
  lock: {
    createPinHeader: 'Your personal PIN',
    createPinInfo: 'To protect your data, you can set a PIN here, which you have to enter every time you use the Blink me app.',
    validatePin: 'Please enter the PIN again.',
    tryAgain: 'PIN does not match. Please try again.',
    triedToOften: 'Unfortunately your PIN does not match. Would you like to restart the process or skip it and do it later?',
    enterPinHeader: 'Pin entry',
    enterPinInfo: 'Please enter your PIN to unlock the app.',
    remainingTrys: '{{Amount}} attempts left. Then the registration in the app has to be carried out again.',
    tooManyEnters: 'Registration with a PIN is unfortunately no longer possible. Please log in again with your Blink ID card.',
    reSignIn: 'New registration',
    setpin: 'PIN input when using the app',
    skip: "Don't you want to assign a PIN now? You can activate this function later at any time via the settings of the app.",
    dontUsePin: 'Do not use a PIN',
    deactivatePinHeader: 'Deactivate PIN',
    deactivatePinInfo: 'Please enter your PIN to deactivate the protection.'
  },
  active: {
    yourTickets: 'Your tickets',
    createNewTask: 'Create new ticket:',
    assignee: 'editor',
    employee: 'employee',
    location: 'object',
    dueDate: 'Due on',
    createTask: 'Create ticket',
    taskCreated: 'The ticket has been created.',
    backToTasks: 'Back to tickets',
    createdAt: 'created on',
    attachments: 'Attachments',
    addFileOrImage: 'Add a file or photo',
    addImage: 'Add picture',
    comments: 'Comments',
    addComment: 'Add comment',
    addCommentInfo: 'Comment is visible to anyone who can see this ticket.',
    commentCreated: 'Comment was saved.',
    showClosed: 'View closed tickets',
    resetFilter: 'Reset all filters',
    changeTicketStatusTo: 'Change ticket status to:',
    editTask: 'Edit ticket',
    saveTask: 'Save ticket',
    taskSaved: 'The ticket has been saved.',
    deleteImage: 'Clear image',
    deleteImageConfirmation: 'Are you sure you want to delete the picture?',
    deleteComment: 'Delete comment',
    deleteCommentConfirmation: 'Are you sure you want to delete the comment?',
    changeAssignee: 'Change agent',
    duplicateTask: 'Duplicate ticket',
    duplicateTaskConfirmation: 'Do you want to duplicate this ticket',
    taskDuplicated: 'Ticket was duplicated.',
    deleteTask: 'Delete ticket',
    deleteTaskConfirmation: 'Do you really want to delete this ticket?',
    taskDeleted: 'The ticket was deleted.',
    assigneeChanged: 'Editor has been changed.',
    statusChanged: 'The ticket status has been changed.',
    startDate: 'Start',
    endDate: 'End',
    doctorVisitDate: "Date of doctor's visit",
    doctorVisitDescription: "Date of doctor's visit: {{date}}",
    chooseDate: 'Choose date',
    comment: 'Comment (optional)',
    dateError: 'The end date cannot be before the start date.',
    deleteAttachment: 'Delete attachment',
    deleteAttachmentConfirmation: 'Do you really want to delete this attachment?',
    pleaseConnectToLoad: 'Please connect to the internet to load your tickets.'
  },
  emptyState: {
    chats: 'Create a chat!',
    chat: 'Write the first message :)',
    tasks: 'No tickets to be found.',
    attachments: 'Pretty empty here.',
    comments: 'What do you think about?',
    vacation: 'No vacation registered for this year'
  },
  ngSelect: {
    typeToSeach: 'Please enter 3 letters or more.',
    loading: 'Loading data...',
    notFound: 'No {{title}} found.',
    clearAll: 'Reset to default'
  },
  placeholder: {
    employee: 'Search for first and last name, personnel number, Blink ID',
    location: 'Search by object name, number or tags',
    date: 'Pick a date',
    searchTasks: 'Search tickets ...'
  },
  hasChat: 'Start chatting now',
  hasNoChatYet: 'has unfortunately not yet installed the Blink me app.',
  noBlinkActiveLicence: 'No license',
  noBlinkActiveLicenceInfo: 'You do not currently have a license for this app. Please contact your supervisor.',
  noBlinkMeLicence: 'No license',
  noBlinkMeLicenceInfo: 'This app could not be used with your system. Please download the Blink Time App from the AppStore or PlayStore',
  noSuitableAppFound: 'No App found to open the file',
  myChats: 'Your Chats',
  hasNoTicketsHome: 'No open tickets',
  chat: {
    notificationsDenied: 'Notifications are disabled on your device/browser. Go to settings and enable notifications for Blink me to receive notifications. Then you can enable notifications in the personal settings.',
    deleted: 'Chat was deleted',
    userAlreadyExists: 'You have already set up the chat on another device. In order to be able to use the chat on this device as well, you must link the devices. Alternatively, you can restart the setup, but then the chat can no longer be used on the other device.',
    linkDevices: 'Link Devices',
    withoutChat: 'Continue without chat',
    setupAgain: 'Set up chat again',
    setupAgainText: 'If you set up the chat again, all messages from the existing individual chats will be deleted and the chat can no longer be used on the other devices! Do you want to continue?',
    LinkDevicesText: 'To link the devices for chat, open the settings on your other device and select “Share chat with other device',
    personalToken: 'Your personal chat code',
    wrongCode: 'The chat code provided is incorrect.',
    beingPrepared: 'Chat is being prepared.',
    twilioAccessDenied: 'The connection to the chat service could not be established. Please contact Blink Support.',
    unavailable: 'Chat is not available',
    participant: 'Participant',
    teamChatDescription: 'All employees for whom you are registered as a supervisor will be added to this chat group.',
    locationChatDescription: 'Employees will be added automatically if they have a plan for this object in the last four weeks or the next four weeks.',
    createEmployeeChatDescription: 'A private chat will be created with the selected employee.',
    teamChatCreationTitle: 'Create chat group',
    createTeamChatDescription: 'A chat group will be created in which all employees for whom you are registered as a supervisor will automatically be added.',
    createLocationChatDescription: 'A chat group is created for the selected object, in which employees are automatically added if they have a plan for it in the last four weeks or the next four weeks.'
  },
  protocol: {
    deleteMessage: 'Do you really want to delete the protocol? This action cannot be undone.',
    Send: 'Send',
    SuccessMessage: 'The protocol was successfully sent to the Blink support team.',
    SEND_EMAIL: 'Send by e-mail',
    DELETE: 'Delete log',
    ConfirmMessage: 'First contact the support team and only send the log if requested.',
    Description: 'Problem Description'
  },
  noLocationFound: 'No objects found.',
  notice: 'Note',
  importantNotice: 'Important note',
  continue: 'Next',
  formError: {
    min: 'value must be greater than {{value}}.',
    min2: 'value must be greater than or equal to {{value}}.',
    max: 'value must be less than or equal to {{value}}.',
    requiredBecause: 'Value needed because {{name}} is filled in.',
    isRequired: '{{name}} is mandatory.',
    numberInUse: 'Personnel number is already in use.',
    maxlength: '{{name}} must not contain more than {{value}} characters.',
    faultyBlinkId: 'Blink ID incorrect'
  },
  timeTracking: {
    title: 'Time tracking',
    start: 'Start',
    startDescription: 'Click start to start time tracking',
    started: 'Started',
    stop: 'Stop',
    duration: 'Duration',
    syncButtonLabel: 'Upload times recorded offline',
    offlineSyncMessage: 'To transfer the recorded times you must be online.',
    successfulSync: 'Times are uploaded',
    scanNowQrCode: 'QR Code',
    scanNowQrCodeDescription: 'Scan the QR code in the object.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Hold your smartphone to the NFC sticker in the object.',
    nfcDescription: 'Capture via NFC: Hold your cell phone to the object code',
    noTimeTrackingMethodAvailableDescription: 'No time tracking method is configured for this user. Please contact your team leader.',
    noLocationCode: 'No code available',
    noLocationCodeDescription: 'Select this option if there is no QR code or NFC sticker in the object. Entering a comment is mandatory.',
    noLocationCodeConfirm: 'Are you sure you want to start without object code? You must specify a comment to stop.',
    wrongCode: 'The scanned QR code is not an object code!',
    readNotice: 'Please read notes!',
    warning: 'Warning',
    lessThanAMinuteTitle: 'Complete time tracking',
    lessThanAMinute: 'You started less than 1 minute ago. Are you sure you want to stop?',
    continue: 'Continue collection',
    timeAlert: {
      differenceInMinutesHint: "The time from your smartphone differs from the server's time. The difference is logged",
      differentTimeZoneHint: "The time zone from your smartphone differs from the server's time zone. The difference is logged."
    },
    leaveComment: {
      withCode: 'The specification of the object/service was not clear. Please tell us in which object/service activity you have worked.',
      withoutCode: 'No object code was captured at start or stop. Please explain why you started/stopped without an object.'
    },
    comment: 'Comment',
    enterComment: 'Comment on time recording',
    differentQrCode: {
      title: 'Change object?',
      continueInLocation: 'Continue working in {{locationName}}.',
      automaticStopInLocation: 'Time tracking in {{locationName}} is stopped automatically.',
      stopInLocation: 'Stop time tracking in {{locationName}}.',
      stop: 'Stop',
      stopNoLocation: 'Stop time tracking.',
      continueNoOldLocation: 'Time tracking on the previous object will be stopped automatically.',
      continueWorking: 'Continue working',
      continueWorkingInNewLocation: 'Continue working in new object.',
      stopWithLocationActivity: 'Stop time tracking on {{locationActivity}}.',
      continueWithNewLocationActivity: 'Continue working with {{locationActivity}}',
      stopOldLocationActivity: 'Time tracking on {{locationActivity}} is stopped automatically.',
      stopOldNoLocationActivity: 'Time tracking without service activity is stopped automatically.',
      continueNoLocationActivity: 'Continue working without service activity.',
      sameLocationTitle: 'Change service activity?',
      differentLocationTitle: 'Change object?'
    },
    saveAndStop: 'Save and stop',
    noLocationScanned: '- No object -',
    noLocationFound: '- Object not unique -',
    stopSuccess: 'Time successfully recorded',
    stopSuccessToast: 'Time tracking successfully stopped.',
    stopTimeTracking: 'Stop',
    manual: {
      time: 'Time',
      locationSelect: 'Select object',
      loadMore: 'Load more',
      trackTime: 'Record time',
      location: 'Object',
      locationActivity: 'Service activity',
      date: 'Date',
      startTime: 'From',
      endTime: 'Until',
      create: 'Confirm',
      createAgain: 'Add more records for this object',
      errorUntil: 'End time must be after start time',
      worktimeDifference: 'Working time:',
      success: 'Time successfully recorded',
      break: 'Pause: ',
      noActivity: '- No service activity -',
      locationPlaceHolder: 'Search by name, number or object codes',
      conflictError: 'There is already a time recording in the specified time periods. Overlaps are not allowed. Please check your entries.',
      worktimeDifferenceValue: '{{hours}} hours {{minutes}} minutes',
      worktimeDifferenceValueMinutes: '{{minutes}} minutes',
      breakMinutes: '{{minutes}} minutes',
      breakHoursAndMinutes: '{{hours}} hours {{minutes}} minutes',
      currentDayDuration: 'Already recorded',
      expectedDuration: 'Expected working hours',
      expectedDurationIncludedCurrent: 'including current input'
    },
    timerDifference: '{{h}} hours {{mm}} minutes',
    differenceInMinutesHint: 'The time on your smartphone differs from the time on the server. The difference is logged',
    differentTimeZoneHint: 'The time zone of your smartphone differs from the time zone of the server. The difference is logged.',
    proposed: {
      capture: 'Capture',
      time: 'Time',
      captureTime: 'Record time',
      button: 'Confirm',
      title: 'Confirm working hours',
      includingBreak: '(including break)',
      from: 'From',
      to: 'Until',
      break: 'Break',
      hour: 'Clock',
      confirm: {
        description: 'Please confirm your working hours',
        confirm: "Yes, that's right",
        editButton: "No, that's not right"
      },
      edit: {
        description: 'Please specify the duration',
        breakIncluded: 'Including {{ duration }} break',
        comment: 'comment',
        yourComment: 'your comment',
        minutes: 'minutes',
        hours: 'Hours'
      },
      reasons: {
        overtime: 'Overtime due to customer',
        faster: 'Done faster',
        other: 'Miscellaneous',
        representative: 'I was a representative'
      },
      description: 'Confirm your working hours as soon as you have finished working at the property.'
    },
    codeNotAssignable: 'The scanned QR code cannot be assigned!',
    running: 'Time recording is running'
  },
  timeSheet: {
    title: 'Times & Schedules',
    noPlannings: 'No planning',
    planned: 'Planned',
    workingTime: 'Working time',
    workloadDurationShort: 'Work time',
    plannedDuration: 'Target',
    workedDuration: 'Actual',
    hasWorkload: 'Scheduled to work',
    pleaseConnectToLoad: 'Please connect to the internet to load your times.',
    footer: {
      planned: 'Planned',
      worklogsTotal: 'Total hours',
      workloadTotal: 'Hours according to contract',
      difference: 'Difference'
    },
    workloadDuration: 'weekly working time',
    homepageTitle: 'Your working day'
  },
  planning: {
    planRoute: 'Plan route',
    title: 'Planning',
    modifyMinutes: 'Flat rate:',
    includingPause: 'Break: ',
    noNavAppFound: 'No navigation app found'
  },
  worklog: {
    title: 'Recorded time',
    absenceTitle: 'Absence',
    workingTime: 'Working time',
    breakDuration: 'Break',
    reduce: 'Flat rate',
    attachments: 'Attachments',
    comments: 'Comments',
    comment: 'Comment',
    noLocation: 'No object specified',
    changedBy: 'Time was changed by {{firstName}}. {{lastName}}.',
    confirmAttachmentDelete: 'Do you really want to delete this attachment?',
    attachmentDeleted: 'Attachment was deleted',
    confirmCommentDelete: 'Do you really want to delete this comment?',
    commentDeleted: 'Comment was deleted'
  },
  tabs: {
    home: 'Home',
    times: 'Times',
    chat: 'Chat',
    tickets: 'Tickets',
    more: 'More'
  },
  profile: {
    title: 'Profile',
    testing: 'Testing',
    testingDescription: 'This area is only visible when connected to a test system.',
    testingAllowQrCodeOnWeb: 'Allow manual input of QR code',
    pushNotifications: 'Push notifications',
    reloadUserContext: 'Reload UserContext',
    pushNotificationSuccessful: 'Push notification received successfully',
    editProfilePicture: 'Change profile picture',
    editProfilePictureTitle: 'Select section',
    pushNotificationNotice: 'The test requires the “Edit tenant settings” permission.',
    send: 'Send',
    profilePicture: 'Profile picture'
  },
  help: { title: 'Help' },
  myVacations: {
    vacation: 'Vacation',
    amountAvailableVacationDays: '{{amount}} days of vacation still available',
    totalVacationDays: 'Vacation entitlement: {{ total }} days',
    remainingVacationDays: '({{ total }} days {{ sign }} {{ remaining }} days remaining {{ previousYear }})',
    xDaysVacations: '{{ total }} days vacation',
    title: 'Your vacation'
  },
  externalLinks: {
    title: 'External links',
    notice: 'This link was provided by your company. You are leaving the Blink system.'
  },
  stickers: {
    title: 'Your stickers',
    sticker: 'Sticker',
    sender: 'Receiver',
    text: 'Subject',
    date: 'Date',
    emptyState: 'You do not have any stickers yet',
    senderDescription: 'This sticker was sent to you by {{sender}} on {{date}}',
    readed: 'Read',
    notReaded: 'Unread',
    newStickerToaster: 'New Blink Sticker!',
    open: 'Open',
    next: 'Next',
    titleCases: {
      case1: 'Congratulations on your 1st sticker!',
      case2: 'Great, now you already have <b>{{count}}</b> stickers!',
      case3: 'Congratulations on the 10th sticker!',
      case4: '30 stickers - wow!',
      case5: "Now it's getting crowded here: <b>{{count}}</b> stickers!"
    },
    confirmDelete: 'Do you really want to delete these stickers?'
  },
  hasNoChatsHome: 'Start chatting now! You can chat with all colleagues and participate in object and team groups.',
  myTickets: 'Your tickets',
  myEmployees: 'My employees',
  group: 'group',
  confirmLogoutWithChat: 'If you log out, your chat messages will be deleted. Would you like to continue?',
  messageNotDecryptable: 'Message could not be decrypted.',
  autoTranslate: {
    isTranslated: 'Automatically translated',
    translationError: 'Automatic translation is currently not possible'
  },
  confirmLogout: 'Do you really want to log out?',
  message: 'News',
  migration: {
    wrongVersion: 'In the current version of Blink me, logging in via the Blink Time app is not supported. Please update the Blink me app to the latest version'
  },
  eLearning: {
    pendingTab: 'To do ({{value}})',
    completedTab: 'Done ({{value}})',
    completed: 'Completed',
    pending: 'Open',
    dueFalling: 'Overdue',
    title: 'E-learning',
    course: 'course',
    dueDate: 'Valid till',
    topics: 'subjects',
    completeDate: 'Finished on',
    status: { Pending: 'open', Done: 'completed' },
    infos: {
      pendingExists: 'You can find all your open training courses here.',
      finishOverdue: 'Please do the training that is already due as quickly as possible.',
      allCompleted: 'Excellent! You have successfully completed all of your training courses.',
      emptyState: 'You have no assigned training.'
    }
  },
  checklists: {
    title: 'Forms',
    description: 'Fill out a form for an object',
    button: 'Select object'
  },
  pendingTab: 'In progress ({{value}})',
  completedTab: 'Completed ({{value}})',
  workOrders: {
    title: 'Work orders',
    homepageTitle: 'Your work orders',
    responsible: 'Responsible',
    customerContact: 'Customer contact',
    trackedTime: 'Time tracking for order #{{value}}',
    show: 'View work order',
    trackInfo: {
      confirmAndAcknowledge: 'Please confirm the positions after implementation and carry out an acknowledgement on site.',
      acknowledgeOnly: 'Please carry out an acknowledgement on site.',
      confirmOnly: 'Please confirm the positions after implementation.'
    },
    pleaseConnectToLoad: 'Please establish an internet connection to load your work orders.',
    status: { new: 'New', inProgress: 'In progress', completed: 'Completed' },
    emptyState: 'No open work orders available.',
    emptyStateCompleted: 'No completed work orders available.',
    details: {
      title: 'Work order details',
      positionsTab: 'Positions ({{value}})',
      acknowledgementsTab: 'Acknowledgements ({{value}})'
    },
    acknowledgement: {
      title: 'Acknowledgement',
      start: 'Start acknowledgement',
      acknowledgedAtDate: 'Taken on',
      itemSelectDescription: 'Select all positions that should be acknowledged.',
      customerInformationDescription: 'Please provide the contact details of the customer (recipient). After acceptance, a confirmation will be sent to this email.',
      created: 'Acknowledgement successfully saved',
      selectAll: 'Select all',
      emptyState: 'There are no acknowledgements.'
    },
    items: {
      items: 'Positions',
      acknowledge: 'Confirm position',
      confirmAcknowledge: 'You hereby confirm that the work has been completed. The status of the item will be updated to “Completed”.',
      status: {
        open: 'Open',
        planned: 'Planned',
        performed: 'Performed',
        acknowledged: 'Acknowledged',
        billed: 'Billed'
      },
      emptyState: 'There are no positions available.'
    },
    trackTime: {
      description: 'Select a work order you want to track time on.',
      chooseWorkOrder: 'Select work order',
      choosePosition: 'Select position'
    }
  }
};
