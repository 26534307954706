import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateFormComponent } from './ui-translate-form.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { BlinkUiModule, UiIconModule, UiInputsModule } from '@blink/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { UiTranslateDisplayComponent } from './translate-display/ui-translate-display.component';
import { UiLanguageFlagComponent } from './ui-language-flag.component';
import { UiLanguageFlagStackComponent } from './ui-language-flag-stack.component';
import { UiTranslateResetTranslationsInfoComponent } from './ui-translate-reset-translations-info.component';
import { UiTranslateDisplayWrapperDirective } from './translate-display/ui-translate-display-wrapper.directive';
import { UiTranslateDisplayToggleComponent } from './translate-display/ui-translate-display-toggle.component';

const uiTranslateComponents = [
  UiTranslateFormComponent,
  UiTranslateDisplayComponent,
  UiTranslateDisplayWrapperDirective,
  UiTranslateDisplayToggleComponent,
  UiLanguageFlagComponent,
  UiLanguageFlagStackComponent,
  UiTranslateResetTranslationsInfoComponent,
];

@NgModule({
  declarations: uiTranslateComponents,
  exports: uiTranslateComponents,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    UiInputsModule,
    FormsModule,
    BlinkUiModule,
    UiIconModule,
    QuillEditorComponent,
    ReactiveFormsModule
  ]
})
export class UiTranslateFormModule {
}
