<div class="sheet-thing-container">
  <ion-row class="ion-justify-content-between" style="border-bottom: 1px solid #d2d2d2;">
    <ion-col class="ion-no-padding"></ion-col>
    <ion-col class="ion-no-padding" size="auto">
      <ui-button fill="clear" (click)="modalController.dismiss()"
                 [icon]="BlinkIcon.Close" [iconOnly]="true"></ui-button>
    </ion-col>
  </ion-row>

  <ion-list class="select-chat-type" lines="none" style="padding: 0;">
    <ion-item (click)="modalController.dismiss(CameraSource.Camera)">
      <div slot="start" class="avatar" style="background-color: #EF9985;">
        <i class="fal fa-camera ion-no-margin"></i>
      </div>
      <div class="select-chat-type-text">
        {{ t.global.camera | translate }}
      </div>
    </ion-item>
    <ion-item (click)="modalController.dismiss(CameraSource.Photos)">
      <div slot="start" class="avatar" style="background-color: #D963A4;">
        <i class="fal fa-images ion-no-margin"></i>
      </div>
      <div class="select-chat-type-text">
        {{ t.global.gallery | translate }}
      </div>
    </ion-item>
    <ion-item *ngIf="!imageOnly" (click)="modalController.dismiss(CameraSource.Prompt)">
      <div slot="start" class="avatar" style="background-color: #D963A4;">
        <i class="fal fa-files ion-no-margin"></i>
      </div>
      <div class="select-chat-type-text">
        {{ t.global.files | translate }}
      </div>
    </ion-item>

  </ion-list>

</div>
