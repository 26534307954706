import { Component, forwardRef, inject, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputCustomEvent, PopoverController } from '@ionic/angular';
import dayjs from 'dayjs';
import { Capacitor } from '@capacitor/core';

import { BlinkIcon } from '../../ui-icon';
import { UiDatetimeComponent } from '../components/ui-datetime/ui-datetime.component';
import { AbstractInputComponent } from '../abstracts/input.component';


@Component({
  selector: 'ui-input-date',
  templateUrl: './ui-input-date.component.html',
  styleUrls: ['./ui-input-date.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiInputDateComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.Emulated
})
export class UiInputDateComponent extends AbstractInputComponent {
  @Input() presentation: 'date' | 'time' = 'date';
  @Input() minDate = '1980';
  @Input() maxDate: string;
  @Input() showWeekDayShort = true;
  @Input() setNowButton = false;

  dateTimePopover: HTMLIonPopoverElement;
  popoverController = inject(PopoverController);

  get useIonDatetime() {
    return this.presentation === 'date' || Capacitor.isNativePlatform();
  }

  get format() {
    switch (this.presentation) {
      case 'date':
        return this.showWeekDayShort
          ? 'dd DD.MM.YYYY'
          : 'DD.MM.YYYY';
      case 'time':
        return 'HH:mm';
    }
  }

  get icon() {
    return this.presentation === 'date'
      ? BlinkIcon.Calendar
      : BlinkIcon.Clock
  }

  handleClearValue(ev: InputCustomEvent) {
    let value = ev?.target?.value;
    if (value === '' || value == null) {
      value = null;
      this.value = value;
    }
  }

  // needs to be delayed to clear the value first if required
  async openDatepickerPopoverDelayed(ev: InputCustomEvent<FocusEvent>) {
    setTimeout(async () => {
      if (!this.dateTimePopover) {
        this.dateTimePopover = await this.popoverController.create({
          component: UiDatetimeComponent,
          componentProps: {
            presentation: this.presentation,
            minDate: this.minDate,
            maxDate: this.maxDate,
            value: (this.value != null && this.value != '')
              ? dayjs(this.value).format()
              : null,
            setNowButton: this.setNowButton
          },
          event: ev,
          cssClass: 'popover-datetime',
          showBackdrop: false
        })
        this.dateTimePopover.onDidDismiss()
          .then(value => {
            this.dateTimePopover = null;
            if (value.data) {
              this.value = value.data
            }
          });
        await this.dateTimePopover.present();
      }
    }, 10);
  }

  timeInputChange(ev: InputCustomEvent) {
    const timeString = ev.detail.value;
    if (timeString) {
      const [hours, minutes] = timeString.split(':');
      const currentDate = this.value
        ? this.value
        : dayjs();
      this.value = dayjs(currentDate)
        .hour(parseInt(hours))
        .minute(parseInt(minutes))
        .toISOString();
    } else {
      this.value = null;
    }
  }
}
