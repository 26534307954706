import { Component } from '@angular/core';
import { GlobalTranslateService, logComponent } from '@blink/util';
import { PopoverController } from '@ionic/angular';
import { UiHandlerAction } from '../../shared/action';

@Component({
  selector: 'ui-dialog-popover',
  template: `
    <ng-container *ngFor="let action of quickActions">
      <ion-item button *ngIf="action.condition === undefined || action.condition" [class.active]="action.active" lines="none"
                (click)="handle(action)">
        <ui-icon *ngIf="action.icon" [icon]="action.icon" slot="start"></ui-icon>
        {{ action.label | translate }}
      </ion-item>
    </ng-container>`,
  styles: ['ui-icon { color: var(--ion-color-primary) } .active { --background: var(--ion-color-primary); :is(ui-icon) { color: black } }']
})
export class UiDialogPopoverComponent {
  quickActions: UiHandlerAction<null>[]

  constructor(public t: GlobalTranslateService,
              private popoverController: PopoverController) {
    logComponent(this)
  }

  async handle(t: UiHandlerAction<null>) {
    await this.popoverController.dismiss(t.handler(), 'handled');
  }
}
