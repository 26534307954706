import { Component, Input } from '@angular/core';
import { BlinkIcon } from '../ui-icon';

@Component({
  selector: 'ui-empty-state',
  template: `
    <div class="empty-state" [class.margin]="addMargin">
      <ui-icon [icon]="icon" size="2x"></ui-icon>
      <i class="far fa-{{icon}} fa-2x"></i>
      <p *ngIf="message">{{ message | translate }}</p>
      <p>
        <ng-content></ng-content>
      </p>
    </div>`,
  styleUrls: ['ui-empty-state.component.scss']
})
export class UiEmptyStateComponent {
  @Input() icon: BlinkIcon = BlinkIcon.Meh;
  @Input() message: string;
  @Input() addMargin = true;
}
