import { AfterViewInit, Component, Type, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { IonInput } from '@ionic/angular';
import { EMAIL_REGEXP } from '@blink/util';

type InputProps = FormlyFieldProps

export interface FormlyInputFieldConfig extends FormlyFieldConfig<InputProps> {
  type: 'input' | Type<UiFormlyFieldInputComponent>;
}

@Component({
  selector: 'ui-formly-field-ion-input',
  template: `
    <ion-input *ngIf="props.type !== 'number'; else numberTmp"
               #input
               [type]="props.type || 'text'"
               [formControl]="formControl"
               [ionFormlyAttributes]="field"
               [label]="props.label | firstUppercase"
               labelPlacement="stacked"
               [pattern]="props.pattern">
    </ion-input>
    <ng-template #numberTmp>
      <ion-input type="number"
                 labelPlacement="floating"
                 [label]="props.label | firstUppercase"
                 [formControl]="formControl"
                 [ionFormlyAttributes]="field">
      </ion-input>
    </ng-template>
  `,
  styles: [':host { display: inherit; }']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiFormlyFieldInputComponent extends FieldType<FieldTypeConfig<InputProps>> implements AfterViewInit {
  @ViewChild('input') inputEl: IonInput;

  ngAfterViewInit() {
    if (this.props['autofocus']) {
      setTimeout(() => {
        this.inputEl.setFocus();
        this.inputEl.getInputElement().then(el => el.select())
      }, 100);
    }
  }

  protected readonly EMAIL_REGEXP = EMAIL_REGEXP;
}
