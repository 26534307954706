import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsLineItemComponent } from './results-line-item.component';
import { ResultsLineItemDropdownComponent } from './results-line-item-dropdown.component';
import { IonicModule } from '@ionic/angular';
import { BlinkUiModule } from '@blink/ui';
import { TranslateModule } from '@ngx-translate/core';
import { UtilModule } from '@blink/util';

@NgModule({
  imports: [CommonModule, IonicModule, BlinkUiModule, TranslateModule, UtilModule],
  declarations: [ResultsLineItemComponent, ResultsLineItemDropdownComponent],
  exports: [ResultsLineItemComponent],
})
export class CheckResultsLineItemModule {}
