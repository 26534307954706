import { Observable, retry, timer } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { LogRepository } from '../../log/log.repository';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  logRepository = inject(LogRepository);

  shouldRetry(error: HttpErrorResponse, index: number, logRepository: LogRepository): Observable<number> {

    //in case of 408 Request Timeout or 504 Gateway Timeout, always retry 3 times
    const requestTimeout = error.status === 408 || error.status === 504;

    //In case of failed refreshToken we always retry 3 times,
    // unless the refreshToken does not exist anymore
    // or the user has no permission for the current app
    // in both cases 403 is returned
    const isTokenRefresh = error
      && error.url
      && error.url.includes('Token/Refresh')
      && error.status !== 403;

    if (requestTimeout || isTokenRefresh) {
      logRepository.add(`RetryInterceptor: Retrying request (${index}). Url: ${error.url}. Error: ${error.message}`, true);
      return timer(1000 * index);
    }

    throw error;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request)
      .pipe(
        retry({
          count: 3,
          delay: (error, count) => this.shouldRetry(error, count, this.logRepository)
        })
      );
  }
}
