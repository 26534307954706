import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { getRegistry } from '@ngneat/elf';

@Injectable({
  providedIn: 'root'
})
export class BlinkAuthService {

  constructor(
    private navController: NavController,
  ) {}

  logout() {
    getRegistry().forEach(store => store.reset());
    localStorage.clear();

    this.navController.navigateRoot(`login`);
  }
}
