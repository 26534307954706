<ion-header>
  <ion-toolbar style="--background: black; --color: white;">
    <ion-buttons slot="start">
      <ion-button (click)="close()"
                  color="dark"
                  class="back-button">
        <ui-icon slot="start"
                 [icon]="BlinkIcon.ChevronLeft"
                 class="mr-7 text-white"
                 [class.black]="false"></ui-icon>
        <div class="text-white">
          {{ t.back | translate | firstUppercase }}
        </div>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ viewerTitle | translate | firstUppercase }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content style="--background: black;">
  <div class="ion-padding">
    <img [src]="this.src">
  </div>
</ion-content>


<ion-footer>
  <ion-toolbar style="--background: black;">
    <div class="w-full justify-center">

      <ng-container *ngIf="view === 'normal'">
        <ion-button class="m-20"
                    color="dark"
                    fill="clear"
                    (click)="share()">
          <ui-icon *ngIf="Capacitor.isNativePlatform()"
                   [icon]="BlinkIcon.Share"
                   size="lg"
                   class="text-white">
          </ui-icon>
          <ui-icon *ngIf="!Capacitor.isNativePlatform()"
                   [icon]="BlinkIcon.DownloadAttachment"
                   size="lg"
                   class="text-white">
          </ui-icon>
        </ion-button>
        <ion-button *ngIf="deletable"
                    class="m-20"
                    color="dark"
                    fill="clear"
                    (click)="view = 'confirmDelete'">
          <ui-icon [icon]="BlinkIcon.Delete"
                   size="lg"
                   class="text-white">
          </ui-icon>
        </ion-button>
      </ng-container>

      <div *ngIf="view === 'confirmDelete'"
           class="w-full p-10">
        <ui-button color="danger"
                   expand="block"
                   (clicked)="delete()"
                   [label]="'typedGlobal.delete'">
        </ui-button>
        <ion-button color="primary"
                    fill="outline"
                    expand="block"
                    (click)="view = 'normal'">
          {{ 'typedGlobal.cancel' | translate }}
        </ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
