import { Component, DestroyRef, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

import { BlinkAuthService } from '../../services/blinkApi/shared/blink-auth.service';
import { openUrl } from '../../utils';
import { Observable, Subscription } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';
import {
  BlinkLanguageRepository,
  BlinkUserContext,
  BlinkVersion,
  LoginUserApi, SessionApi,
  SessionRepository,
  VersionApi
} from '@blink/api';
import { uiCancelButton, UiDialogService, uiPrimaryButton } from '@blink/ui';
import { NetworkService } from '@blink/capacitor-plugins';
import { APP_VERSION, CheckTranslateService, onlineDokuUrl } from '@blink/util';
import { first, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BlinkLanguage } from '@blink/shared-blink-types';

@Component({
  selector: 'check-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {
  userContext: BlinkUserContext;
  system: string;
  subscriptions = new Subscription();
  apiVersion$: Observable<BlinkVersion>;
  checkApiVersion$: Observable<BlinkVersion>;
  isDesktop = false;
  deviceId: string;
  private destroyRef = inject(DestroyRef);

  selectedLanguageId$ = this.sessionRepo.userContext$.pipe(
    map(userContext => userContext.LanguageId || 1)
  );

  constructor(public blinkAuth: BlinkAuthService,
              private versionApi: VersionApi,
              public t: CheckTranslateService,
              public platform: Platform,
              @Inject(APP_VERSION) public appVersion: string,
              private sessionApi: SessionApi,
              private sessionRepo: SessionRepository,
              private networkService: NetworkService,
              private uiDialogService: UiDialogService,
              public languageRepository: BlinkLanguageRepository,
              private loginUserApi: LoginUserApi) {
    if (Capacitor.isNativePlatform()) {
      Device.getId().then(deviceId => this.deviceId = deviceId.identifier);
    } else {
      this.isDesktop = true;
    }
  }

  ngOnInit(): void {
    this.system = this.sessionRepo.getSystem();
    this.userContext = this.sessionRepo.getUserContext();

    this.networkService.status$
      .pipe(
        first(x => x),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(isOnline => {
        if (isOnline) {
          this.checkApiVersion$ = this.versionApi.getCheckApiVersion();
          this.apiVersion$ = this.versionApi.getApiVersion();
        }
      });
  }

  openDocumentation() {
    if (Capacitor.isNativePlatform()) {
      Browser.open({ url: onlineDokuUrl });
    } else {
      openUrl(onlineDokuUrl);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.uiDialogService.confirm({
      title: 'typedGlobal.logout',
      text: 'confirmLogout',
      buttons: [
        uiCancelButton(),
        uiPrimaryButton(
          'typedGlobal.logout',
          () => this.blinkAuth.logout()
        )
      ]
    });
  }

  languageSelected(language: BlinkLanguage) {
    this.loginUserApi.updateLanguage(this.userContext.LoginUserId, language.Code)
      .subscribe(() => {
        this.sessionApi.fetchUserContext().subscribe();
      });
  }
}
