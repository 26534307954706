import { Injectable } from '@angular/core';
import { BlinkLocationApi, BlinkLocationRepository, CheckChecklistRepository } from '@blink/api';
import { take } from 'rxjs/operators';
import { AlertController, ModalController } from '@ionic/angular';
import { BarcodeScannerService, NetworkService } from '@blink/capacitor-plugins';
import { TranslateService } from '@ngx-translate/core';
import { CostCentreSearchComponent } from '../cost-centre-search/cost-centre-search.component';
import { LocationChecklistSelectComponent } from './location-checklist-select.component';
import { GlobalTranslateService } from '@blink/util';
import { FillChecklistService } from '@blink/check/shared/services/fill-checklist';
import { BlinkLocation } from '@blink/shared-blink-types';

@Injectable({ providedIn: 'root' })
export class LocationChecklistService {

  constructor(private checklistRepo: CheckChecklistRepository,
              private locationApi: BlinkLocationApi,
              private locationRepo: BlinkLocationRepository,
              private modalController: ModalController,
              private networkService: NetworkService,
              private alertController: AlertController,
              private translate: TranslateService,
              private t: GlobalTranslateService,
              private barcodeScanner: BarcodeScannerService,
              private fillChecklistService: FillChecklistService) {
  }

  async selectLocationChecklist(): Promise<void> {
    const locationSearchModal = await this.modalController.create({
      component: CostCentreSearchComponent,
      id: 'CostCentreSearch',
      showBackdrop: true,
      backdropDismiss: false
    });

    locationSearchModal.onWillDismiss().then(response => {
      this.selectChecklistByLocation(response.data)
    });
    locationSearchModal.present();
  }

  async selectChecklistByLocation(location: BlinkLocation): Promise<void> {
    if (location) {
      this.checklistRepo.getNotStartedChecklists(location.Id)
        .pipe(take(1))
        .subscribe(async checklists => {
          if (checklists.length === 1) {
            await this.fillChecklistService.showCheckListDetail(checklists[0], true, location);
          } else {
            const locationChecklistSelectModal = await this.modalController.create({
              component: LocationChecklistSelectComponent,
              id: 'LocationChecklistSelect',
              componentProps: {
                location,
                checklists
              },
              showBackdrop: true,
              backdropDismiss: false
            });
            locationChecklistSelectModal.onWillDismiss().then(response => {
              if (response.data) {
                this.fillChecklistService.showCheckListDetail(response.data, true, location);
              }
            })
            locationChecklistSelectModal.present();
          }
        });


    } else if (!this.networkService.connected) {
      this.alertController.create({
        message: this.translate.instant(this.t.location_offline),
        buttons: [
          {
            text: this.translate.instant(this.t.ok)
          }
        ]
      }).then(alert => alert.present());
    }
  }

  async scanLocationCode() {
    const locationCode = await this.barcodeScanner.scanBlinkLocationCode();
    if (locationCode) {
      if (!this.networkService.connected) {
        this.locationRepo.getLocationByQrCode(locationCode)
          .subscribe(location => {
            this.selectChecklistByLocation(location);
          });
      } else {
        this.locationApi.getLocationByQrCode(locationCode)
          .subscribe(location => {
            this.selectChecklistByLocation(location);
          });
      }
    } else {
      this.alertController.create({
        header: this.translate.instant(this.t.error),
        message: this.translate.instant(this.t.invalidObjectCode),
        buttons: [
          {
            text: this.translate.instant(this.t.ok)
          }
        ]
      }).then(alert => alert.present());
    }
  }
}
