import { Component, inject, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractNgModelComponent } from '../../abstracts/ng-model.component';
import { DatetimeCustomEvent, IonDatetime, PopoverController } from '@ionic/angular';
import * as dayjs from 'dayjs';
import { GlobalTranslateService } from '@blink/util';

@Component({
  selector: 'ui-datetime',
  templateUrl: './ui-datetime.component.html',
  styleUrls: ['./ui-datetime.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class UiDatetimeComponent extends AbstractNgModelComponent {
  @ViewChild('dateTime') dateTime: IonDatetime;
  @Input() presentation: 'date' | 'time' | 'month-year' = 'date';
  @Input() minDate = '1980';
  @Input() maxDate: string;
  @Input() setNowButton = false;

  t = inject(GlobalTranslateService);

  initTime: string = new Date().toISOString();

  constructor(private popoverController: PopoverController) {
    super();
  }

  onDateTimeChange(event: DatetimeCustomEvent) {
    if (this.presentation === 'date' &&
      !!this.dateTime &&
      !event.target.classList.contains('month-year-picker-open') &&
      this.value !== this.dateTime.value) {
      this.value = dayjs(this.dateTime.value as string).toISOString();
      void this.popoverController.dismiss(this.value);
    } else if (this.presentation === 'date' && !!this.dateTime && event.target.classList.contains('month-year-picker-open')) {
      this.dateTime.value = undefined;
    } else if(this.presentation === 'month-year') {
      console.log('month-year-picker-open', this.dateTime);
      this.value = dayjs(this.dateTime.value as string).toISOString();
      void this.popoverController.dismiss(this.value);
    }
  }

  setTimeNow() {
    this.value = dayjs().toISOString();
    if (this.dateTime) {
      this.dateTime.value = this.value;
    }
    void this.popoverController.dismiss(this.value);
  }

  submitTime() {
    if (this.dateTime.value) {
      this.value = dayjs(this.dateTime.value as string).toISOString();
    } else {
      this.value = this.initTime;
    }
    void this.popoverController.dismiss(this.value);
  }

  submitToday() {
    void this.popoverController.dismiss(dayjs().toISOString());
  }
}
