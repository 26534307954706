export class UiTranslateDisplay {
  defaultText: string;
  translations: any[];
  property: string;
  currentLanguageId = 2;

  get translation() {
    return this.translations?.find(
      x => x.LanguageId === this.currentLanguageId
    );
  }

  get mainText(): string {
    if (this.currentLanguageId === 1) {
      return this.defaultText;
    }
    return this.translation?.[this.property] || this.defaultText;
  }

  get subText() {
    if (this.translation && this.translation[this.property]) {
      return this.defaultText;
    }
    return null;
  }
}
