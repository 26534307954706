import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { QuillModule } from 'ngx-quill';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ErrorHandlerService } from '../services/shared/error-handler.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { COMPONENTS } from '../components';
import { PAGES } from '../pages';
import { UpdateService } from '../services/shared/UpdateService';
import { BlinkUpdateComponent } from './components/blink-update/blink-update.component';
import { SharedModule } from './shared';
import {
  APP_NAME,
  APP_VERSION,
  ApplicationInsightsService,
  BlinkMissingTranslationHandler,
  ENV_PROD,
  ENV_VARS_SYSTEM
} from '@blink/util';
import { ApiModule, AuthInterceptor, ClientInfoInterceptor, RetryInterceptor, SessionInterceptor } from '@blink/api';
import { appName, appVersion } from '../settings';
import { BlinkCoreModule, BlinkLoginModule, LoginMethod } from "@blink/core";
import { CheckResultsLineItemModule } from '@blink/check/shared/components/results-list-item';
import { PLUGINS } from '../plugins';
import { ComponentsAppShellModule } from '@blink/components/app-shell';
import { AppInitializeService } from './app.initializer';
import { UiLanguageSelectModule } from 'shared/ui/language-select';


export function translateFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json`);
}

@NgModule({ declarations: [
        AppComponent,
        PAGES,
        COMPONENTS,
        BlinkUpdateComponent
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        SharedModule,
        IonicModule.forRoot({
            mode: 'md'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (translateFactory),
                deps: [HttpClient]
            },
            isolate: false,
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: BlinkMissingTranslationHandler
            }
        }),
        ApiModule,
        AppRoutingModule,
        QuillModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        }),
        BlinkLoginModule.forRoot({
            allowedLoginMethod: LoginMethod.BOTH,
            devPassword: environment.variables.password,
            devUsername: environment.variables.login
        }),
        BlinkCoreModule,
        CheckResultsLineItemModule,
        ComponentsAppShellModule,
        UiLanguageSelectModule], providers: [
        PLUGINS,
        { provide: ApplicationInsightsService },
        UpdateService,
        {
            provide: LOCALE_ID,
            useValue: 'de'
            // todo when have more then one language we need this to translate angulars
            // included stuff like datepipe and other formatters
            // deps: [LanguageService],
            // useFactory: (languageService) => languageService.getLanguage()
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: APP_NAME, useFactory: () => appName },
        { provide: APP_VERSION, useFactory: () => appVersion },
        { provide: ENV_PROD, useValue: environment.production },
        { provide: ENV_VARS_SYSTEM, useValue: environment.variables.system },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientInfoInterceptor,
            deps: [APP_NAME, APP_VERSION],
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: AppInitializeService, useClass: AppInitializeService },
        {
            provide: APP_INITIALIZER,
            useFactory: onAppInit,
            multi: true,
            deps: [AppInitializeService]
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}

export function onAppInit(appInitializeService: AppInitializeService) {
  return appInitializeService.init();
}
