import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, Platform } from '@ionic/angular';
import * as compareVersions from 'compare-versions';
import { AppVersionOptions } from './app-version.options';
import { RemoteConfigService } from "../remote-config.service";
import { GlobalTranslateService } from '@blink/util';

@Injectable({providedIn: "root"})
export class AppVersionService {

  constructor(private translate: TranslateService,
              private t: GlobalTranslateService,
              private platform: Platform,
              private remoteConfigService: RemoteConfigService,
              private alertController: AlertController) {
  }

  async checkAppVersion(appVersionOptions: AppVersionOptions) {
    const versionInfo = await this.remoteConfigService.getVersionInfo(appVersionOptions.versionFileName);
    const env = versionInfo['testSystems'].includes(appVersionOptions.system) ? 'test' : 'prod';
    const latestVersion = versionInfo[env].latestVersion;
    const warnVersion = versionInfo[env].warnVersionsBefore;
    const blockVersion = versionInfo[env].blockVersionsBefore;
    const currentAppVersion = appVersionOptions.currentAppVersion.split('-')[0];

    // if appVersion is lower than blockVersion, blockIt.
    if (compareVersions.compare(currentAppVersion, blockVersion, '<')) {
      await this.showUpdateDialog(appVersionOptions, this.translate.instant(this.t.update.update), true);
    }
    // if appVersion is lower than warnVersion, warn user to update it.
    else if (compareVersions.compare(currentAppVersion, warnVersion, '<')) {
      await this.showUpdateDialog(appVersionOptions, this.translate.instant(this.t.update.warnVersion));
    }
    // else if currentVersion is lower than latestVersion show info
    else if (compareVersions.compare(currentAppVersion, latestVersion, '<')) {
      await this.showUpdateDialog(appVersionOptions, this.translate.instant(this.t.update.latestVersion));
    }

  }

  async isTestingEnvironment(baseUrl: string): Promise<boolean> {
    if (!baseUrl) {
      return false;
    }

    const testingPrefixes = ['dev-', 'feature-'];
    if (testingPrefixes.some(prefix => baseUrl.includes(prefix))) {
      return true;
    }

    const versionInfo = await this.remoteConfigService.getVersionInfo("BlinkMe");
    const env = versionInfo['testSystems'].includes(baseUrl) ? 'test' : 'prod';

    return env === 'test';
  }

  private async showUpdateDialog(appVersionOptions: AppVersionOptions, message: string, block = false) {
    const buttons = [];

    if (!block) {
      buttons.push({
        text: this.translate.instant(this.t.cancel),
        role: 'cancel'
      });
    }

    buttons.push({
      text: this.translate.instant(this.t.update.update),
      role: 'update'
    });


    const alert = await this.alertController.create({
      message,
      buttons,
      backdropDismiss: !block
    });


    alert.onDidDismiss().then(async response => {
      if (response.role === 'update') {
        if (this.platform.is('ios')) {
          window.open(appVersionOptions.iosUrl);
        } else {
          window.open(appVersionOptions.androidUrl);
        }
      }
      return !block;
    });

    await alert.present();
  }
}
