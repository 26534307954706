<!--<ion-app>-->
<!--  <ion-split-pane contentId="main-content" [ngClass]="{'ui-mobile-tabs-active': userContext}"-->
<!--                  [class.small-width]="router.url.includes('login')"-->
<!--                  [class.medium-width]="!router.url.includes('login') && !userContext">-->
<!--    <ion-menu [class.hide]="router.url.includes('login') || !userContext"-->
<!--              contentId="main-content" menuId="main-menu">-->
<!--      <ui-main-menu [class.ios-offset]="devicePlatform === 'ios'"-->
<!--                    [showUpdateButton]="updateService.showUpdateButton"-->
<!--                    [tabs]="menuTabs"-->
<!--                    [companies]="companies$ | async"-->
<!--                    [selectedCompanyId]="selectedCompanyId$ | async"-->
<!--                    [items]="menuItems"-->
<!--                    (companySelected)="switchCompany($event.data)">-->
<!--      </ui-main-menu>-->
<!--    </ion-menu>-->
<!--    <ion-router-outlet id="main-content"></ion-router-outlet>-->
<!--  </ion-split-pane>-->
<!--  <ui-mobile-tabs *ngIf="userContext"-->
<!--                  [tabs]="menuTabs">-->
<!--  </ui-mobile-tabs>-->

<!--  <ui-loading></ui-loading>-->
<!--  <button *ngIf="debug" hidden cy="submit-checklist-test"-->
<!--          (click)="fillChecklistService.finish(1, false, true)">-->
<!--  </button>-->
<!--</ion-app>-->

<blink-app-shell
  [showMenu]="!!userContext"
  [uiMenuItems]="menuItems"
  [showTabs]="!!userContext"
  [tabs]="menuTabs"
  (companySelected)="switchCompany($event.data)"
  [newVersionAvailable]="updateService.showUpdateButton"
  [companies$]="companies$"
  [selectedCompanyId$]="selectedCompanyId$"
>
</blink-app-shell>

<button
  *ngIf="debug"
  hidden
  (click)="fillChecklistService.finish(1, false, true)"
  data-testid="submit-checklist-test"
></button>
