import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe, SafePipe } from './pipes/save-html.pipe';
import { TestIdDirective } from './testing';
import { OrderByDatePipe } from './pipes/orderByDate.pipe';
import { DayjsPipe } from './pipes/dayjs.pipe';
import { RoundMinutePipe } from './pipes/roundMinute.pipe';
import { AuthTokenPipe } from './pipes/auth-token.pipe';
import { ConvertUrlsToHtmlLinksPipe } from './pipes/convert-urls-to-html-links.pipe';
import { FirstLetterUpperCasePipe } from "./pipes/firstLetterUpperCase.pipe";
import { FileTypePipe } from './pipes/fa-icon-for-file-ending.pipe';
import { DayPipe } from './pipes/day.pipe';
import { BlinkFormatNamePipe } from './format-name/format-name';
import { ContrastColorPipe, EnumToArrayPipe, PercentagePipe, IsWeekendPipe } from './pipes';

const utilComponents = [
  SafeHtmlPipe,
  SafePipe,
  OrderByDatePipe,
  DayjsPipe,
  RoundMinutePipe,
  AuthTokenPipe,
  FirstLetterUpperCasePipe,
  FileTypePipe,
  DayPipe,
  ConvertUrlsToHtmlLinksPipe,
  BlinkFormatNamePipe,
  EnumToArrayPipe,
  ContrastColorPipe,
  TestIdDirective,
  PercentagePipe,
  IsWeekendPipe
]

@NgModule({
  imports: [CommonModule],
  declarations: [...utilComponents],
  exports: [...utilComponents],
  providers: [PercentagePipe]
})
export class UtilModule {
}
