import { inject, Injectable } from '@angular/core';
import { BlinkLanguage } from '@blink/shared-blink-types';
import { ApiManager, BlinkService } from '../../shared';
import { BlinkLanguageRepository } from './language.repository';

@Injectable({ providedIn: 'root' })
export class BlinkLanguageApi {
  private api = inject(ApiManager)
    .createApiForEndpoint<BlinkLanguage>(
      'odata/v1',
      'Languages',
      BlinkService.Core
    );

  constructor(private languageRepo: BlinkLanguageRepository) {
  }

  get() {
    return this.api.get<BlinkLanguage[]>({})
      .subscribe(languages => this.languageRepo.setLanguages(languages));
  }
}
