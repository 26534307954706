import { createStore, select, setProps, withProps } from '@ngneat/elf';
import {
  deleteEntities,
  getActiveEntity,
  getAllEntities,
  getEntityByPredicate,
  selectActiveEntity,
  upsertEntities,
  withActiveId,
  withEntities
} from '@ngneat/elf-entities';

import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlinkMeUserContext, TimeTrackingLocation, TimeTrackingLocationActivity, TimeTrackingLocationQrCode } from '.';
import { PersistStateService } from '../../shared';
import { TimeTrackingModes, TimeTrackingProposedDuration } from './time-tracking-context';
import { TimeTrackingMode } from '@blink/shared-blink-types';

interface TimeTrackingContextRepoProps {
  AutoStopDurationHours: number;
  TimeTrackingMode: TimeTrackingMode;
  LocationActivities: TimeTrackingLocationActivity[];
  Locations: TimeTrackingLocation[];
  ProposedDurations: TimeTrackingProposedDuration[];
}

const store = createStore(
  { name: 'time-tracking-context' },
  withActiveId(),
  withEntities<TimeTrackingLocationQrCode, 'QrCode'>({ idKey: 'QrCode' }),
  withProps<TimeTrackingContextRepoProps>({
    AutoStopDurationHours: 0,
    TimeTrackingMode: TimeTrackingMode.Exact,
    LocationActivities: [],
    Locations: [],
    ProposedDurations: []
  })
);

@Injectable({ providedIn: 'root' })
export class TimeTrackingContextRepository {
  store = store;
  timeTrackingMode$ = store.pipe(select(t => t.TimeTrackingMode),
    map(t => {
      return {
        timeTrackingMode: t,
        isExact: t === TimeTrackingMode.Exact,
        isProposed: t === TimeTrackingMode.Proposed
      } as TimeTrackingModes;
    }));
  selectActive$: Observable<TimeTrackingLocationQrCode> = store.pipe(selectActiveEntity());
  activeLocationAndActivity$ = combineLatest([
    this.selectActive$,
    store.pipe(select(t => t.Locations)),
    store.pipe(select(t => t.LocationActivities))
  ]).pipe(
    map(([ttLocationQrCode, locations, locationActivities]) => {
      if (ttLocationQrCode) {
        const location = locations.find(t => t.Id === ttLocationQrCode.LocationId);
        const locationActivity = locationActivities?.find(t => t.Id === ttLocationQrCode.LocationActivityId);
        return {
          location: location as TimeTrackingLocation,
          locationActivity: locationActivity as TimeTrackingLocationActivity
        };
      }
      return null;
    }));
  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();

  constructor(private persistStateService: PersistStateService) {
    this.persistStateService.persistState(store, false).subscribe(() => {
        this.persistentStateReadySubject.next(true)
      }
    );
  }

  update(userContext: BlinkMeUserContext) {
    if (userContext && userContext.TimeTrackingContext) {
      store.update(upsertEntities(userContext.TimeTrackingContext.LocationQrCodes));
      store.update(setProps({
        AutoStopDurationHours: userContext.TimeTrackingContext.AutoStopDurationHours,
        TimeTrackingMode: userContext.TimeTrackingContext.TimeTrackingMode,
        LocationActivities: userContext.TimeTrackingContext.LocationActivities,
        Locations: userContext.TimeTrackingContext.Locations,
        ProposedDurations: userContext.TimeTrackingContext.ProposedDurations
      }));
    }
    this.clearOldData(userContext);
  }

  clearOldData(userContext: BlinkMeUserContext) {
    const activeEntity = store.query(getActiveEntity());

    //entities
    const allEntities = store.query(getAllEntities())
      .filter(t => !activeEntity || t.QrCode !== activeEntity.QrCode)
      .map(t => t.QrCode);

    const deleted =
      allEntities
        .filter(item => userContext.TimeTrackingContext.LocationQrCodes.map(t => t.QrCode).indexOf(item) < 0);

    store.update(deleteEntities(deleted));
  }

  getLocationQrCode(locationCode: string): TimeTrackingLocationQrCode | null {
    return store.query(getEntityByPredicate(entity => entity.QrCode.toLowerCase() === locationCode.toLowerCase()))
  }

  getLocation(locationId: number): TimeTrackingLocation {
    return store.getValue().Locations.find(t => t.Id === locationId);
  }

  getProposedDurations(): TimeTrackingProposedDuration[] {
    return store.getValue().ProposedDurations
  }
}
