<ng-template #pageTemplate>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-toggle *ngIf="menuButton" menu="main-menu">
          <ion-button>
            <ui-icon [icon]="BlinkIcon.Menu" size="xl"></ui-icon>
          </ion-button>
        </ion-menu-toggle>
        <ion-back-button *ngIf="_showDefaultBackButton"
                         [defaultHref]="backHref"
                         [text]="t.back | translate">
        </ion-back-button>
        <ion-button *ngIf="_showCustomBackButton"
                    slot="start"
                    (click)="customBackButtonClick.emit()"
                    class="back-button">
          <ui-icon slot="start" [icon]="customBackButtonIcon" class="mr-7"
                   [class.black]="customBackButtonBlack"></ui-icon>
          {{ customBackButtonText | translate | firstUppercase }}
        </ion-button>
        <ion-button *ngIf="modalDismiss" (click)="modalController.dismiss()">
          <ui-icon slot="icon-only" [icon]="BlinkIcon.Close" size="lg"></ui-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>
        <ng-content select="[headerTitle]"></ng-content>
        {{ title | translate | firstUppercase }}
        <small *ngIf="subtitle" class="font-normal">{{ subtitle }}</small>
      </ion-title>
      <div slot="end">
        <ng-content select="[headerButtons]"></ng-content>
        <ion-buttons class="mr-15" slot="end">

          <ion-button *ngIf="addButtonClick.observed && addButtonVisible"
                      fill="solid"
                      color="success"
                      data-testid="btn_add"
                      (click)="onAddClick($event)">
            <ui-icon slot="start" [icon]="BlinkIcon.CreateStyleless" class="mr-5"></ui-icon>
            {{ addButtonLabel | translate | firstUppercase }}
          </ion-button>
        </ion-buttons>
      </div>

      <ion-progress-bar *ngIf="loading.readLoading$ | async"
                        type="indeterminate"
                        style="height: 3px;">
      </ion-progress-bar>

    </ion-toolbar>

    <ng-content select="[fixed]"></ng-content>

  </ion-header>

  <!--  this looks shitty on blink-me... needs checking on both (checking on check)! -->
  <!--  <ion-content style="&#45;&#45;background: var(&#45;&#45;color-background);">-->
  <ion-content>

    <div class="content-wrapper"
         [class.content-wrapper--padding]="contentPadding"
         [class.content-wrapper--h-100]="isModal"
         [class.content-wrapper--legacy]="legacyWrapper">
      <h1 *ngIf="contentTitle" class="mt-10 pl-15 mb-0">{{ contentTitle | translate }}</h1>
      <ng-content></ng-content>
    </div>

  </ion-content>

  <ng-content select=".modal-content"></ng-content>

  <ion-footer *ngIf="this._showFooter"
              class="core-page">
    <ion-row class="ion-justify-content-end">
      <ion-col size="auto">

        <ion-button *ngIf="_showCancelButton"
                    fill="outline"
                    (click)="onDismissClick('cancel')">
          <!--          <ui-icon slot="start" [icon]="BlinkIcon.Save" class="mr-5"></ui-icon>-->
          {{ cancelButtonLabel | translate }}
        </ion-button>

        <ion-button *ngIf="_showSaveButton"
                    fill="solid"
                    color="primary"
                    [disabled]="saveButtonDisabled || !saveButtonEnabled"
                    (click)="onSaveClick()">
          <!--          <ui-icon slot="start" [icon]="BlinkIcon.Save" class="mr-5"></ui-icon>-->
          {{ saveButtonCustomLabel ? saveButtonCustomLabel : t.save | translate | firstUppercase }}
        </ion-button>

        <ion-button *ngIf="_showCreateButton"
                    fill="solid"
                    color="success"
                    [disabled]="saveButtonDisabled || !saveButtonEnabled"
                    (click)="onSaveClick()">
          <!--          <ui-icon slot="start" [icon]="BlinkIcon.Save" class="mr-5"></ui-icon>-->
          {{ saveButtonCustomLabel ? saveButtonCustomLabel : t.create | translate | firstUppercase }}
        </ion-button>

        <ion-button *ngIf="_showDeleteButton"
                    fill="solid"
                    color="danger"
                    [disabled]="!deleteButtonEnabled"
                    (click)="onDeleteClick()">
          <!--          <ui-icon slot="start" [icon]="BlinkIcon.Save" class="mr-5"></ui-icon>-->
          {{ t.delete | translate | firstUppercase }}
        </ion-button>

        <ion-button *ngIf="_showExportButton"
                    fill="solid"
                    color="primary"
                    [disabled]="!exportButtonEnabled"
                    (click)="onExportClick()">
          <!--          <ui-icon slot="start" [icon]="BlinkIcon.Save" class="mr-5"></ui-icon>-->
          {{ t.doExport | translate | firstUppercase }}
        </ion-button>


        <ng-content select="[customActions]"></ng-content>


      </ion-col>
    </ion-row>
  </ion-footer>

</ng-template>
