export default {
  home: {
    pageTitle: 'Начална страница',
    welcome: 'Добре дошли в приложението Blink me. Забавлявайте се в чата,\n' +
      '\n' +
      'Вашият мигащ екип',
    startChat: 'започвам',
    ticket: 'билет',
    timeLicenseMissing: 'Не ви е даден лиценз за Blink Time!'
  },
  conversations: { pageTitle: 'Новини', yourChats: 'Вашите чатове', chat: 'Чат' },
  settings: {
    settings: 'Настройки',
    pushEnabled: 'Получавайте насочени известия',
    system: 'система',
    languageSelection: 'избор на език',
    currentSettings: 'Текущи настройки',
    user: 'потребител',
    appVersion: 'Версия (приложение)',
    apiVersion: 'Версия (API)',
    deviceInfo: 'Версия на устройството',
    buildVersion: 'версия (компилация)',
    helpAndDataPolicy: 'Помощ и защита на данните',
    text1: 'Нашият екип за поддръжка ще се радва да ви помогне, ако имате въпроси:',
    phone: 'телефон',
    mail: 'електронна поща',
    information: 'инфо',
    documentation: 'Онлайн документация',
    documentationText: 'В нашата онлайн документация ще намерите инструкции и видеоклипове за всички важни области на Blink.',
    dataPolicy: 'защита на данни',
    support: 'поддържа',
    moreActions: 'По-нататъшни действия',
    protocol: 'протокол',
    showChatUserSecret: 'Споделете чат с друго устройство',
    scanCodeAnotherDevice: 'Сканирайте следния QR код на новото си устройство или въведете личния си код за чат на новото устройство!',
    yourPersonalCode: 'Вашият личен код за чат',
    permissions: {
      title: 'Разрешения',
      grantPermission: 'дайте разрешение',
      camera: 'камера',
      cameraHint: 'Необходим за запис на време чрез обектен код.',
      nfc: 'NFC',
      nfcHint: 'Необходим за запис на време чрез NFC етикет.',
      media: 'Вземете изображение и изображение от библиотека',
      mediaHint: 'Необходими за заснемане на изображения и документи, свързани с вашите часове и билети.',
      location: 'Местоположение',
      locationHint: 'Необходимо е да запишете местоположението си по време на запис на време.',
      notifications: 'Push известия',
      notificationsHint: 'Необходимо е да получавате насочени известия за чат съобщения, мигащи известия и стикери.',
      locationDisabledOnSystem: 'Услугите за местоположение са деактивирани. Моля, активирайте това в настройките на вашето устройство.'
    }
  },
  connectionState: {
    connecting: 'Свързване...',
    connected: 'Завързани заедно.',
    disconnecting: 'Връзката е прекъсната...',
    disconnected: 'връзката е изгубена',
    denied: 'Връзката не може да бъде установена.'
  },
  noOneToFind: 'Никой да не бъде намерен.',
  nothingToFind: 'Нищо не може да се намери.',
  noLocationFound: 'Няма намерени обекти.',
  searchPlaceholder: 'Търсене...',
  employeeSearchPlaceholder: 'Първо име Име',
  typeToSearch: 'Докоснете за търсене...',
  typeToSearchEmployee: 'Въведете името на лицето, за да започнете чат',
  createChatWithEmployee: 'Търси служители',
  createChatWithLocationTeam: 'Обект за търсене',
  createTeamleaderChat: 'Всички мои служители',
  yourLanguageSelection: 'Вашият избор на език:',
  loginInfo: 'За да се регистрирате, моля сканирайте личния си QR код:',
  updating: 'Blink me се актуализира.',
  notice: 'Забележете',
  importantNotice: 'Важна ЗАБЕЛЕЖКА',
  continue: 'По-нататък',
  intro: {
    slide1: {
      header: 'Добре дошли',
      text: 'Забавлявайте се с вашето приложение Blink me.'
    },
    slide2: {
      header: 'Чат',
      text: 'Разговаряйте директно с вашите колеги или научете последните новини от интересни групи.'
    },
    slide3: {
      header: 'Билети',
      text: 'Тук можете да видите вашите задачи и да създадете приложения.'
    }
  },
  pleaseWait: 'Моля Изчакай...',
  skip: 'Пропускане',
  startAgain: 'Започнете наново',
  lock: {
    createPinHeader: 'Вашият личен ПИН',
    createPinInfo: 'За да защитите данните си, тук можете да зададете PIN, който трябва да въвеждате всеки път, когато използвате приложението Blink me.',
    validatePin: 'Моля, въведете ПИН кода отново.',
    tryAgain: 'PIN не съвпада. Моля, опитайте отново.',
    triedToOften: 'За съжаление вашият ПИН не съвпада. Искате ли да рестартирате процеса или да го пропуснете и да го направите по-късно?',
    enterPinHeader: 'ПИН въвеждане',
    enterPinInfo: 'Моля, въведете вашия ПИН, за да отключите приложението.',
    remainingTrys: 'Още {{amount}} опита. След това трябва да влезете отново в приложението.',
    tooManyEnters: 'За съжаление вече не е възможна регистрация с ПИН код. Моля, влезте отново с вашата Blink ID карта.',
    reSignIn: 'Нова регистрация',
    setpin: 'Въвеждане на PIN при използване на приложението',
    skip: 'Не искате ли да зададете ПИН сега? Можете да активирате тази функция по всяко време чрез настройките на приложението, включително по-късно.',
    dontUsePin: 'не използвайте ПИН',
    deactivatePinHeader: 'Деактивиране на ПИН',
    deactivatePinInfo: 'Моля, въведете вашия ПИН, за да деактивирате защитата.'
  },
  active: {
    yourTickets: 'Вашите билети',
    createNewTask: 'Създайте нов билет',
    assignee: 'редактор',
    location: 'обект',
    dueDate: 'С падеж',
    createTask: 'Създайте билет',
    taskCreated: 'Билетът е създаден.',
    backToTasks: 'Обратно към билетите',
    createdAt: 'създадено на',
    attachments: 'Прикачени файлове',
    addFileOrImage: 'Добавете файл или снимка',
    addImage: 'добавете снимка',
    comments: 'Коментари',
    addComment: 'добави коментар',
    addCommentInfo: 'Коментарът е видим за всеки, който може да види този билет.',
    commentCreated: 'Коментарът е запазен.',
    showClosed: 'Вижте завършените билети',
    resetFilter: 'нулиране на всички филтри',
    changeTicketStatusTo: 'Променете състоянието на билета на:',
    editTask: 'Редактиране на билет',
    saveTask: 'Запазете билет',
    taskSaved: 'Билетът е запазен.',
    deleteImage: 'Ясно изображение',
    deleteImageConfirmation: 'Наистина ли искате да изтриете изображението?',
    deleteAttachment: 'Изтриване на прикачения файл',
    deleteAttachmentConfirmation: 'Сигурни ли сте, че искате да изтриете този прикачен файл?',
    deleteComment: 'Изтриване на коментар',
    deleteCommentConfirmation: 'Наистина ли искате да изтриете коментара?',
    changeAssignee: 'Промяна на редактора',
    duplicateTask: 'Дублиран билет',
    duplicateTaskConfirmation: 'Искате ли да дублирате този билет?',
    taskDuplicated: 'Билетът беше дублиран.',
    deleteTask: 'Изтриване на билет',
    deleteTaskConfirmation: 'Сигурни ли сте, че искате да изтриете този билет?',
    taskDeleted: 'Билетът е изтрит.',
    assigneeChanged: 'Процесорът е сменен.',
    statusChanged: 'Статусът на билета е променен.',
    startDate: 'започвам',
    endDate: 'Край',
    doctorVisitDate: 'Дата на посещение при лекар',
    doctorVisitDescription: 'Дата на посещение при лекар: {{date}}',
    chooseDate: 'Изберете дата',
    comment: 'Коментар (по избор)',
    dateError: 'Крайната дата не може да бъде преди началната дата',
    employee: 'служители',
    pleaseConnectToLoad: 'Моля, установете интернет връзка, за да заредите вашите билети.'
  },
  emptyState: {
    chats: 'Създайте чат!',
    chat: 'Напиши първото съобщение :)',
    tasks: 'Няма билети за намиране.',
    attachments: 'Няма налични прикачени файлове.',
    comments: 'Няма налични коментари.',
    vacation: 'За тази година няма въведени отпуски'
  },
  formError: {
    min: 'Стойността трябва да е по-голяма от {{value}}.',
    min2: 'Стойността трябва да е по-голяма или равна на {{value}}.',
    max: 'Стойността трябва да е по-малка или равна на {{value}}.',
    requiredBecause: 'Изисква се стойност, защото {{name}} е попълнено.',
    isRequired: '{{name}} е задължително.',
    numberInUse: 'Личният номер вече се използва.',
    maxlength: '{{name}} не може да съдържа повече от {{value}} знака.',
    faultyBlinkId: 'Идентификаторът на мигане е неправилен'
  },
  ngSelect: {
    typeToSeach: 'Моля, въведете 3 или повече букви.',
    loading: 'зареждане на данни...',
    notFound: 'Няма намерени {{title}}.',
    clearAll: 'Обновявам до първоначалното'
  },
  placeholder: {
    employee: 'Търсене по име и фамилия, личен номер, Blink ID',
    location: 'Търсене по име, номер или кодове на обекти',
    date: 'Изберете дата',
    searchTasks: 'Търсене на билети...'
  },
  hasChat: 'Започнете чат сега',
  hasNoChatYet: 'За съжаление все още не съм инсталирал приложението Blink me.',
  noBlinkActiveLicence: 'Без лиценз',
  noBlinkActiveLicenceInfo: 'В момента нямате лиценз за това приложение. Моля, свържете се с вашия ръководител.',
  noBlinkMeLicence: 'Без лиценз',
  noBlinkMeLicenceInfo: 'Това приложение не може да се използва с вашата система. Моля, използвайте Blink Time от приложението или Playstore.',
  noSuitableAppFound: 'Не е намерено приложение за отваряне на файла.',
  myChats: 'Вашите чатове',
  hasNoChatsHome: 'Започнете да чатите сега! Можете да говорите с всички колеги и да участвате в обектни и екипни групи.',
  hasNoTicketsHome: 'Няма налични отворени билети.',
  myTickets: 'Вашите билети',
  myEmployees: 'Моите служители',
  group: 'група',
  confirmLogoutWithChat: 'Ако излезете, вашите съобщения в чата ще бъдат изтрити. Искате ли да продължите?',
  messageNotDecryptable: 'Съобщението не можа да бъде декриптирано.',
  chat: {
    userAlreadyExists: 'Вече сте настроили чат на друго устройство. За да можете да използвате чат на това устройство, трябва да свържете устройствата. Като алтернатива можете да рестартирате настройката, но тогава чатът вече не може да се използва на другото устройство.',
    linkDevices: 'Свързване на устройства',
    withoutChat: 'Продължете без чат',
    setupAgain: 'Нулирайте чата',
    setupAgainText: 'Ако настроите чата отново, всички съобщения от съществуващите индивидуални чатове ще бъдат изтрити и чатът вече не може да се използва на другите устройства! Искате ли да продължите?',
    LinkDevicesText: 'За да свържете устройства за чат, отворете Настройки на другото си устройство и изберете „Споделяне на чат с друго устройство“.',
    personalToken: 'Вашият личен код за чат',
    wrongCode: 'Предоставеният код за чат е неправилен.',
    beingPrepared: 'Чатът се подготвя.',
    twilioAccessDenied: 'Връзката с услугата за чат не може да бъде установена. Моля, свържете се с поддръжката на Blink.',
    notificationsDenied: 'Известията са деактивирани на вашето устройство/браузър. Отидете в Настройки и активирайте известията за Blink me, за да получавате известия. След това можете да активирате известията в личните си настройки.',
    deleted: 'Чатът е изтрит',
    unavailable: 'Чатът не е наличен',
    participant: 'участник',
    teamChatDescription: 'Всички служители, за които сте регистрирани като мениджър, се добавят към тази чат група.',
    locationChatDescription: 'Служителите се добавят автоматично, ако имат график за този обект през последните четири седмици или следващите четири седмици.',
    createEmployeeChatDescription: 'Ще бъде създаден частен чат с избрания служител.',
    teamChatCreationTitle: 'Създайте чат група',
    createTeamChatDescription: 'Създава се чат група, в която автоматично се добавят всички служители, за които сте регистрирани като мениджър.',
    createLocationChatDescription: 'За избрания обект се създава чат група, в която автоматично се добавят служители, ако имат план за това през последните четири седмици или следващите четири седмици.'
  },
  protocol: {
    SEND_EMAIL: 'Изпратен по имейл',
    DELETE: 'Изчистване на дневника',
    deleteMessage: 'Наистина ли искате да изтриете дневника? Това действие не може да бъде отменено.',
    Send: 'Изпратете',
    SuccessMessage: 'Регистърът бе изпратен успешно до поддръжката на Blink.',
    ConfirmMessage: 'Първо се свържете с екипа за поддръжка и изпратете регистрационния файл само ако бъде поискан.',
    Description: 'Описание на проблема'
  },
  timeTracking: {
    title: 'Проследяване на времето',
    start: 'Започнете',
    startDescription: 'Щракнете върху начало, за да започнете да проследявате времето',
    started: 'започна',
    stop: 'Да спра',
    duration: 'Продължителност на времето',
    syncButtonLabel: 'Часовете на качване са записани офлайн',
    offlineSyncMessage: 'За да прехвърлите запазените времена, трябва да сте онлайн.',
    successfulSync: 'Часовете са качени',
    scanNowQrCode: 'QR код',
    scanNowQrCodeDescription: 'Сканирайте QR кода в обекта.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Дръжте смартфона си срещу NFC стикера в обекта.',
    nfcDescription: 'Откриване чрез NFC: Задръжте мобилния си телефон срещу кода на обекта',
    noTimeTrackingMethodAvailableDescription: 'Няма конфигуриран метод за проследяване на времето за този потребител. Моля, свържете се с вашия ръководител на екипа.',
    noLocationCode: 'Няма наличен код',
    noLocationCodeDescription: 'Изберете тази опция, ако в обекта няма QR код или NFC стикер. Предоставянето на коментар е задължително.',
    noLocationCodeConfirm: 'Сигурни ли сте, че искате да започнете без обектен код? Трябва да предоставите коментар, за да спрете.',
    wrongCode: 'Сканираният QR код не е обектен код!',
    readNotice: 'Моля, прочетете инструкциите!',
    warning: 'внимание',
    lessThanAMinuteTitle: 'Пълен запис на времето',
    lessThanAMinute: 'Започнахте преди по-малко от 1 минута. Сигурни ли сте, че искате да спрете?',
    continue: 'Продължете да записвате',
    timeAlert: {
      differenceInMinutesHint: 'Часът на вашия смартфон се различава от този на сървъра. Разликата се регистрира',
      differentTimeZoneHint: 'Часовата зона на вашия смартфон се различава от часовата зона на сървъра. Разликата се регистрира.'
    },
    leaveComment: {
      withCode: 'Информацията за обекта/услугата не беше ясна. Моля, кажете ни в кой имот/услуга сте работили.',
      withoutCode: 'Не е уловен обектен код при стартиране или спиране. Моля, обяснете защо сте започнали/спряли без обект.'
    },
    comment: 'коментар',
    enterComment: 'Коментар за проследяване на времето',
    differentQrCode: {
      title: 'Промяна на обекта?',
      continueInLocation: 'Продължете да работите в {{locationName}}.',
      automaticStopInLocation: 'Записването на времето в {{locationName}} ще спре автоматично.',
      stopInLocation: 'Спрете записа на времето в {{locationName}}.',
      stop: 'Да спра',
      stopNoLocation: 'Спрете записа на времето.',
      continueNoOldLocation: 'Записването на времето за предишния обект се спира автоматично.',
      continueWorking: 'Продължавай да работиш',
      continueWorkingInNewLocation: 'Продължете да работите в нов имот.',
      stopWithLocationActivity: 'Спрете записа на времето на {{locationActivity}}.',
      continueWithNewLocationActivity: 'Продължете да работите с {{locationActivity}}',
      stopOldLocationActivity: 'Записът на време на {{locationActivity}} спира автоматично.',
      stopOldNoLocationActivity: 'Записът на време без изпълнение се спира автоматично.',
      continueNoLocationActivity: 'Продължете да работите без изпълнение.',
      sameLocationTitle: 'Промяна на ефективността?',
      differentLocationTitle: 'Промяна на обекта?'
    },
    saveAndStop: 'Запазване и спиране',
    noLocationScanned: '- Няма обект -',
    noLocationFound: '- Обектът не е ясен -',
    timerDifference: '{{h}} часа {{mm}} минути',
    stopSuccess: 'Времето е записано успешно',
    stopSuccessToast: 'Записването на времето спря успешно.',
    stopTimeTracking: 'Да спра',
    manual: {
      time: 'време',
      locationSelect: 'Изберете обект',
      loadMore: 'зареди още',
      trackTime: 'Рекордно време',
      location: 'обект',
      locationActivity: 'производителност',
      date: 'Дата',
      startTime: 'от',
      endTime: 'До',
      create: 'Потвърдете',
      createAgain: 'Запишете допълнително време за този обект',
      errorUntil: 'От време трябва да е по-малко от До време',
      worktimeDifference: 'Работни часове:',
      worktimeDifferenceValue: '{{hours}} часа {{minutes}} минути',
      worktimeDifferenceValueMinutes: '{{minutes}} минути',
      success: 'Времето е записано успешно',
      break: 'прекъсване:',
      breakMinutes: '{{minutes}} минути',
      breakHoursAndMinutes: '{{hours}} часа {{minutes}} минути',
      noActivity: '- няма изпълнение -',
      locationPlaceHolder: 'Търсене по име, номер или кодове на обекти',
      conflictError: 'Вече има запис на време в посочените периоди. Не се допускат припокривания. Моля, проверете данните си.',
      currentDayDuration: 'Вече е записано',
      expectedDuration: 'Очаквано работно време',
      expectedDurationIncludedCurrent: 'включително текущ вход'
    },
    differenceInMinutesHint: 'Часът на вашия смартфон се различава от този на сървъра. Разликата се регистрира',
    differentTimeZoneHint: 'Часовата зона на вашия смартфон се различава от часовата зона на сървъра. Разликата се регистрира.',
    proposed: {
      capture: 'Улавяне',
      time: 'време',
      captureTime: 'Рекордно време',
      button: 'Потвърдете',
      title: 'Потвърдете работното време',
      includingBreak: '(включително почивка)',
      from: 'от',
      to: 'До',
      break: 'почивка',
      hour: 'Часовник',
      confirm: {
        description: 'Моля, потвърдете работното си време',
        confirm: 'Да, така е',
        editButton: 'Не, това не е правилно'
      },
      edit: {
        description: 'Моля, посочете продължителността',
        breakIncluded: 'Включително {{ duration }} прекъсване',
        comment: 'коментар',
        yourComment: 'твоят коментар',
        minutes: 'минути',
        hours: 'Часа'
      },
      reasons: {
        overtime: 'Извънреден труд по вина на клиента',
        faster: 'Готово по-бързо',
        other: 'Разни',
        representative: 'Бях заместник'
      },
      description: 'Потвърдете работното си време веднага след като приключите работа в имота.'
    },
    codeNotAssignable: 'Сканираният QR код не може да бъде присвоен!',
    running: 'Тече запис на време'
  },
  timeSheet: {
    title: 'Време и планиране',
    noPlannings: 'Без планиране',
    planned: 'Планирано',
    workingTime: 'работни часове',
    workloadDuration: 'Седмично работно време',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Трябва',
    workedDuration: 'Е',
    hasWorkload: 'Работете този ден',
    pleaseConnectToLoad: 'Моля, установете интернет връзка, за да заредите часовете си.',
    footer: {
      planned: 'Планирано',
      worklogsTotal: 'Общо часове',
      workloadTotal: 'Работно време по договор',
      difference: 'разлика'
    },
    homepageTitle: 'Вашият работен ден'
  },
  planning: {
    planRoute: 'Планирайте маршрут',
    title: 'планиране',
    modifyMinutes: 'Единна ставка:',
    includingPause: 'прекъсване:',
    noNavAppFound: 'Няма намерено приложение за навигация'
  },
  worklog: {
    title: 'Записано време',
    absenceTitle: 'отсъствие',
    workingTime: 'работни часове',
    breakDuration: 'прекъсване',
    reduce: 'единна ставка',
    attachments: 'Прикачени файлове',
    comments: 'Коментари',
    comment: 'коментар',
    noLocation: 'Няма посочен обект',
    changedBy: 'Часът е променен от {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'Сигурни ли сте, че искате да изтриете този прикачен файл?',
    attachmentDeleted: 'Прикаченият файл е изтрит',
    confirmCommentDelete: 'Сигурни ли сте, че искате да изтриете този коментар?',
    commentDeleted: 'Коментарът беше изтрит'
  },
  tabs: {
    home: 'У дома',
    times: 'пъти',
    chat: 'Чат',
    tickets: 'Билети',
    more: 'Повече'
  },
  profile: {
    title: 'профил',
    testing: 'Тестване',
    testingDescription: 'Тази зона се вижда само когато е свързана към тестова система.',
    testingAllowQrCodeOnWeb: 'Разрешете ръчно въвеждане на QR код',
    pushNotifications: 'Push известия',
    reloadUserContext: 'Презаредете UserContext',
    pushNotificationSuccessful: 'Push известието е получено успешно',
    editProfilePicture: 'промяна на профилната снимка',
    editProfilePictureTitle: 'Изберете раздел',
    pushNotificationNotice: 'За теста е необходимо разрешението „Редактиране на настройките на клиента“.',
    send: 'Изпратете',
    profilePicture: 'Снимка на профила'
  },
  help: { title: 'Помогне' },
  myVacations: {
    vacation: 'ваканция',
    amountAvailableVacationDays: 'Все още има {{amount}} дни ваканция',
    totalVacationDays: 'Право на ваканция {{ total }} дни',
    remainingVacationDays: '({{ total }} дни {{ sign }} {{ remaining }} дни оставаща ваканция {{ previousYear }})',
    xDaysVacations: '{{ total }} дни ваканция',
    title: 'Вашата почивка'
  },
  externalLinks: {
    title: 'външни връзки',
    notice: 'Тази връзка е предоставена от вашата компания. Вие напускате системата Blink.'
  },
  stickers: {
    title: 'Вашите стикери',
    sticker: 'Стикери',
    sender: 'Получател',
    text: 'Относно',
    date: 'Дата',
    emptyState: 'За съжаление все още нямате стикери',
    senderDescription: 'Този стикер ви е изпратен от {{sender}} на {{date}}',
    readed: 'Прочети',
    notReaded: 'все още не съм го прочел',
    newStickerToaster: 'Нов стикер за мигане!',
    open: 'Отворете',
    next: 'По-нататък',
    titleCases: {
      case1: 'Поздравления за първия ви стикер!',
      case2: 'Страхотно, сега вече имате <b>{{count}}</b> стикера!',
      case3: 'Поздравления за вашия 10-ти стикер!',
      case4: '30 стикера - уау!',
      case5: 'Сега тук става претъпкано: <b>{{count}}</b> Стикери!'
    },
    confirmDelete: 'Наистина ли искате да изтриете тези стикери?'
  },
  autoTranslate: {
    isTranslated: 'Автоматично преведено',
    translationError: 'Автоматичният превод в момента не е възможен'
  },
  confirmLogout: 'Наистина ли искате да излезете?',
  message: 'Новини',
  migration: {
    wrongVersion: 'В текущата версия на Blink me не се поддържа влизане чрез приложението Blink Time. Моля, актуализирайте приложението Blink me до най-новата версия'
  },
  eLearning: {
    pendingTab: 'Задачи ({{value}})',
    completedTab: 'Готово ({{value}})',
    completed: 'Завършено',
    pending: 'Отворете',
    dueFalling: 'Просрочени',
    title: 'Електронно обучение',
    course: 'курс',
    dueDate: 'Валидна до',
    topics: 'предмети',
    completeDate: 'Завършен на',
    status: { Pending: 'отворен', Done: 'завършен' },
    infos: {
      pendingExists: 'Можете да намерите всички ваши отворени курсове за обучение тук.',
      finishOverdue: 'Моля, направете обучението, което вече е насрочено, възможно най-бързо.',
      allCompleted: 'Отлично! Вие завършихте успешно всичките си курсове за обучение.',
      emptyState: 'Нямате назначено обучение.'
    }
  },
  checklists: {
    title: 'Форми',
    description: 'Попълнете формуляр за обект',
    button: 'Изберете обект'
  },
  pendingTab: 'Изпълнение ({{value}})',
  completedTab: 'Завършено ({{value}})',
  workOrders: {
    title: 'Поръчки',
    homepageTitle: 'Вашите поръчки',
    responsible: 'Отговорен',
    customerContact: 'Контакт с клиента',
    trackedTime: 'Запис на времето за поръчка №{{value}}',
    show: 'Вижте поръчката',
    trackInfo: {
      confirmAndAcknowledge: 'Моля да потвърдите позициите след изпълнение и да извършите оглед на място.',
      acknowledgeOnly: 'Моля, извършете оглед на място.',
      confirmOnly: 'Моля, потвърдете позициите след изпълнение.'
    },
    pleaseConnectToLoad: 'Моля, установете интернет връзка, за да заредите вашите поръчки.',
    status: { new: 'Нов', inProgress: 'Изпълнение', completed: 'Завършено' },
    emptyState: 'Няма налични отворени поръчки.',
    emptyStateCompleted: 'Няма налични завършени поръчки.',
    details: {
      title: 'Подробности за поръчката',
      positionsTab: 'Позиции ({{value}})',
      acknowledgementsTab: 'намалява ({{value}})'
    },
    acknowledgement: {
      title: 'приемане',
      start: 'Започнете приемане',
      acknowledgedAtDate: 'Премахнато на',
      itemSelectDescription: 'Изберете всички позиции за премахване.',
      customerInformationDescription: 'Моля, посочете данните за контакт на клиента (купувача). След приемане на този имейл ще бъде изпратено потвърждение.',
      created: 'Приемането е запазено успешно',
      selectAll: 'Изберете всички',
      emptyState: 'Няма приемания.'
    },
    items: {
      items: 'позиции',
      acknowledge: 'Потвърдете позицията',
      confirmAcknowledge: 'С това потвърждавате, че работата е извършена. Състоянието на позицията се актуализира на „Завършена“.',
      status: {
        open: 'Отворете',
        planned: 'Планирано',
        performed: 'Готово',
        acknowledged: 'Премахнато',
        billed: 'Таксуван'
      },
      emptyState: 'Няма свободни позиции.'
    },
    trackTime: {
      description: 'Изберете работа, за която искате да следите времето.',
      chooseWorkOrder: 'Изберете поръчка',
      choosePosition: 'Изберете позиция'
    }
  }
};
