<ui-page [title]="t.selectChecklistByLocation" [modalDismiss]="true">

  <div fixed class="ion-padding" style="background-color: white; color: black;">
    <p slot="end" *ngIf="location">
      <small>
        <i class="far fa-city mr-5"></i>
        {{ location.Name }}
      </small>
    </p>
  </div>

    <ng-container *ngIf="checklists">
      <ion-list>
        <ion-list-header>
          <i class="far fa-file-plus fa-2x"></i>
          <ion-title>{{ t.home.locationChecklists_label | translate }}</ion-title>
        </ion-list-header>
        <check-checklist-lineitem *ngFor="let locationChecklist of checklists"
                                [checklist]="locationChecklist"
                                [showIsStarted]="true"
                                (checklistClick)="onChecklistSelected(locationChecklist)"
                                [attr.data-testid]="'location-checklist_' + locationChecklist.Id">
        </check-checklist-lineitem>
      </ion-list>

      <ui-empty-state *ngIf="!checklists.length">
        {{ t.noChecklistsForLocationFound | translate }}
      </ui-empty-state>
    </ng-container>
</ui-page>
