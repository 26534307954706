import { environment } from './environments/environment';

export const appName = 'Blink Check';
export const appVersion = '2.5.3.3956-feature-BCHECK-869-benutzerkonfigurierbare-uebers';


export const logLevel = environment.production ? 'warn' : 'debug';
export const checkSettings = {
  nonRequiredItems: ['Text', 'Header', 'Picture', 'Link', 'Video', 'PreviewButton']
};

