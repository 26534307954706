import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contrastColor'
})
export class ContrastColorPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return 'black';

    const r = parseInt(value.slice(1, 3), 16);
    const g = parseInt(value.slice(3, 5), 16);
    const b = parseInt(value.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > 175 ? 'black' : 'white';
  }
}
