import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AbstractNgModelComponent } from './ng-model.component';
import { ControlContainer, FormControl } from '@angular/forms';
import { GlobalTranslateService, hasRequiredField } from '@blink/util';
import { Subscription } from 'rxjs';

@Component({ template: '' })
export class AbstractInputComponent extends AbstractNgModelComponent implements OnInit {
  @Input() label = ''
  @Input() readonly = false;
  @Input() required = false;
  @Input() clearable = false;
  @Input() placeholder = 'typedGlobal.pleaseFill';
  @Input() type = 'text';
  @Input() info: string;

  @Input() formControlName: string;

  controlContainer = inject(
    ControlContainer,
    { optional: true, host: true, skipSelf: true }
  );
  t = inject(GlobalTranslateService);
  control: FormControl;
  statusChangesSubscription: Subscription;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBlur = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFocus = new EventEmitter<void>();

  get inputReadonly(): boolean {
    return this.readonly || typeof this.readonly !== 'boolean';
  }

  get inputRequired(): boolean {
    return this.required || typeof this.required !== 'boolean';
  }

  ngOnInit() {
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName) as FormControl;

        this.required = hasRequiredField(this.control);
        this.statusChangesSubscription = this.control?.statusChanges.subscribe(() => {
          this.required = hasRequiredField(this.control);
        });

      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      //TODO check if also ngModel is missing
      // console.warn('Can\'t find parent FormGroup directive');
    }
  }
}
