import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';

import { ApiManager, BlinkService } from '../../shared/api-manager';
import { CheckCategory } from './category';
import { CheckCategoryRepository } from './category.repository';
import { CheckChecklistApi } from '../checklist';

@Injectable({
  providedIn: 'root'
})
export class CheckCategoryApiService {
  private api = this.apiManager
    .createApiForEndpoint<CheckCategory>(
      'odata/v1',
      'Categories',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager,
              private categoryRepo: CheckCategoryRepository,
              private checklistApi: CheckChecklistApi) {
  }

  get(categoryId: number) {
    const query = {
      key: categoryId
    };
    return this.api.get<CheckCategory>(query)
      .pipe(
        tap(category => this.categoryRepo.sync([category], false))
      );
  }

  create(category: Partial<CheckCategory>) {
    return this.api.post({ body: category }).pipe(tap(
      created => this.categoryRepo.sync([{
        ...created,
        CategoryTranslations: category.CategoryTranslations
      }], false)
    ));
  }

  duplicate(category: CheckCategory) {
    const query = {
      key: category.Id,
      action: 'Duplicate'
    };
    return this.api.post(query)
      .pipe(
        switchMap(() => this.checklistApi.getCategories(category.ChecklistId))
      );
  }

  update(category: Partial<CheckCategory>) {
    const options = {
      key: category.Id,
      body: category,
      oDataReturnRepresentation: true
    };
    return this.api.put(options).pipe(tap(
      (updated) => this.categoryRepo.sync([{
        ...updated,
        CategoryTranslations: category.CategoryTranslations
      }], false)
    ));
  }

  delete(id: number) {
    return this.api.delete({ key: id }).pipe(tap(
      () => this.categoryRepo.delete(id)
    ));
  }
}
