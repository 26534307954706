import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[utilDataTestId]',
})
export class TestIdDirective implements OnInit {
  constructor(private el: ElementRef) {}

  @Input() utilDataTestId: string;

  ngOnInit(): void {
    if (this.utilDataTestId) {
      this.el.nativeElement.setAttribute('data-testid', this.utilDataTestId);
    }
  }
}
