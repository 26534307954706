import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiPageService {
  private scrollTopSubject = new Subject<void>();
  scrollTop$ = this.scrollTopSubject.asObservable();

  private scrollBottomSubject = new Subject<void>();
  scrollBottom$ = this.scrollBottomSubject.asObservable();

  scrollTop() {
    this.scrollTopSubject.next();
  }

  scrollBottom() {
    this.scrollBottomSubject.next();
  }
}
