import { Injectable } from '@angular/core';
import {
  CheckChecklist,
  CheckChecklistApi,
  CheckChecklistDataApi,
  CheckChecklistDataRepository,
  CheckChecklistRepository,
  CheckItemType,
  ChecklistSubmitFacade,
  CompanyRepository,
  FileUploadInfo,
  FillItemFileUploadService,
  HANDLE_FILE_UPLOAD,
  SessionRepository
} from '@blink/api';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { uiCancelButton, UiDialogService, UiLoadingService } from '@blink/ui';
import { NetworkService } from '@blink/capacitor-plugins';
import { first, map, take } from "rxjs/operators";
import { FillChecklistTranslateService } from './translations/fill-checklist-translate.service';
import { Capacitor } from '@capacitor/core';
import { firstValueFrom } from 'rxjs';
import { EmployeeSearchComponent } from '@blink/check/shared/components/employee-search';
import { FillChecklistLoginUser } from '@blink/check/shared/types/main';
import { BlinkEmployee, BlinkLocationBase } from '@blink/shared-blink-types';

@Injectable({ providedIn: 'root' })
export class FillChecklistService {

  constructor(private sessionRepo: SessionRepository,
              private checklistApi: CheckChecklistApi,
              private checklistRepo: CheckChecklistRepository,
              private checklistDataApi: CheckChecklistDataApi,
              private checklistDataRepo: CheckChecklistDataRepository,
              private fillItemFileUploadService: FillItemFileUploadService,
              private modalController: ModalController,
              private router: Router,
              private checklistSubmitFacade: ChecklistSubmitFacade,
              private networkService: NetworkService,
              private uiDialogService: UiDialogService,
              private uiLoading: UiLoadingService,
              private t: FillChecklistTranslateService,
              private companyRepository: CompanyRepository) {
  }


  async showCheckListDetail(checklist: CheckChecklist,
                            startChecklistFill = false,
                            location: BlinkLocationBase = null,
                            employee: FillChecklistLoginUser = null,
                            additionalValues: Map<string, string> = null) {

    if (startChecklistFill) {
      if (this.networkService.connected) {
        this.uiLoading.setWriteLoading(true);
        await this.checklistApi.getFull(checklist.Id).toPromise();
        this.uiLoading.setWriteLoading(false);
      } else {
        if (checklist.LoginUserRequired && !employee) {
          this.uiDialogService.confirm({
            title: this.t.global.internetRequired,
            text: this.t.fill.checklistOfflineLoginUserRequired,
            buttons: [uiCancelButton(
              () => {
                this.router.navigate(['checklist-home'], { replaceUrl: true });
              }
            )]
          });
          return;
        }
        if (!checklist.offlineAvailable) {
          this.uiDialogService.confirm({
            title: this.t.global.internetRequired,
            text: this.t.fill.checklistNotOfflineAvailable,
            buttons: [uiCancelButton(
              () => {
                this.router.navigate(['checklist-home'], { replaceUrl: true });
              }
            )]
          });
          return;
        }
      }


      let loginUserId = employee?.LoginUserId ?? null;
      let loginUserDisplayName = employee?.displayName ?? null;

      if (checklist.LoginUserRequired && !employee) {
        const loginUser = await this.selectLoginUser();
        if (!loginUser) {
          void this.router.navigate(['checklist-home'], { replaceUrl: true });
          return;
        }
        loginUserId = loginUser.LoginUserId;
        loginUserDisplayName = loginUser.displayName;
      }

      this.checklistDataRepo.startChecklistFill(
        { ...checklist, CompanyId: this.companyRepository.getActiveCompanyId() },
        loginUserId,
        location?.Id,
        loginUserDisplayName,
        location?.Name
      );

      this.checklistRepo.getForFill(checklist.Id)
        .pipe(take(1))
        .subscribe(checklist => {
          this.fillDefaultValues(checklist);
          this.fillAdditionalValues(checklist, additionalValues);
          void this.router.navigate(['fill', checklist.Id, 'group', checklist.categories[0].groups[0].Id]);
        })
    } else {
      await this.checklistDataRepo.updateVersionTag(checklist.Id, checklist.VersionTag);
      void this.router.navigate(['fill', checklist.Id]);
    }
  }

  fillDefaultValues(checklist: CheckChecklist) {
    if (checklist.items) {
      checklist.items.forEach(item => {
        if (!item.DefaultValue) {
          return;
        }
        if (item.ItemType === CheckItemType.Boolean) {
          const value = item.DefaultValue === 'true' ? true :
            item.DefaultValue === 'false' ? false :
              item.DefaultValue;

          this.checklistDataRepo.upsertItemValue(item, value);
        } else if (item.ItemType === CheckItemType.Currency) {
          this.checklistDataRepo.upsertItemValue(item, parseFloat(item.DefaultValue));
        } else {
          this.checklistDataRepo.upsertItemValue(item, item.DefaultValue);
        }
      })
    }
  }

  fillAdditionalValues(checklist: CheckChecklist, values: Map<string, string>) {
    if (checklist.items && values) {
      values.forEach((value, key) => {
        const item =
          checklist.items.find(x => x.ExternalId.toLowerCase() === key.toLowerCase() && x.Editable)

        if (item) {
          if (item.ItemType === CheckItemType.Boolean) {
            this.checklistDataRepo.upsertItemValue(item, value === 'true');
          } else {
            this.checklistDataRepo.upsertItemValue(item, value);
          }
        }
      });
    }
  }


  async selectLoginUser(): Promise<BlinkEmployee> {
    const employeeSearchModal = await this.modalController.create({
      component: EmployeeSearchComponent,
      id: 'LoginUserSearch',
      showBackdrop: true,
      backdropDismiss: false
    });

    void employeeSearchModal.present();
    const response = await employeeSearchModal.onWillDismiss();

    if (response.role === 'employeeSelected') {
      return response.data;
    }
    return null;
  }

  public previewChecklist(checklistId: number) {
    if (!this.networkService.connected) {
      this.uiDialogService.confirm({
        title: this.t.global.internetRequired,
        text: this.t.fill.offlinePreviewMessage,
        buttons: [uiCancelButton()]
      });
      return null;
    }
    return this.checklistDataApi.previewChecklist(checklistId);
  }

  async finish(checklistId, skipAuth = false, skipConfirm = false) {
    if (!this.networkService.connected) {
      this.uiDialogService.confirm({
        title: this.t.global.internetRequired,
        text: this.t.fill.offlineMessage,
        buttons: [uiCancelButton()]
      });
      return;
    }

    if (!skipAuth && !await this.isChecklistDataValid(checklistId)) {
      return;
    }

    void this.checklistDataApi.submitChecklist(checklistId, skipAuth, skipConfirm);
  }

  private async isChecklistDataValid(checklistId: number): Promise<boolean> {
    return await this.validateAndRemoveDeletedFiles(checklistId) && await this.isValidComparedToNewlyDownloadedChecklist(checklistId);
  }

  private async isValidComparedToNewlyDownloadedChecklist(checklistId: number) {
    return this.checklistApi.getFull(checklistId)
      .pipe(map(async checklist => {
        const checklistData = await this.checklistDataRepo
          .getFullChecklistData(checklistId)
          .pipe(first())
          .toPromise();
        if (checklistData.versionTag !== checklist.VersionTag) {
          this.uiDialogService.confirm({
            utilDataTestId: 'version-tag-has-changed-dialog',
            title: this.t.fill.checklistUpdatedHeader,
            text: this.t.fill.checklistUpdatedMessage,
            buttons: [uiCancelButton()]
          });
          this.checklistDataRepo.upsert({ ...checklistData, versionTag: checklist.VersionTag });

          this.checklistDataApi.syncDependencies(checklistId, checklistData);

          return false;
        }

        this.checklistDataApi.syncDependencies(checklistId, checklistData);

        return true;
      }))
      .toPromise();
  }

  private async validateAndRemoveDeletedFiles(checklistId: number): Promise<boolean> {
    if (Capacitor.getPlatform() === 'web') {
      return true;
    }

    const isValid = await firstValueFrom(this.checklistSubmitFacade.getChecklistDataForSubmit(checklistId).pipe(
      first(),
      map(async checklistData => {
        const fileUploadItems = checklistData.Items
          .filter(x => this.checklistDataApi.isFileUploadItem(x));
        let isValid = true;
        const missingFiles: string[] = [];
        for (const item of fileUploadItems) {
          const fileUploadInfos = this.getFileUploadInfos(item.ItemData);

          for (const fileUploadInfo of fileUploadInfos) {
            const isExisting = await this.fillItemFileUploadService.isFileExisting(fileUploadInfo.src)
            if (!isExisting) {
              missingFiles.push(fileUploadInfo.name);
              isValid = false;
            }
          }
        }
        if (!isValid) {
          this.uiDialogService.confirm({
            utilDataTestId: 'file-has-been-deleted-dialog',
            title: this.t.fill.fileUploadFileMissingHeader,
            text: this.t.fill.fileUploadFileMissingMessage,
            textOptions: { missingFiles: missingFiles.join(', ') },
            buttons: [uiCancelButton()]
          });
        }
        return isValid;
      })
    ));
    return isValid;
  }

  private getFileUploadInfos(fillValue: string): FileUploadInfo[] {
    return fillValue
      ? JSON.parse(fillValue.replace(HANDLE_FILE_UPLOAD, '')) as FileUploadInfo[]
      : [];
  }
}
