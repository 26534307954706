import { createStore } from '@ngneat/elf';
import { selectAllEntities, selectEntity, setEntities, withEntities } from '@ngneat/elf-entities';
import { BlinkLanguage } from '@blink/shared-blink-types';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

const store = createStore(
  { name: 'core_languages' },
  withEntities<BlinkLanguage, 'Id'>({idKey: 'Id'})
);

@Injectable({providedIn: 'root'})
export class BlinkLanguageRepository {
  languages$ = store.pipe(
    selectAllEntities(),
    filter(languages => !!languages)
  );

  language$(languageId: number) {
    return store.pipe(selectEntity(languageId));
  }

  setLanguages(languages: BlinkLanguage[]) {
    store.update(setEntities(languages));
  }
}
