import { createStore, select, setProp, setProps, withProps } from '@ngneat/elf';
import { BlinkFeatureInternalId, BlinkPermission, BlinkUserContext } from './blink-user-context';
import { Injectable } from '@angular/core';
import { withActiveId } from '@ngneat/elf-entities';
import { Observable, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { displayName } from '../display-name';
import { PersistStateService } from '../../shared';
import { BlinkApps, TimeFrames } from '@blink/shared-blink-types';
import { map } from 'rxjs/operators';
import { permissionCheck } from './permission-check';
import { ProfileRepository } from '../profile/profile.repository';

interface SessionRepositoryNotificationProps {
  pushEnabled?: boolean;
  appInstanceIdentifier?: string;
  fcmToken?: string,
  registeredOnServer: boolean
}

interface ManagerProps {
  timeFrameSettings: TimeFrames;
  planningTimeFrameSettings: TimeFrames;
  modernDurationFormat: boolean;
  language: string;
}

interface SessionRepositoryProps {
  userContext: BlinkUserContext;
  system: string;
  blinkId: string;
  refreshToken: string;
  authToken: string;
  notification: SessionRepositoryNotificationProps;
  migration: {
    activeId: number;
    migrated: boolean;
    ready: boolean;
  },
  manager: ManagerProps;
}

const store = createStore(
  { name: 'core_session' },
  withActiveId(),
  withProps<SessionRepositoryProps>({
    userContext: null,
    system: null,
    blinkId: null,
    authToken: null,
    refreshToken: null,
    notification: {
      pushEnabled: null,
      appInstanceIdentifier: null,
      fcmToken: null,
      registeredOnServer: false
    },
    migration: {
      activeId: null,
      migrated: false,
      ready: false
    },
    manager: {
      timeFrameSettings: TimeFrames.LastWeek,
      planningTimeFrameSettings: TimeFrames.CurrentWeek,
      modernDurationFormat: false,
      language: 'de'
    }
  })
);

@Injectable({ providedIn: 'root' })
export class SessionRepository {
  userContext$ = store.pipe(select(t => t.userContext));
  permissions$ = this.userContext$.pipe(map(x => x.Permissions));
  system$ = store.pipe(select(state => state.system));
  authToken$ = store.pipe(select(session => session.authToken));
  isLoggedIn$ = store.pipe(select(session =>
    session.authToken !== null));
  pushEnabled$ = store.pipe(select(session => session.notification.pushEnabled));
  showChecklists$: Observable<boolean> = this.userContext$
    .pipe(
      map(userContext => {
        return userContext?.BlinkApps.some(ba => ba.InternalId === BlinkApps.BlinkCheck || ba.InternalId === BlinkApps.BlinkCheckUnlimited);
      })
    );
  managerLanguage$ = store.pipe(select(session => session.manager.language));
  private sessionReadySubject = new ReplaySubject<boolean>(1);
  sessionReady$ = this.sessionReadySubject.asObservable();

  selectedLanguageId$ = this.userContext$.pipe(map(uc => uc.LanguageId || 1));

  constructor(private router: Router,
              persistStateService: PersistStateService,
              private profileRepository: ProfileRepository) {

    window['sessionStore'] = store;
    window['sessionRepository'] = this;

    persistStateService
      .persistState(store, false)
      .subscribe(async () => {
          const migration = store.getValue().migration;
          if (!migration.migrated) {
            store.update(setProps((state) => ({
              migration: {
                activeId: state.activeId,
                ready: true,
                migrated: false
              }
            })));
          }

          if (!this.managerTimeFrameSettings) {
            this.managerTimeFrameSettings = TimeFrames.LastWeek;
          }

          if (!this.managerPlanningTimeFrameSettings) {
            this.managerPlanningTimeFrameSettings = TimeFrames.CurrentWeek;
          }

          if (!this.managerPlanningTimeFrameSettings) {
            this.managerPlanningTimeFrameSettings = TimeFrames.CurrentWeek;
          }


          await this.startUp();
        }
      );
  }

  get getMigration(): { activeId: number; migrated: boolean; ready: boolean } {
    return store.getValue().migration;
  }

  get appInstanceIdentifier() {
    return store.getValue().notification.appInstanceIdentifier;
  }

  get hasFeatureSticker() {
    return store.getValue().userContext
      ?.Features
      ?.some(feature => feature.InternalId === BlinkFeatureInternalId.Sticker);
  }

  get hasFeatureELearning() {
    return this.getUserContext()
      ?.Features
      ?.some(feature => feature.InternalId === BlinkFeatureInternalId.ELearning
        || feature.InternalId === BlinkFeatureInternalId.Schulungstool);
  }

  get hasBlinkCheckStandardLicence() {
    return this.getUserHasLicenceFor(BlinkApps.BlinkCheck);
  }

  get hasActiveLicence() {
    return this.getUserHasLicenceFor(BlinkApps.BlinkActive);
  }

  get hasTimeLicence() {
    return this.getUserHasLicenceFor(BlinkApps.BlinkTime);
  }

  get hasBlinkCheckUnlimitedLicence() {
    return this.getUserHasLicenceFor(BlinkApps.BlinkCheckUnlimited);
  }

  get hasBlinkCheckLicence() {
    return this.hasBlinkCheckStandardLicence || this.hasBlinkCheckUnlimitedLicence || this.getUserHasLicenceFor(BlinkApps.BlinkCheckProfessional);
  }

  get hasBlinkOrderStandardLicence() {
    return this.getUserHasLicenceFor(BlinkApps.BlinkOrderStandard);
  }

  get hasBlinkOrderProfessionalLicence() {
    return this.getUserHasLicenceFor(BlinkApps.BlinkOrderProfessional);
  }

  get hasBlinkOrderEnterpriseLicence() {
    return this.getUserHasLicenceFor(BlinkApps.BlinkOrderEnterprise);
  }

  get hasBlinkOrderLicence() {
    return this.hasBlinkOrderStandardLicence
      || this.hasBlinkOrderProfessionalLicence
      || this.hasBlinkOrderEnterpriseLicence;
  }

  get hasBlinkWorkOrderLicence() {
    return this.getUserHasLicenceFor(BlinkApps.BlinkWorkOrder);
  }

  get pushEnabled() {
    return store.getValue().notification.pushEnabled;
  }

  get fcmToken() {
    return store.getValue().notification.fcmToken;
  }

  get registeredOnServer() {
    return store.getValue().notification.registeredOnServer;
  }

  set registeredOnServer(registeredOnServer: boolean) {
    store.update(
      setProps((state) => ({
        notification: {
          ...state.notification,
          registeredOnServer: registeredOnServer
        }
      }))
    );
  }

  get managerLanguage() {
    const language = store.getValue().manager.language;
    return language || 'de';
  }

  set managerLanguage(value: string) {
    store.update(
      setProps((state) => ({
        manager: {
          ...state.manager,
          language: value
        }
      }))
    );
  }

  get managerModernDurationFormat() {
    return store.getValue().manager.modernDurationFormat;
  }

  //
  // setRefreshTokenToken(refreshToken: string) {
  //   store.update(setProp('refreshToken', refreshToken));
  // }

  set managerModernDurationFormat(value: boolean) {
    store.update(
      setProps((state) => ({
        manager: {
          ...state.manager,
          modernDurationFormat: value
        }
      }))
    );
  }

  get language() {
    const language = store.getValue().userContext?.LanguageCode;
    return language || 'de';
  }

  get managerTimeFrameSettings() {
    return store.getValue().manager.timeFrameSettings;
  }

  set managerTimeFrameSettings(value: TimeFrames) {
    store.update(
      setProps((state) => ({
        manager: {
          ...state.manager,
          timeFrameSettings: value
        }
      }))
    );
  }

  get managerPlanningTimeFrameSettings() {
    return store.getValue().manager.planningTimeFrameSettings;
  }

  set managerPlanningTimeFrameSettings(value: TimeFrames) {
    store.update(
      setProps((state) => ({
        manager: {
          ...state.manager,
          planningTimeFrameSettings: value
        }
      }))
    );
  }

  async startUp() {
    this.router.initialNavigation();
    this.sessionReadySubject.next(true);
  }

  setSystem(system: string) {
    store.update(setProp('system', system));
  }

  getSystem() {
    return store.getValue().system;
  }

  setBlinkId(blinkId: string) {
    store.update(setProp('blinkId', blinkId));
  }

  setTokens(authToken: string, refreshToken: string, blinkId: string = null) {
    store.update(setProps({ authToken, refreshToken, blinkId }));
  }

  setAuthToken(authToken: string) {
    store.update(setProp('authToken', authToken));
  }

  getAuthToken() {
    return store.getValue().authToken;
  }

  getRefreshToken() {
    return store.getValue().refreshToken;
  }

  setUserContext(userContext: BlinkUserContext) {
    userContext.loginUserName = displayName(userContext.FirstName, userContext.LastName);
    store.update(setProp('userContext', userContext));


    this.profileRepository.upsert({
      userContext: userContext,
      refreshToken: this.getRefreshToken(),
      system: this.getSystem(),
      blinkId: store.getValue().blinkId
    })
  }

  getUserContext(): BlinkUserContext {
    const userContext = store.getValue().userContext;

    const isLoggedIn = store.getValue().authToken !== null;
    if (!userContext && isLoggedIn) {
      throw new Error('No User context');
    }
    return userContext;
  }

  isAndavisAdmin() {
    return store.getValue().userContext.LoginUserId === 1;
  }

  getUserHasLicenceFor(blinkApp: BlinkApps) {
    return store.getValue().userContext?.BlinkApps.some(app => app.InternalId === blinkApp);
  }

  userHasLicense$(licenseToCheck: BlinkApps): Observable<boolean> {
    return this.userContext$.pipe(
      map(userContext => userContext.BlinkApps),
      map(blinkApps => blinkApps.some(app => app.InternalId === licenseToCheck))
    )
  }

  userHasPermission$(permissionToCheck: BlinkPermission | BlinkPermission[]): Observable<boolean> {
    return this.userContext$.pipe(
      map(userContext => userContext.Permissions),
      map(permissions => permissionCheck(permissions, permissionToCheck))
    )
  }

  getUserHasPermissions(permission: BlinkPermission | BlinkPermission[]) {
    return permissionCheck(store.getValue().userContext?.Permissions, permission);
  }

  setPush(pushEnabled: boolean) {
    store.update(
      setProps((state) => ({
        notification: {
          ...state.notification,
          pushEnabled: pushEnabled
        }
      }))
    );
  }

  updateAppInstanceIdentifier(appInstanceIdentifier: string) {
    store.update(
      setProps((state) => ({
        notification: {
          ...state.notification,
          appInstanceIdentifier: appInstanceIdentifier
        }
      }))
    );
  }

  updateFcmToken(fcmToken: string) {
    store.update(
      setProps((state) => ({
        notification: {
          ...state.notification,
          fcmToken: fcmToken
        }
      }))
    );
  }

  setMigrationMigrated() {
    store.update(
      setProps((state) => ({
        migration: {
          ...state.migration,
          migrated: true
        }
      }))
    );
  }

  changeProfile(system: string, refreshToken: string, blinkId: string) {
    store.update(
      setProps((state) => ({
        ...state,
        authToken: null,
        refreshToken: refreshToken,
        system: system,
        blinkId
      }))
    );
  }
}
