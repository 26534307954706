export default {
  home: {
    pageTitle: 'Domovská stránka',
    welcome: 'Vítejte v aplikaci Blink me. Bavte se chatováním,\n\nVáš Blink tým',
    startChat: 'začít',
    ticket: 'lístek',
    timeLicenseMissing: 'Nebyla vám přidělena licence Blink Time!'
  },
  conversations: { pageTitle: 'Zprávy', yourChats: 'Vaše chaty', chat: 'Povídat si' },
  settings: {
    settings: 'Nastavení',
    pushEnabled: 'Přijímat oznámení push',
    system: 'Systém',
    languageSelection: 'výběr jazyka',
    currentSettings: 'Aktuální nastavení',
    user: 'uživatel',
    appVersion: 'Verze (aplikace)',
    apiVersion: 'Verze (API)',
    deviceInfo: 'Verze zařízení',
    buildVersion: 'verze (sestavení)',
    helpAndDataPolicy: 'Pomoc a ochrana dat',
    text1: 'Náš tým podpory vám rád pomůže, pokud budete mít nějaké dotazy:',
    phone: 'telefon',
    mail: 'e-mailem',
    information: 'info',
    documentation: 'Online dokumentace',
    documentationText: 'V naší online dokumentaci najdete návody a videa pro všechny důležité oblasti Blink.',
    dataPolicy: 'ochrana dat',
    support: 'Podpěra, podpora',
    moreActions: 'Další akce',
    protocol: 'protokol',
    showChatUserSecret: 'Sdílejte chat s jiným zařízením',
    scanCodeAnotherDevice: 'Naskenujte následující QR kód na svém novém zařízení nebo zadejte svůj osobní chatovací kód na novém zařízení!',
    yourPersonalCode: 'Váš osobní kód chatu',
    permissions: {
      title: 'Oprávnění',
      grantPermission: 'udělit povolení',
      camera: 'Fotoaparát',
      cameraHint: 'Nezbytné pro záznam času přes objektový kód.',
      nfc: 'NFC',
      nfcHint: 'Nezbytné pro záznam času přes NFC tag.',
      media: 'Vezměte obrázek a obrázek z knihovny',
      mediaHint: 'Nezbytné pro zachycení obrázků a dokumentů souvisejících s vašimi časy a vstupenkami.',
      location: 'Umístění',
      locationHint: 'Nutné zaznamenat vaši polohu v době záznamu času.',
      locationDisabledOnSystem: 'Lokalizační služby jsou zakázány. Aktivujte to prosím v nastavení vašeho zařízení.',
      notifications: 'Push notifikace',
      notificationsHint: 'Nezbytné pro příjem upozornění push na zprávy chatu, upozornění na mrknutí a nálepky.'
    }
  },
  connectionState: {
    connecting: 'Připojit...',
    connected: 'Svázané dohromady.',
    disconnecting: 'Připojení je odpojeno...',
    disconnected: 'spojení ztraceno',
    denied: 'Spojení se nepodařilo navázat.'
  },
  noOneToFind: 'Žádné k nalezení.',
  nothingToFind: 'Nic k nalezení.',
  noLocationFound: 'Nebyly nalezeny žádné objekty.',
  searchPlaceholder: 'Hledat...',
  employeeSearchPlaceholder: 'Jméno křestní Jméno',
  typeToSearch: 'Klepnutím vyhledáte...',
  typeToSearchEmployee: 'Chcete-li zahájit chat, zadejte jméno osoby',
  createChatWithEmployee: 'Hledáme zaměstnance',
  createChatWithLocationTeam: 'Vyhledat objekt',
  createTeamleaderChat: 'Všichni moji zaměstnanci',
  yourLanguageSelection: 'Váš výběr jazyka:',
  loginInfo: 'Pro registraci naskenujte svůj osobní QR kód:',
  updating: 'Blink me se aktualizuje.',
  notice: 'Oznámení',
  importantNotice: 'Důležitá poznámka',
  continue: 'Dále',
  intro: {
    slide1: { header: 'Vítejte', text: 'Bavte se s vaší aplikací Blink me.' },
    slide2: {
      header: 'Povídat si',
      text: 'Chatujte přímo se svými kolegy nebo zjistěte nejnovější zprávy ze zajímavých skupin.'
    },
    slide3: {
      header: 'Vstupenky',
      text: 'Zde můžete vidět své úkoly a vytvářet aplikace.'
    }
  },
  pleaseWait: 'Prosím, čekejte...',
  skip: 'Přeskočit',
  startAgain: 'Začít znovu',
  lock: {
    createPinHeader: 'Váš osobní PIN',
    createPinInfo: 'Pro ochranu vašich dat si zde můžete nastavit PIN, který musíte zadat při každém použití aplikace Blink me.',
    validatePin: 'Zadejte prosím PIN znovu.',
    tryAgain: 'PIN se neshoduje. Zkuste to znovu.',
    triedToOften: 'Váš PIN se bohužel neshoduje. Chcete proces restartovat nebo jej přeskočit a provést později?',
    enterPinHeader: 'Zadání PIN',
    enterPinInfo: 'Chcete-li aplikaci odemknout, zadejte svůj PIN.',
    remainingTrys: 'Další pokusy: {{amount}}. Poté se musíte znovu přihlásit do aplikace.',
    tooManyEnters: 'Registrace pomocí PIN již bohužel není možná. Přihlaste se znovu pomocí své Blink ID karty.',
    reSignIn: 'Nová registrace',
    setpin: 'Zadání PIN při používání aplikace',
    skip: 'Nechcete nyní přidělit PIN? Tuto funkci můžete kdykoli aktivovat prostřednictvím nastavení aplikace, a to i později.',
    dontUsePin: 'nepoužívejte PIN',
    deactivatePinHeader: 'Zakázat PIN',
    deactivatePinInfo: 'Pro deaktivaci ochrany prosím zadejte svůj PIN.'
  },
  active: {
    yourTickets: 'Vaše vstupenky',
    createNewTask: 'Vytvořte nový tiket',
    assignee: 'editor',
    location: 'objekt',
    dueDate: 'Splatné',
    createTask: 'Vytvořit lístek',
    taskCreated: 'Lístek byl vytvořen.',
    backToTasks: 'Zpět k lístkům',
    createdAt: 'vytvořeno dne',
    attachments: 'Přílohy',
    addFileOrImage: 'Přidejte soubor nebo fotografii',
    addImage: 'přidat fotku',
    comments: 'Komentáře',
    addComment: 'přidat komentář',
    addCommentInfo: 'Komentář je viditelný pro každého, kdo může zobrazit tento tiket.',
    commentCreated: 'Komentář byl uložen.',
    showClosed: 'Prohlédněte si hotové vstupenky',
    resetFilter: 'resetujte všechny filtry',
    changeTicketStatusTo: 'Změnit stav vstupenky na:',
    editTask: 'Upravit lístek',
    saveTask: 'Uložit lístek',
    taskSaved: 'Lístek byl uložen.',
    deleteImage: 'Jasný obraz',
    deleteImageConfirmation: 'Opravdu chcete obrázek smazat?',
    deleteAttachment: 'Smazat přílohu',
    deleteAttachmentConfirmation: 'Opravdu chcete smazat tuto přílohu?',
    deleteComment: 'Smazat komentář',
    deleteCommentConfirmation: 'Opravdu chcete smazat komentář?',
    changeAssignee: 'Změnit editor',
    duplicateTask: 'Duplicitní lístek',
    duplicateTaskConfirmation: 'Chcete duplikovat tento tiket?',
    taskDuplicated: 'Lístek byl duplikován.',
    deleteTask: 'Smazat lístek',
    deleteTaskConfirmation: 'Opravdu chcete smazat tento tiket?',
    taskDeleted: 'Lístek byl smazán.',
    assigneeChanged: 'Procesor byl změněn.',
    statusChanged: 'Stav tiketu byl změněn.',
    startDate: 'začít',
    endDate: 'Konec',
    doctorVisitDate: 'Datum návštěvy lékaře',
    doctorVisitDescription: 'Datum návštěvy lékaře: {{date}}',
    chooseDate: 'Vyberte datum',
    comment: 'Komentář (volitelné)',
    dateError: 'Datum ukončení nemůže být před datem zahájení',
    employee: 'Zaměstnanci',
    pleaseConnectToLoad: 'Chcete-li načíst vstupenky, vytvořte si internetové připojení.'
  },
  emptyState: {
    chats: 'Vytvořte chat!',
    chat: 'Napište první zprávu :)',
    tasks: 'Nebyly nalezeny žádné vstupenky.',
    attachments: 'Nejsou k dispozici žádné přílohy.',
    comments: 'Nejsou k dispozici žádné komentáře.',
    vacation: 'Pro letošní rok nebyla zadána žádná dovolená'
  },
  formError: {
    min: 'Hodnota musí být větší než {{value}}.',
    min2: 'Hodnota musí být větší nebo rovna {{value}}.',
    max: 'Hodnota musí být menší nebo rovna {{value}}.',
    requiredBecause: 'Hodnota je povinná, protože {{name}} je vyplněno.',
    isRequired: '{{name}} je povinné.',
    numberInUse: 'Osobní číslo se již používá.',
    maxlength: '{{name}} nemůže obsahovat více než {{value}} znaků.',
    faultyBlinkId: 'Nesprávné ID blikání'
  },
  ngSelect: {
    typeToSeach: 'Zadejte prosím 3 nebo více písmen.',
    loading: 'načítání dat...',
    notFound: 'Nebylo nalezeno žádné {{title}}.',
    clearAll: 'Obnovit do základního nastavení'
  },
  placeholder: {
    employee: 'Vyhledávání podle jména a příjmení, osobního čísla, Blink ID',
    location: 'Vyhledávání podle názvu, čísla nebo kódů objektů',
    date: 'Vyberte datum',
    searchTasks: 'Hledat vstupenky...'
  },
  hasChat: 'Začněte chatovat',
  hasNoChatYet: 'Bohužel jsem ještě nenainstaloval aplikaci Blink me.',
  noBlinkActiveLicence: 'Žádná licence',
  noBlinkActiveLicenceInfo: 'Momentálně nemáte licenci pro tuto aplikaci. Kontaktujte prosím svého nadřízeného.',
  noBlinkMeLicence: 'Žádná licence',
  noBlinkMeLicenceInfo: 'Tuto aplikaci nelze použít s vaším systémem. Použijte dobu blikání z aplikace nebo obchodu Play.',
  noSuitableAppFound: 'Nebyla nalezena žádná aplikace pro otevření souboru.',
  myChats: 'Vaše chaty',
  hasNoChatsHome: 'Začněte chatovat hned teď! Můžete mluvit se všemi kolegy a účastnit se objektových a týmových skupin.',
  hasNoTicketsHome: 'Nejsou k dispozici žádné otevřené vstupenky.',
  myTickets: 'Vaše vstupenky',
  myEmployees: 'Moji zaměstnanci',
  group: 'skupina',
  confirmLogoutWithChat: 'Pokud se odhlásíte, vaše chatové zprávy budou smazány. Chcete pokračovat?',
  confirmLogout: 'Opravdu se chcete odhlásit?',
  message: 'Zprávy',
  messageNotDecryptable: 'Zprávu se nepodařilo dešifrovat.',
  chat: {
    userAlreadyExists: 'Chat jste již nastavili na jiném zařízení. Abyste na tomto zařízení mohli používat chat, musíte zařízení propojit. Případně můžete restartovat nastavení, ale pak již nelze chat používat na druhém zařízení.',
    linkDevices: 'Propojte zařízení',
    withoutChat: 'Pokračujte bez chatu',
    setupAgain: 'Resetovat chat',
    setupAgainText: 'Pokud chat znovu nastavíte, všechny zprávy ze stávajících jednotlivých chatů budou smazány a chat již nebude možné používat na ostatních zařízeních! Chcete pokračovat?',
    LinkDevicesText: 'Chcete-li propojit zařízení pro chat, otevřete Nastavení na druhém zařízení a vyberte „Sdílet chat s jiným zařízením“.',
    personalToken: 'Váš osobní kód chatu',
    wrongCode: 'Zadaný kód chatu je nesprávný.',
    beingPrepared: 'Chat se připravuje.',
    twilioAccessDenied: 'Připojení ke službě chatu se nepodařilo navázat. Kontaktujte prosím podporu Blink.',
    notificationsDenied: 'Oznámení jsou ve vašem zařízení/prohlížeči zakázána. Přejděte do Nastavení a povolte upozornění pro Blink me, abyste mohli přijímat upozornění. Oznámení si pak můžete aktivovat ve svém osobním nastavení.',
    deleted: 'Chat byl smazán',
    unavailable: 'Chat není dostupný',
    participant: 'Účastník',
    teamChatDescription: 'Do této chatovací skupiny jsou přidáni všichni zaměstnanci, pro které jste registrováni jako vedoucí.',
    locationChatDescription: 'Zaměstnanci jsou automaticky přidáni, pokud mají plán pro tento objekt na poslední čtyři týdny nebo na další čtyři týdny.',
    createEmployeeChatDescription: 'S vybraným zaměstnancem bude vytvořen soukromý chat.',
    teamChatCreationTitle: 'Vytvořit chatovací skupinu',
    createTeamChatDescription: 'Vytvoří se chatová skupina, do které jsou automaticky přidáni všichni zaměstnanci, u kterých jste registrováni jako vedoucí.',
    createLocationChatDescription: 'Pro vybraný objekt se vytvoří chatovací skupina, kam se automaticky přidají zaměstnanci, pokud na to mají plán na poslední čtyři týdny nebo na další čtyři týdny.'
  },
  protocol: {
    SEND_EMAIL: 'Odeslat emailem',
    DELETE: 'Vymazat protokol',
    deleteMessage: 'Opravdu chcete smazat protokol? Tuto akci nelze vrátit zpět.',
    Send: 'Poslat',
    SuccessMessage: 'Protokol byl úspěšně odeslán podpoře Blink.',
    ConfirmMessage: 'Nejprve kontaktujte tým podpory a odešlete protokol pouze na vyžádání.',
    Description: 'popis problému'
  },
  timeTracking: {
    title: 'Sledování času',
    start: 'Start',
    startDescription: 'Kliknutím na start spustíte sledování času',
    started: 'Zahájeno',
    stop: 'Zastavit',
    duration: 'Délka času',
    syncButtonLabel: 'Časy nahrávání zaznamenané offline',
    offlineSyncMessage: 'Chcete-li přenést uložené časy, musíte být online.',
    successfulSync: 'Časy jsou nahrány',
    scanNowQrCode: 'QR kód',
    scanNowQrCodeDescription: 'Naskenujte QR kód v objektu.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Přidržte svůj smartphone proti nálepce NFC v objektu.',
    nfcDescription: 'Detekce přes NFC: Přidržte svůj mobilní telefon proti kódu objektu',
    noTimeTrackingMethodAvailableDescription: 'Pro tohoto uživatele není nakonfigurována žádná metoda sledování času. Kontaktujte svého vedoucího týmu.',
    noLocationCode: 'Není k dispozici žádný kód',
    noLocationCodeDescription: 'Tuto možnost vyberte, pokud v objektu není žádný QR kód ani nálepka NFC. Uvedení komentáře je povinné.',
    noLocationCodeConfirm: 'Opravdu chcete začít bez objektového kódu? Chcete-li zastavit, musíte zadat komentář.',
    wrongCode: 'Naskenovaný QR kód není objektový kód!',
    readNotice: 'Přečtěte si prosím pokyny!',
    warning: 'Varování',
    lessThanAMinuteTitle: 'Kompletní záznam času',
    lessThanAMinute: 'Začali jste před méně než 1 minutou. Opravdu chcete přestat?',
    continue: 'Pokračujte v nahrávání',
    timeAlert: {
      differenceInMinutesHint: 'Čas na vašem smartphonu se liší od času na serveru. Rozdíl je zaznamenán',
      differentTimeZoneHint: 'Časové pásmo vašeho smartphonu se liší od časového pásma serveru. Rozdíl je zaznamenán.'
    },
    leaveComment: {
      withCode: 'Informace o objektu/službě nebyly jasné. Sdělte nám prosím, ve které nemovitosti/službě jste pracovali.',
      withoutCode: 'Při spuštění nebo zastavení nebyl zachycen žádný kód objektu. Vysvětlete prosím, proč jste začali/zastavili bez předmětu.'
    },
    comment: 'komentář',
    enterComment: 'Komentář ke sledování času',
    differentQrCode: {
      title: 'Změnit objekt?',
      continueInLocation: 'Pokračujte v práci v {{locationName}}.',
      automaticStopInLocation: 'Záznam času v {{locationName}} se automaticky zastaví.',
      stopInLocation: 'Zastavit záznam času v {{locationName}}.',
      stop: 'Zastavit',
      stopNoLocation: 'Zastavení záznamu času.',
      continueNoOldLocation: 'Záznam času pro předchozí objekt se automaticky zastaví.',
      continueWorking: 'Pokračuj v práci',
      continueWorkingInNewLocation: 'Pokračujte v práci v nové nemovitosti.',
      stopWithLocationActivity: 'Zastavit záznam času na {{locationActivity}}.',
      continueWithNewLocationActivity: 'Pokračovat v práci s {{locationActivity}}',
      stopOldLocationActivity: 'Záznam času na {{locationActivity}} se automaticky zastaví.',
      stopOldNoLocationActivity: 'Záznam času bez výkonu se automaticky zastaví.',
      continueNoLocationActivity: 'Pokračujte v práci bez výkonu.',
      sameLocationTitle: 'Změnit výkon?',
      differentLocationTitle: 'Změnit objekt?'
    },
    saveAndStop: 'Uložit a zastavit',
    noLocationScanned: '- Žádný předmět -',
    noLocationFound: '- Objekt není jasný -',
    timerDifference: '{{h}} hodin {{mm}} minut',
    stopSuccess: 'Čas byl úspěšně zaznamenán',
    stopSuccessToast: 'Záznam času se úspěšně zastavil.',
    stopTimeTracking: 'Zastavit',
    manual: {
      time: 'Čas',
      locationSelect: 'Vyberte objekt',
      loadMore: 'načíst další',
      trackTime: 'Rekordní čas',
      location: 'objekt',
      locationActivity: 'Výkon',
      date: 'datum',
      startTime: 'Z',
      endTime: 'Až do',
      create: 'Potvrdit',
      createAgain: 'Zaznamenejte další čas pro tento objekt',
      errorUntil: 'Čas od času musí být menší než čas od času',
      worktimeDifference: 'Pracovní doba:',
      worktimeDifferenceValue: '{{hours}} hodin {{minutes}} minut',
      worktimeDifferenceValueMinutes: '{{minutes}} minut',
      success: 'Čas byl úspěšně zaznamenán',
      break: 'Přestávka:',
      breakMinutes: '{{minutes}} minut',
      breakHoursAndMinutes: '{{hours}} hodin {{minutes}} minut',
      noActivity: '-žádný výkon-',
      locationPlaceHolder: 'Vyhledávání podle názvu, čísla nebo kódů objektů',
      conflictError: 'V určených obdobích již probíhá záznam času. Přesahy nejsou povoleny. Zkontrolujte prosím své údaje.',
      currentDayDuration: 'Již zaznamenáno',
      expectedDuration: 'Předpokládaná pracovní doba',
      expectedDurationIncludedCurrent: 'včetně aktuálního vstupu'
    },
    differenceInMinutesHint: 'Čas na vašem smartphonu se liší od času na serveru. Rozdíl je zaznamenán',
    differentTimeZoneHint: 'Časové pásmo vašeho smartphonu se liší od časového pásma serveru. Rozdíl je zaznamenán.',
    codeNotAssignable: 'Naskenovaný QR kód nelze přiřadit!',
    proposed: {
      capture: 'Zachyťte',
      time: 'Čas',
      captureTime: 'Rekordní čas',
      button: 'Potvrdit',
      title: 'Potvrďte pracovní dobu',
      includingBreak: '(včetně přestávky)',
      from: 'Z',
      to: 'Až do',
      break: 'Přestávka',
      hour: 'Hodiny',
      confirm: {
        description: 'Potvrďte prosím svou pracovní dobu',
        confirm: 'Ano to je správně',
        editButton: 'Ne, to není správné'
      },
      edit: {
        description: 'Upřesněte dobu trvání',
        breakIncluded: 'Včetně přestávky {{ duration }}',
        comment: 'komentář',
        yourComment: 'Tvůj komentář',
        minutes: 'minut',
        hours: 'hodiny'
      },
      reasons: {
        overtime: 'Přesčasy kvůli zákazníkovi',
        faster: 'Hotovo rychleji',
        other: 'Smíšený',
        representative: 'Byl jsem náhradník'
      },
      description: 'Jakmile dokončíte práci v objektu, potvrďte svou pracovní dobu.'
    },
    running: 'Záznam času běží'
  },
  timeSheet: {
    title: 'Časy a plánování',
    noPlannings: 'Žádné plánování',
    planned: 'Plánováno',
    workingTime: 'pracovní doba',
    workloadDuration: 'Týdenní pracovní doba',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'By měl',
    workedDuration: 'Je',
    hasWorkload: 'Pracujte ten den',
    pleaseConnectToLoad: 'Pro načtení časů vytvořte připojení k internetu.',
    footer: {
      planned: 'Plánováno',
      worklogsTotal: 'Celkový počet hodin',
      workloadTotal: 'Hodiny dle smlouvy',
      difference: 'rozdíl'
    },
    homepageTitle: 'Váš pracovní den'
  },
  planning: {
    modifyMinutes: 'paušální sazba:',
    planRoute: 'Naplánovat trasu',
    title: 'plánování',
    includingPause: 'Přestávka:',
    noNavAppFound: 'Nebyla nalezena žádná navigační aplikace'
  },
  worklog: {
    title: 'Zaznamenaný čas',
    absenceTitle: 'absence',
    workingTime: 'pracovní doba',
    breakDuration: 'Přestávka',
    reduce: 'paušální sazbu',
    attachments: 'Přílohy',
    comments: 'Komentáře',
    comment: 'komentář',
    noLocation: 'Nebyl zadán žádný objekt',
    changedBy: 'Čas se změnil z {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'Opravdu chcete smazat tuto přílohu?',
    attachmentDeleted: 'Příloha byla smazána',
    confirmCommentDelete: 'Opravdu chcete tento komentář smazat?',
    commentDeleted: 'Komentář byl smazán'
  },
  tabs: {
    home: 'Domov',
    times: 'časy',
    chat: 'Povídat si',
    tickets: 'Vstupenky',
    more: 'více'
  },
  profile: {
    title: 'profil',
    testing: 'Testování',
    testingDescription: 'Tato oblast je viditelná pouze při připojení k testovacímu systému.',
    testingAllowQrCodeOnWeb: 'Povolit ruční zadání QR kódu',
    pushNotifications: 'Push notifikace',
    reloadUserContext: 'Znovu načtěte UserContext',
    pushNotificationSuccessful: 'Oznámení push bylo úspěšně přijato',
    editProfilePicture: 'změnit profilový obrázek',
    editProfilePictureTitle: 'Vyberte sekci',
    pushNotificationNotice: 'Pro test je vyžadována autorizace „Upravit nastavení klienta“.',
    send: 'Poslat',
    profilePicture: 'Profilový obrázek'
  },
  help: { title: 'Pomoc' },
  myVacations: {
    vacation: 'Dovolená',
    amountAvailableVacationDays: '{{amount}} dní dovolené je stále k dispozici',
    totalVacationDays: 'Nárok na dovolenou {{ total }} dní',
    remainingVacationDays: '({{ total }} dnů {{ sign }} {{ remaining }} dnů zbývajících prázdnin {{ previousYear }})',
    xDaysVacations: '{{ total }} dní dovolené',
    title: 'Vaše dovolená'
  },
  externalLinks: {
    title: 'externí odkazy',
    notice: 'Tento odkaz poskytla vaše společnost. Opouštíte systém Blink.'
  },
  stickers: {
    title: 'Vaše nálepky',
    sticker: 'Samolepky',
    sender: 'Příjemce',
    text: 'Ohledně',
    date: 'datum',
    emptyState: 'Bohužel ještě nemáte žádné nálepky',
    senderDescription: 'Tuto nálepku vám zaslal {{sender}} dne {{date}}',
    readed: 'číst',
    notReaded: 'ještě nečetl',
    newStickerToaster: 'Nová Blink Sticker!',
    open: 'OTEVŘENO',
    next: 'Dále',
    titleCases: {
      case1: 'Gratulujeme k vaší 1. nálepce!',
      case2: 'Skvělé, nyní již máte <b>{{count}}</b> nálepky!',
      case3: 'Gratulujeme k 10. nálepce!',
      case4: '30 samolepek - wow!',
      case5: 'Teď to tu začíná být přeplněné: <b>{{count}}</b> samolepky!'
    },
    confirmDelete: 'Opravdu chcete tyto nálepky smazat?'
  },
  autoTranslate: {
    isTranslated: 'Automaticky přeloženo',
    translationError: 'Automatický překlad momentálně není možný'
  },
  migration: {
    wrongVersion: 'V aktuální verzi Blink me není podporováno přihlášení přes aplikaci Blink Time. Aktualizujte prosím aplikaci Blink me na nejnovější verzi'
  },
  eLearning: {
    pendingTab: 'Úkoly ({{value}})',
    completedTab: 'Hotovo ({{value}})',
    completed: 'Dokončeno',
    pending: 'OTEVŘENO',
    dueFalling: 'Zpožděný',
    title: 'E-learning',
    course: 'chod',
    dueDate: 'Platný do',
    topics: 'předměty',
    completeDate: 'Hotovo',
    status: { Pending: 'OTEVŘENO', Done: 'dokončeno' },
    infos: {
      pendingExists: 'Všechny vaše otevřené vzdělávací kurzy naleznete zde.',
      finishOverdue: 'Udělejte prosím školení, které se již chystá, co nejdříve.',
      allCompleted: 'Vynikající! Úspěšně jste dokončili všechny své vzdělávací kurzy.',
      emptyState: 'Nemáte přidělené školení.'
    }
  },
  checklists: {
    title: 'formuláře',
    description: 'Vyplňte formulář pro objekt',
    button: 'Vyberte objekt'
  },
  pendingTab: 'Provedení ({{value}})',
  completedTab: 'Dokončeno ({{value}})',
  workOrders: {
    title: 'Objednávky',
    homepageTitle: 'Vaše objednávky',
    responsible: 'Odpovědný',
    customerContact: 'Kontakt na zákazníka',
    trackedTime: 'Záznam času pro objednávku č. {{value}}',
    show: 'Zobrazit objednávku',
    trackInfo: {
      confirmAndAcknowledge: 'Po implementaci potvrďte pozice a proveďte akceptační test na místě.',
      acknowledgeOnly: 'Proveďte prosím kontrolu na místě.',
      confirmOnly: 'Po realizaci prosím potvrďte pozice.'
    },
    pleaseConnectToLoad: 'Pro načtení vašich objednávek vytvořte internetové připojení.',
    status: { new: 'Nový', inProgress: 'Provedení', completed: 'Dokončeno' },
    emptyState: 'Nejsou k dispozici žádné otevřené objednávky.',
    emptyStateCompleted: 'Nejsou k dispozici žádné dokončené objednávky.',
    details: {
      title: 'Podrobnosti objednávky',
      positionsTab: 'Pozice ({{value}})',
      acknowledgementsTab: 'poklesy ({{value}})'
    },
    acknowledgement: {
      title: 'přijetí',
      start: 'Začněte přijímat',
      acknowledgedAtDate: 'Odebráno dne',
      itemSelectDescription: 'Vyberte všechny pozice, které chcete odstranit.',
      customerInformationDescription: 'Uveďte prosím kontaktní údaje zákazníka (kupujícího). Po přijetí vám bude na tento email zasláno potvrzení.',
      created: 'Přijetí bylo úspěšně uloženo',
      selectAll: 'Vyberte vše',
      emptyState: 'Nejsou žádné akceptace.'
    },
    items: {
      items: 'pozice',
      acknowledge: 'Potvrďte polohu',
      confirmAcknowledge: 'Tímto potvrzujete, že práce byla provedena. Stav pozice se aktualizuje na „Dokončeno“.',
      status: {
        open: 'OTEVŘENO',
        planned: 'Plánováno',
        performed: 'Hotovo',
        acknowledged: 'Odebráno',
        billed: 'Účtováno'
      },
      emptyState: 'Nejsou zde žádné pozice.'
    },
    trackTime: {
      description: 'Vyberte úlohu, u které chcete sledovat čas.',
      chooseWorkOrder: 'Vyberte pořadí',
      choosePosition: 'Vyberte pozici'
    }
  }
};
