import { Component, Input} from '@angular/core';
import { UiTranslateDisplay } from './ui-translate-display';
import { UiTranslateDisplayWrapperDirective } from './ui-translate-display-wrapper.directive';
import { UiTranslateDisplayService } from './ui-translate-display.service';

@Component({
  selector: 'ui-translate-display',
  templateUrl: 'ui-translate-display.component.html'
})
export class UiTranslateDisplayComponent extends UiTranslateDisplay {
  @Input() override defaultText: string;
  @Input() override translations: any[];
  @Input() override property: string;
  @Input() override currentLanguageId = 1;
  @Input() mainClass: string;
  @Input() subClass: string;
  @Input() mainTextExtra: string;

  constructor(public translateDisplayService: UiTranslateDisplayService) {
    super();
  }

}

