import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiManager } from './api-manager';

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {

  constructor(private apiManager: ApiManager) {
  }

  getVersionInfo(fileName: string) {
    return firstValueFrom(this.apiManager.http.get(`https://assets.blink.online/appVersions/${fileName}.json`));
  }

}
