import { Component } from '@angular/core';
import { ApplicationInsightsService, GlobalTranslateService, MeLanguages } from '@blink/util';
import { PopoverController } from '@ionic/angular';
import { BlinkLanguage } from '@blink/shared-blink-types';

@Component({
  selector: 'ui-language-select-popover',
  template: `
    <ion-row>
      <ion-col *ngFor="let language of languages" size="6"
               class="language-select-col"
               [class.selected-language]="language.Id === selectedLanguageId"
               (click)="onLanguageClick(language)">

        <img src="assets/imgs/language-flags/flag_{{language.Code}}.png" alt="{{language.Name}} flag" height="20"
             class="language-select-image mr-5"/>

        {{ t.languages[language.Code] | translate | firstUppercase }}
      </ion-col>
    </ion-row>
  `,
  styleUrls: ['ui-language-select-popover.component.scss']
})
export class UiLanguageSelectPopoverComponent {
  languages: BlinkLanguage[];
  selectedLanguageId: number;

  // get selectedLanguage() {
  //   return this.languages.find(l => l.Id === this.selectedLanguageId);
  // }

  constructor(private popoverController: PopoverController,
              public t: GlobalTranslateService,
              private appInsights: ApplicationInsightsService) {
    this.appInsights.registerPage(this, 'BLanguageSelectComponent', 'Popover')
  }

  onLanguageClick(language: BlinkLanguage) {
    // if (!language.disabled) {
    //   this.selectedLanguageId = code.code;
    //   this.appInsights.logEvent(`[BLanguageSelectComponent] language selected`, { languageCode: code })
    //   this.popoverController.dismiss(code.code, 'languageChosen');
    // }
    this.selectedLanguageId = language.Id;
    this.appInsights.logEvent(`[BLanguageSelectComponent] language selected`, { languageCode: language.Code })
    this.popoverController.dismiss(language, 'languageChosen');
  }

  protected readonly MeLanguages = MeLanguages;
}
