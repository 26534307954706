import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BlinkIcon, InfoType } from '@blink/ui';
import { GlobalTranslateService, logComponent } from '@blink/util';
import { debounceTime, Observable, Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-translate-reset-translations-info',
  template: `
<!--    <div id="reset-translation-toast-anchor"-->
<!--         [class.offset-height]="toastVisible">-->
<!--    </div>-->
    <ui-info *ngIf="changedProperties.length || visible"
             [translate]="t.translatedTextChanged"
             type="Warning">
      <div>
        <ion-button color="light"
                    (click)="onResetTranslations()">
          <ui-icon [icon]="BlinkIcon.Sparkles"
                   class="mr-5">
          </ui-icon>
          {{ t.translateAutomatically | translate }}
        </ion-button>
      </div>
    </ui-info>
  `,
  styles: `
    :host {
      display: block;
      margin: 0 16px;
    }
    .offset-height {
      height: 132px;
    }
  `
})
export class UiTranslateResetTranslationsInfoComponent implements OnDestroy {
  protected readonly BlinkIcon = BlinkIcon;

  subscriptions = new Subscription();

  visible = false;

  changedProperties: string[] = [];

  _properties: string[];
  @Input() set properties(properties: string[]) {
    this._properties = properties;
    this.createValueChangeSubscriptions();
  }

  _form: FormGroup;

  @Input() set formGroup(form: FormGroup) {
    this._form = form;
    this.createValueChangeSubscriptions();
  }

  @Output() resetTranslations = new EventEmitter<string[]>();

  private toast: HTMLIonToastElement;

  get toastVisible() {
    return !!this.toast;
  }

  constructor(public t: GlobalTranslateService,
              private translate: TranslateService,
              private toastController: ToastController) {
    logComponent(this);
  }

  ngOnDestroy() {
    if (this.toast) {
      this.toast.dismiss();
      this.toast = undefined;
    }
  }

  createValueChangeSubscriptions() {
    if (this._form && this._properties) {
      this.subscriptions.unsubscribe();
      this.subscriptions = new Subscription();
      this._properties.forEach(property => {
        this.subscriptions.add(
          this._form.get(property).valueChanges
            .pipe(debounceTime(500))
            .subscribe(change => {
              console.log('prop changed', { property, change })
              if (!this.changedProperties.includes(property)) {
                this.changedProperties.push(property)
              }
              this.showToast();
            })
        );
      })
    }
  }

  async showToast() {
    this.visible = true;

    // if (!this.toast) {
    //   this.toast = await this.toastController.create({
    //     positionAnchor: 'reset-translation-toast-anchor',
    //     message: this.translate.instant(this.t.translatedTextChanged),
    //     cssClass: 'reset-translation-toast',
    //     duration: 0,
    //     layout: 'stacked',
    //     position: 'top',
    //     buttons: [{
    //       text: this.translate.instant(this.t.translateAutomatically),
    //       role: 'translateAutomatically'
    //     }]
    //   });
    //   this.toast.onDidDismiss().then((response) => {
    //     if (response.role === 'translateAutomatically') {
    //       this.onResetTranslations();
    //     }
    //     this.toast = undefined;
    //   })
    //   console.log('showing toast')
    //   this.toast.present();
    // }
  }

  onResetTranslations() {
    this.resetTranslations.emit(this.changedProperties);
    this.changedProperties = [];
    this.visible = false;
  }

  protected readonly InfoType = InfoType;
}
