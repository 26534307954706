import { Pipe, PipeTransform } from '@angular/core';

interface NameType {
  FirstName: string;
  LastName: string
}

export const formatName = <T extends NameType>(obj: T) => {
  const firstName = obj.FirstName?.trim();
  const lastName = obj.LastName?.trim();

  const nameArray = [];
  if (lastName?.length > 0) {
    nameArray.push(lastName);
  }
  if (firstName?.length > 0) {
    nameArray.push(firstName.trim());
  }
  return nameArray.join(', ');
}

@Pipe({
  name: 'formatName'
})
export class BlinkFormatNamePipe implements PipeTransform {
  transform<T extends NameType>(obj: T | T[]): string {
    if(!obj) return '';
    if(Array.isArray(obj)){
      return  obj.map(x => formatName(x)).join(' | ')
    } else {
      return formatName(obj);
    }
  }
}
