import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlinkIcon } from '@blink/ui';
import { PopoverController } from '@ionic/angular';
import { UiLanguageSelectPopoverComponent } from './ui-language-select-popover.component';
import { BlinkLanguage } from '@blink/shared-blink-types';

@Component({
  selector: 'ui-language-select',
  template: `
    <ion-button *ngIf="languages && languages.length > 0"
                color="light"
                (click)="chooseLanguage($event)"
                [disabled]="disabled">
      <img src="assets/imgs/language-flags/flag_{{language?.Code}}.png" alt="german flag" height="20"
           style="margin-right: 8px;"/>
      <ui-icon style="font-size: 15px;" [icon]="BlinkIcon.CaretDown"></ui-icon>
    </ion-button>
  `
})
export class UiLanguageSelectComponent {
  protected readonly BlinkIcon = BlinkIcon;
  @Input() languages: BlinkLanguage[];
  @Input() selectedLanguageId: number;
  @Input() disabled = false;
  @Output() languageSelected = new EventEmitter<BlinkLanguage>();

  get language() {
    return this.languages?.find(l => l.Id === this.selectedLanguageId)
  }

  constructor(private popoverController: PopoverController) {
  }

  async chooseLanguage(ev) {
    const langPopover = await this.popoverController.create({
      component: UiLanguageSelectPopoverComponent,
      componentProps: {
        languages: this.languages,
        selectedLanguageId: this.selectedLanguageId
      },
      event: ev,
      cssClass: 'language-select-popover'
    });
    langPopover.onDidDismiss().then(result => {
      if (result.role === 'languageChosen') {
        this.languageSelected.emit(result.data);
      }
    });
    await langPopover.present();
  }
}
