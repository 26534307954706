import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiManager, BlinkService } from '../shared';
import { map } from 'rxjs/operators';
import { SessionRepository } from '../core';

export interface DeeplRequest {
  Texts: string[];
  TranslateFrom?: string;
  TranslateTo: string[]
}

interface DeeplResponse {
  value: {
    OriginalText: string;
    Translations: DeeplResponseTranslation[];
  }[];
}

interface DeeplResponseTranslation {
  TranslatedTo: string;
  Text: string;
  IsValidTranslation: boolean;
}

@Injectable({ providedIn: 'root' })
export class BlinkTranslateApi {
  private api = this.apiManager
    .createApiForEndpoint<DeeplResponse>(
      'odata/v1',
      'Translate',
      BlinkService.Ai
    )

  constructor(private apiManager: ApiManager,
              private sessionRepository: SessionRepository) {
    window['translateApi'] = this;
  }

  translate(text: string, languageCode = this.sessionRepository.language): Observable<DeeplResponseTranslation> {
    const request: DeeplRequest = {
      Texts: [text],
      // TranslateFrom: 'de',
      TranslateTo: [languageCode]
    }
    return this.api.post({ body: request }).pipe(
      map(response => response.value[0].Translations[0])
    );
  }

  multiTranslate(request: DeeplRequest) {
    return this.api.post({ body: request }).pipe(
      map(response => response.value)
    );
  }

  testTranslations() {
    const languages = [
      {
        name: 'Arabic',
        code: 'ar'
      },
      {
        name: 'Bulgarian',
        code: 'bg'
      },
      {
        name: 'German',
        code: 'de'
      },
      {
        name: 'English',
        code: 'en'
      },
      {
        name: 'Spanish',
        code: 'es'
      },
      {
        name: 'Persian',
        code: 'fa'
      },
      {
        name: 'French',
        code: 'fr'
      },
      {
        name: 'Greek',
        code: 'el'
      },
      {
        name: 'Croatian',
        code: 'hr'
      },
      {
        name: 'Hungarian',
        code: 'hu'
      },
      {
        name: 'Italian',
        code: 'it'
      },
      {
        name: 'Polish',
        code: 'pl'
      },
      {
        name: 'Portuguese',
        code: 'pt'
      },
      {
        name: 'Romanian',
        code: 'ro'
      },
      {
        name: 'Russian',
        code: 'ru'
      },
      {
        name: 'Turkish',
        code: 'tr'
      }
    ];
    languages.forEach(x => this.translate('Der Hund wackelt mit dem Schwanz.', x.code).subscribe())
  }
}
