<div>
  <div *ngFor="let color of colors" class="color-field"
       [class.mobile]="isMobile"
       [style.background-color]="color"
       [style.color]="color | contrastColor"
       (click)="onSelect(color)">
    <div *ngIf="color === selectedColor" class="color-check">
      <ui-icon [icon]="BlinkIcon.Check"></ui-icon>
    </div>
  </div>
</div>
