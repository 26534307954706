import { BlinkApp, BlinkCompany } from "@blink/shared-blink-types";

export interface BlinkUserContext {
  EmployeeId: number;
  LoginUserId: number;
  LastName: string;
  FirstName: string;
  LoginCardNumber?: any;
  Email: string;
  WorklogMaxDurationHours?: any;
  BlinkApps: BlinkApp[];
  LoginUserType: string;
  Permissions: BlinkPermissions;
  GpsTrackingActive: boolean;
  PermissionGroupId: number;
  Company: BlinkCompany;
  loginUserName: string;
  ThumbnailUrl: string;
  Features: BlinkFeature[];
  LanguageCode: string;
  LanguageId: number;
  CompanyId: number;
}


export enum BlinkFeatureInternalId {
  TimeLimits = 'TimeLimits',
  Sticker = 'Sticker',
  Chat = 'Chat',
  ELearning = 'MyTutorial',
  Schulungstool = 'Schulungstool',
}

export interface BlinkPermissions {
  CanEditSystemSettings: boolean;
  CanEditTerminals: boolean;
  CanEditCompanies: boolean;
  CanEditEntireEmployees: boolean;
  CanEditSubEntireEmployees: boolean;
  CanAssignLoginCards: boolean;
  CanEditLocations: boolean;
  CanEditSubLocations: boolean;
  CanEditPlanningEntries: boolean;
  CanEditLocationPhoneNumbers: boolean;
  CanRequestLocationPdfs: boolean;
  CanTrackTimeForEmployees: boolean;
  CanReadSubWorklogs: boolean;
  CanEditWorklogs: boolean;
  CanBookWorklogs: boolean;
  CanExportWorklogs: boolean;
  CanImpersonate: boolean;
  CanUseExtendedAuthProcess: boolean;
  CanManageLocations: boolean;
  CanManageEmployees: boolean;
  CanManageTime: boolean;
  CanManageActive: boolean;
  CanEditAreas: boolean;
  CanManageCheck: boolean;
  CanDeactivateLocations: boolean;
  CanDeactivateEmployees: boolean;
  CanManageWorklogStatus: boolean;
  CanSetWorklogStatusToExported: boolean;
  CanManageAllTasksOfCompany: boolean;
  CanManageAssignedTasks: boolean;
  CanChangeTaskStatus: boolean;
  CanChangeTaskAssignee: boolean;
  CanEditShiftPlannings: boolean;
  CanManageInsight: boolean;
  CanTrackTimeManually: boolean;
  CanSelectLocationAtTimeTracking: boolean;
  CanTrackTimeWithoutLocationQrCode: boolean;
  CanManageOwnTasks: boolean;
  CanDoTraining: boolean;
  CanUseSpecialAbsenceTypes: boolean;
  CanEditEmployees: boolean;
  CanUseBlinkShop: boolean;
  CanReadOwnWorkOrders: boolean;
  CanReadOwnLocationsWorkOrders: boolean;
  CanReadLocationManagersWorkOrders: boolean;
  CanReadOwnAreasWorkOrders: boolean;
  CanReadAllWorkOrders: boolean;
  CanAcknowledgeWorkOrder: boolean;
  CanChangeOwnWorkOrders: boolean;
  CanChangeOwnLocationsWorkOrders: boolean;
  CanChangeLocationManagersWorkOrders: boolean;
  CanChangeOwnAreasWorkOrders: boolean;
  CanChangeAllWorkOrders: boolean;
  CanCreateWorkOrder: boolean;
  CanDeleteWorkOrder: boolean;
  CanReadCompanyLocations: boolean;
  CanReadManagerLocations: boolean;
  CanReadAreaLocations: boolean;
  CanDeleteTickets: boolean;
  CanExportWorklogsPdfExcel: boolean;
}

export interface BlinkFeature {
  InternalId: BlinkFeatureInternalId;
  Name: string;
}


export type BlinkPermission = keyof BlinkPermissions

