import { Injectable } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import {
  BTakeOrChooseImageSheetComponent
} from "./b-take-or-choose-image-sheet/b-take-or-choose-image-sheet.component";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { CameraService } from "../camera";
import { CameraSource } from '@capacitor/camera';
import { FilePicker, PickedFile } from '@hotend/capacitor-file-picker';
import { logService } from '@blink/util';
import { BlinkImageModalComponent } from './ui-image-modal/blink-image-modal.component';


export function openUrl(url) {
  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.setAttribute("href", url);
  anchor.setAttribute("target", "_blank");

  anchor.click();

  anchor.remove();
}

@Injectable({ providedIn: "root" })
export class MediaService {

  constructor(private platform: Platform,
              private translate: TranslateService,
              private modalController: ModalController,
              private cameraService: CameraService) {
    logService(this);
  }

  async takeOrChooseImage(imageOnly = true): Promise<{ stringResult: string, file: PickedFile, fileName?: string }> {
    if (Capacitor.isNativePlatform()) {
      const modal = await this.modalController.create({
        component: BTakeOrChooseImageSheetComponent,
        cssClass: "sheet-thing",
        componentProps: { imageOnly }
      });

      await modal.present();
      return modal.onDidDismiss().then(async response => {
        if (response.data) {
          if (response.data !== CameraSource.Prompt) {
            return {
              stringResult: await this.cameraService.chooseOrTake(response.data, {
                resize: 750
              }), file: null
            };
          } else {
            return await FilePicker.pickFiles({
              readData: true
            }).then(async files => {
              return {
                stringResult: files.files[0].data,
                fileName: files.files[0].name,
                file: null
              }
            });
          }
        } else {
          return { stringResult: null, file: null }
        }
      });
    } else {
      return await FilePicker.pickFiles({
        types: imageOnly ? ["image/*"] : []
      }).then(async files => {
        return {
          stringResult: null, file: files.files[0]
        }
      });
    }
  }

  async openWebsite(url: string) {
    if (Capacitor.isPluginAvailable("Browser")) {
      await Browser.open({ url });
    } else {
      openUrl(url);
    }
  }

  async viewImage(imageBase64: string, deletable = false, fileName: string = null) {
    const viewer = await this.modalController.create({
      component: BlinkImageModalComponent,
      componentProps: {
        src: `${imageBase64}`,
        deletable,
        fileName,
        viewerTitle: this.translate.instant('typedGlobal.attachment')
      },
      cssClass: 'ui-image-modal',
      keyboardClose: true,
      showBackdrop: true
    });
    await viewer.present();
    const response = await viewer.onDidDismiss();
    return ({
      deleteImage: response.role === "delete"
    });
  }
}
