import { Component, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CheckChecklist } from '@blink/api';
import { FillChecklistTranslateService } from '@blink/check/shared/services/fill-checklist';
import { BlinkLocation } from '@blink/shared-blink-types';

@Component({
  selector: 'check-location-checklist-select',
  templateUrl: './location-checklist-select.component.html',
  styleUrls: ['./location-checklist-select.component.scss']
})
export class LocationChecklistSelectComponent implements OnDestroy {
  // locationChecklists$: Observable<List<OCCheckListLineItem>>;

  location: BlinkLocation;
  checklists: CheckChecklist[];

  subscriptions = new Subscription();

  constructor(public modalController: ModalController,
              public t: FillChecklistTranslateService) {
  }

  onChecklistSelected(checklist: CheckChecklist) {
    void this.modalController.dismiss(checklist);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
