<ui-page [title]="t.ENTER_CODE_PAGE.PAGE_TITLE" backHref="login/blink-id">
  <ui-card class="ion-text-center">
    <div class="ion-padding">

      <div class="text-large" *ngIf="showEmailText">{{ t.ENTER_CODE_PAGE.EMAIL_TEXT | translate }}</div>
      <div class="text-large" *ngIf="!showEmailText">{{ t.ENTER_CODE_PAGE.SMS_TEXT | translate }}</div>
      <div class="text-large"><b>{{ displayAuthTarget }}</b></div>
      <br/>
      <h4>Code</h4>
      <ion-item class="blink-item-input" color="input" style="text-align: center; font-size: 30px;">
        <ion-input type="text" pattern="\d*" #codeInput autofocus="true" inputmode="tel"
                   (keyup)="codeSubject.next($event.target['value'])">
        </ion-input>
      </ion-item>


      <br/>

      <ion-button expand="block" size="large" fill="outline" (click)="noCodeReceived()">
        {{ t.ENTER_CODE_PAGE.NO_CODE | translate }}
      </ion-button>
    </div>
  </ui-card>
</ui-page>
