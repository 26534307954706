import { Component, OnDestroy } from '@angular/core';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BlinkIcon } from '@blink/ui';
import { NetworkService } from '@blink/capacitor-plugins';
import { Capacitor } from '@capacitor/core';
import {
  BlinkLocationRepository,
  BlinkUserContext,
  CheckChecklist,
  CheckChecklistApi,
  CheckChecklistDataApi,
  CheckChecklistRepository,
  CheckResult,
  CompanyRepository,
  SessionRepository
} from '@blink/api';
import { CheckTranslateService, logComponent, trackById } from '@blink/util';
import { tap } from 'rxjs/operators';
import { LocationChecklistService } from '@blink/check/shared/components/legacy';
import { FillChecklistService, FillChecklistTranslateService } from '@blink/check/shared/services/fill-checklist';

@Component({
  selector: 'check-home',
  templateUrl: 'home.html',
  styleUrls: ['home.scss']
})
export class HomeComponent implements OnDestroy {
  BlinkIcon = BlinkIcon;
  trackById = trackById;

  storeMigrationExecuted = false;

  checklistsExists$ = this.checklistRepo.checklists$;
  locationChecklistsExists$ = this.checklistRepo.locationChecklistsExists$;

  locations$ = this.locationRepo.locations$;

  checklistLanguages$ = this.checklistRepo.checklistLanguages$;
  selectedLanguage$ = this.checklistRepo.selectedLanguage$;

  results$: Observable<CheckResult[]>;
  resultsLoading = false;

  notStartedChecklists$: Observable<CheckChecklist[]> = this.checklistRepo.getNotStartedChecklists();
  startedChecklists$: Observable<CheckChecklist[]> = this.checklistRepo.getStartedChecklists();

  isApp: boolean;

  getChecklistByLocationSubscription = new Subscription();
  subscriptions = new Subscription();

  userContext: BlinkUserContext;

  constructor(public navCtrl: NavController,
              public platform: Platform,
              private networkService: NetworkService,
              public modalController: ModalController,
              public alertController: AlertController,
              public translate: TranslateService,
              public t: CheckTranslateService,
              public fillCheckTranslate: FillChecklistTranslateService,
              private checklistRepo: CheckChecklistRepository,
              private locationRepo: BlinkLocationRepository,
              private checklistApi: CheckChecklistApi,
              private checklistDataApi: CheckChecklistDataApi,
              private sessionRepo: SessionRepository,
              private locationChecklistService: LocationChecklistService,
              public fillChecklistService: FillChecklistService,
              private companyRepository: CompanyRepository) {
    this.isApp = Capacitor.isNativePlatform();
    logComponent(this);
  }

  get currentUserName(): string {
    const nameArray = [];

    if (this.userContext) {
      if (this.userContext.FirstName !== null) {
        nameArray.push(this.userContext.FirstName);
      }
      if (this.userContext.LastName !== null) {
        nameArray.push(this.userContext.LastName);
      }
    }

    return nameArray.join(' ');
  }

  get isOnline(): boolean {
    return this.networkService.connected;
  }

  ionViewWillEnter() {
    if (this.isOnline) {
      this.checklistApi.getList(this.companyRepository.getActiveCompanyId());
      this.fetchResults();
    }

    this.subscriptions.add(this.sessionRepo.userContext$.subscribe(userContext => {
      this.userContext = userContext;
    }));
  }

  scanQrCode() {
    this.locationChecklistService.scanLocationCode();
  }

  // setChecklistsLanguage(language: string) {
  //   this.checklistRepo.setFillLanguage(language);
  // }

  selectLocationChecklist() {
    void this.locationChecklistService.selectLocationChecklist();
  }

  fetchResults() {
    this.resultsLoading = true;
    this.results$ = this.checklistDataApi.getRecentResults().pipe(
      tap(() => this.resultsLoading = false)
    );
  }

  ngOnDestroy() {
    this.getChecklistByLocationSubscription.unsubscribe();
    this.getChecklistByLocationSubscription = new Subscription();
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
  }
}
