<ui-page [attr.data-testid]="'page_loginShell'">
  <div class="ion-text-center">

    <img src="assets/imgs/logo.png" class="logo" alt="logo"/>

    <ui-card>
      <div class="ion-padding">
        <h4>{{ t.REGISTRATION.WELCOME | translate }}</h4>
        <div>{{ t.REGISTRATION.chooseMethod | translate }}</div>
        <br/>

        <ion-button id="register-login-card-button"
                    expand="block"
                    (click)="startBlinkIdLoginFlow()">
          {{ t.REGISTRATION.withBlinkId | translate }}
        </ion-button>

        <p class="line-around">
          {{ t.or | translate }}
        </p>

        <ion-button id="register-credential-button"
                    expand="block"
                    (click)="startCredentialsLoginFlow()">
          {{ t.REGISTRATION.withCredentials | translate }}
        </ion-button>

        <b *ngIf="isInternetExplorer">{{ t.REGISTRATION.NO_INTERNET_EXPLORER | translate }}</b>

        <ion-button class="register-help-button"
                    fill="clear"
                    expand="full"
                    (click)="showHelpText()">
          <ui-icon [icon]="BlinkIcon.Info" class="mr-5"></ui-icon>
          {{ t.REGISTRATION.help | translate }}
        </ion-button>
      </div>
    </ui-card>
  </div>

</ui-page>
