import dayjs from "dayjs";
import 'dayjs/locale/de';
import updateLocale from 'dayjs/plugin/updateLocale'
// import * as localeData from 'dayjs/plugin/localeData';
import * as weekday from 'dayjs/plugin/weekday';
import * as isToday from 'dayjs/plugin/isToday';
import * as duration from 'dayjs/plugin/duration';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import * as utc from 'dayjs/plugin/utc'
import * as isBetween from 'dayjs/plugin/isBetween'
import * as weekOfYear from 'dayjs/plugin/weekOfYear'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'

export function initDayjs() {
  dayjs.extend(updateLocale);
// dayjs.extend(localeData)
  dayjs.extend(weekday);
  dayjs.extend(utc);
  dayjs.extend(isToday);
  dayjs.extend(duration)
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isBetween)
  dayjs.extend(customParseFormat)
  dayjs.extend(weekOfYear)

  updateDayjsLocale('de');
}

export function updateDayjsLocale(locale: string) {
  dayjs.extend(updateLocale);
  dayjs.locale(locale);
  dayjs.updateLocale(locale, { weekStart: 1 })
}
