<ui-page title="Login"
         backHref="/register"
         [legacyWrapper]="true">

  <div class="spinner-container" *ngIf="loading">
    <ion-spinner color="primary"></ion-spinner>
  </div>

  <ng-container *ngIf="!loading">
    <p class="mt-0">
      {{ t.REGISTER_MANUALLY_PAGE.textCredentials | translate }}
    </p>

    <div *ngIf="form">
      <form [formGroup]="form"
            (ngSubmit)="tryLogin()">
        <ui-input formControlName="company"
                  [label]="t.REGISTER_MANUALLY_PAGE.company"
                  labelPlacement="floating">
        </ui-input>
        <ui-input formControlName="username"
                  [label]="t.REGISTER_MANUALLY_PAGE.username"
                  labelPlacement="floating">
        </ui-input>
        <ui-input formControlName="password"
                  type="password"
                  [label]="t.REGISTER_MANUALLY_PAGE.password"
                  labelPlacement="floating">
        </ui-input>
        <ion-button expand="block"
                    [disabled]="!form.valid"
                    type="submit"
                    data-testid="btn_login">
          {{ t.REGISTRATION.loginNow | translate }}
        </ion-button>
      </form>

      <ion-button expand="block"
                  fill="clear"
                  (click)="forgotPassword()">
        {{ t.REGISTER_MANUALLY_PAGE.FORGOT_PASSWORD | translate }}
      </ion-button>

    </div>
  </ng-container>
</ui-page>
