import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { Component } from '@angular/core';

interface UiDisplayImageProps extends FormlyFieldProps {
  src: string;
}

@Component({
  selector: 'ui-formly-image',
  template: `
    <img
      [src]="props.src"/>`
})
export class UiFormlyDisplayImageFieldTypeComponent extends FieldType<FieldTypeConfig<UiDisplayImageProps>> {
}
