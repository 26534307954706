import { Component } from '@angular/core';
import { CameraSource } from '@capacitor/camera';
import { ModalController } from '@ionic/angular';
import { MeTranslateService } from '@blink/util';
import { BlinkIcon } from '@blink/ui';

@Component({
  selector: 'blink-take-or-choose-image-sheet',
  templateUrl: './b-take-or-choose-image-sheet.component.html'
})
export class BTakeOrChooseImageSheetComponent {
  protected readonly BlinkIcon = BlinkIcon;

  CameraSource = CameraSource;
  imageOnly = true;

  constructor(public t: MeTranslateService,
              public modalController: ModalController) {
  }

}
