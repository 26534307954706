<ui-card *ngIf="locationChecklistsExists$ | async">
  <ion-list>
    <ion-list-header class="mb-0">
      <ion-title>{{ t.home.locationChecklists_label | translate }}</ion-title>
    </ion-list-header>
    <ion-item lines="none">
      <ion-row>
        <ion-col>
          <ion-button fill="outline" size="large" *ngIf="showLocationSelectButton"
                      (click)="locationSelectButtonClick.emit()"
                      data-testid="btn_selectLocation">
            <ui-icon slot="start" [icon]="BlinkIcon.Location" class="mr-5"></ui-icon>
            {{ t.home.selectLocation | translate }}
          </ion-button>

          <ion-button fill="outline" size="large" *ngIf="isApp"
                      (click)="scanQrCode.emit()">
            <ui-icon slot="start" [icon]="BlinkIcon.QrCode"></ui-icon>
            {{ t.home.scanQrCode | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-item>
  </ion-list>
</ui-card>

<ui-card *ngIf="notStartedChecklists$ | async as notStartedChecklists">
  <ion-list class="checklistsSection" *ngIf="notStartedChecklists.length">
    <ion-list-header class="mb-0">
      <ion-title>{{ t.home.generalChecklists_label | translate }}</ion-title>
    </ion-list-header>
    <check-checklist-lineitem
      *ngFor="let checklist of notStartedChecklists| slice:0:generalChecklistsPageCount; trackBy: trackById"
      [checklist]="checklist"
      [showIsStarted]="true"
      (checklistClick)="notStartedChecklistClick.emit(checklist)"
      [attr.data-testid]="'general-checklist_' + checklist.Id">
    </check-checklist-lineitem>
    <ion-item button *ngIf="notStartedChecklists.length > 3 && generalChecklistsPageCount === 3"
              (click)="generalChecklistsPageCount = 999" utilDataTestId="ionItem_checklistShowAll">
      <ion-title class="checkListShowAll">{{ t.home.showAll | translate }}</ion-title>
    </ion-item>

    <ion-item button *ngIf="generalChecklistsPageCount !== 3" (click)="generalChecklistsPageCount = 3">
      <ion-title class="checkListShowAll">{{ t.home.showLess | translate }}</ion-title>
    </ion-item>
  </ion-list>
</ui-card>

<ui-card *ngIf="startedChecklists$ | async as startedChecklists">
  <ion-list class="checklistsSection" *ngIf="startedChecklists.length">
    <ion-list-header>
      <ion-title>{{ t.home.processingChecklists_label | translate }}</ion-title>
    </ion-list-header>

    <ng-container>
      <check-checklist-lineitem *ngFor="let checklist of startedChecklists; trackBy: trackById"
                                [checklist]="checklist"
                                [showIsStarted]="true"
                                (checklistClick)="startedChecklistClick.emit(checklist)">
      </check-checklist-lineitem>
    </ng-container>
  </ion-list>
</ui-card>
