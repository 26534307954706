<div *ngIf="!config.showLanguageSelect"
     class="text-gray mb-10">
  {{ t.adjustTranslationsInfo | translate }}
</div>
<ng-container *ngIf="config.showLanguageSelect">
  <div class="text-gray mb-10">
    {{ t.translationInfo | translate }}
  </div>
  <div style="display: flex; align-items: center;">
    <ui-input-select style="flex-grow: 1"
                     [disabled]="!enabled"
                     [clearable]="false"
                     [items]="languageSelectList"
                     [label]="t.chooseLanguage"
                     [multiple]="true"
                     [placeholder]="t.pleaseChoose"
                     [searchable]="true"
                     [(ngModel)]="selectedLanguageIds"
                     [ngModelOptions]="{standalone: true}"
                     (add)="addTranslationFor($event)"
                     (remove)="removeTranslationFor($event)">
    </ui-input-select>
    <ion-button
      *ngIf="(allLanguages && internalTranslations) && allLanguages.length > internalTranslations.length"
      [disabled]="!enabled"
      class="ml-10"
      fill="outline"
      (click)="selectAllLanguages()">
      {{ t.addAll | translate }}
    </ion-button>
  </div>
</ng-container>
<ng-container *ngIf="internalTranslations.length > 0">
  <div class="mt-15 pb-15"
       style="border-bottom: 1px solid var(--item-border-color)">
    <ion-button fill="outline"
                (click)="translateAutomatically()">
      <ui-icon [icon]="BlinkIcon.Sparkles"
               class="mr-5">
      </ui-icon>
      {{ t.translateAutomatically | translate }}
    </ion-button>
    <ion-button *ngIf="config.showLanguageSelect"
                fill="outline"
                color="danger"
                (click)="removeAllTranslations()">
      <ui-icon [icon]="BlinkIcon.Delete"
               class="mr-5">
      </ui-icon>
      {{ t.deleteAll | translate }}
    </ion-button>
  </div>

  <div *ngFor="let translation of internalTranslations; trackBy: identifyTranslation"
       class="mt-15">
    <div style="display: flex; justify-content: space-between; align-items: center">
      <div class="text-lg font-medium my-10">
        {{ translation.language?.Name!! }}
      </div>
      <div>
<!--        <ion-button *ngIf="config.showLanguageSelect"-->
<!--                    fill="clear"-->
<!--                    (click)="removeTranslation(translation)">-->
<!--          <ui-icon [icon]="BlinkIcon.Delete"-->
<!--                   class="mr-5">-->
<!--          </ui-icon>-->
<!--          {{ t.delete | translate }}-->
<!--        </ion-button>-->
      </div>
    </div>


    <ng-container *ngFor="let item of translation.items">
      <ng-container *ngIf="item.config.condition === undefined || item.config.condition">
        <ui-input *ngIf="item.config.inputType === undefined"
                  [label]="item.config.label"
                  placeholder=""
                  [(ngModel)]="item.translatedText"
                  [ngModelOptions]="{standalone: true}"
                  [required]="false"
                  (ngModelChange)="updateIsValid()">
        </ui-input>
        <quill-editor *ngIf="item.config.inputType === 'wysiwyg'"
                      [(ngModel)]="item.translatedText"
                      class="ion-margin-bottom"
                      style="display:block; height:100%"
                      [modules]="quilModules"
                      (ngModelChange)="updateIsValid()">
        </quill-editor>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="additionalTranslations">
      <div *ngFor="let valueTranslation of additionalTranslations[translation.language.Id]">
        <ui-input [label]="valueTranslation.referenceName!"
                  placeholder=""
                  [(ngModel)]="valueTranslation.Name"
                  [ngModelOptions]="{standalone: true}"
                  [required]="false"
                  (ngModelChange)="updateIsValid()">
        </ui-input>
      </div>
    </ng-container>


  </div>


</ng-container>

<div *ngIf="!enabled" class="disabled-div"></div>
