import { Injectable } from '@angular/core';
import { ApiManager, BlinkService } from '../../shared/api-manager';
import { CheckItemValue } from './item-value';
import { tap } from 'rxjs/operators';
import { CheckItemValueRepository } from './item-value.repository';
import { CheckItemTranslation } from '../item/item-translation';
import { CheckItemValueTranslation } from './item-value-translation';

@Injectable({
  providedIn: 'root'
})
export class CheckItemValueApi {
  private api = this.apiManager
    .createApiForEndpoint<CheckItemValue>(
      'odata/v1',
      'ItemValues',
      BlinkService.Check
    );

  private translateApi = this.apiManager
    .createApiForEndpoint<CheckItemValueTranslation>(
      'odata/v1',
      'ItemValueTranslations',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager,
              private itemValueRepo: CheckItemValueRepository) {
  }

  create(itemValue: Partial<CheckItemValue>) {
    console.log('create!', itemValue);
    return this.api.post({ body: itemValue }).pipe(tap(
      created => this.itemValueRepo.sync([{
        ...created,
        Active: true,
        ItemValueTranslations: itemValue.ItemValueTranslations?.map(ivt => ({ ...ivt, ItemValueId: created.Id }))
      }], false)
    ));
  }

  update(itemValue: CheckItemValue) {
    itemValue.Active = true;
    const query = {
      key: itemValue.Id,
      oDataReturnRepresentation: true,
      body: itemValue
    }
    return this.api.put(query).pipe(tap(
      updated => this.itemValueRepo.sync([{
        ...updated,
        ItemValueTranslations: itemValue.ItemValueTranslations
      }], false)
    ));
  }

  delete(id: number) {
    this.api.delete({ key: id }).subscribe(
      () => this.itemValueRepo.delete(id)
    );
  }

  getTranslations(itemId: number) {
    const query = {
      filter: {
        ItemId: itemId
      }
    }
    return this.translateApi.get<CheckItemValueTranslation[]>(query);
  }
}
