export default {
  home: {
    pageTitle: 'pagina de inicio',
    welcome: 'Bienvenido a la aplicación Blink me. Diviértete charlando,\n' +
      '\n' +
      'Tu equipo de Blink',
    startChat: 'comenzar',
    ticket: 'boleto',
    timeLicenseMissing: '¡No se le ha asignado una licencia de Blink Time!'
  },
  conversations: { pageTitle: 'Noticias', yourChats: 'Tus charlas', chat: 'Charlar' },
  settings: {
    settings: 'Ajustes',
    pushEnabled: 'Recibir notificaciones automáticas',
    system: 'sistema',
    languageSelection: 'Selección de idioma',
    currentSettings: 'Configuraciones actuales',
    user: 'usuario',
    appVersion: 'Versión (aplicación)',
    apiVersion: 'Versión (API)',
    deviceInfo: 'Versión del dispositivo',
    buildVersion: 'versión (compilación)',
    helpAndDataPolicy: 'Ayuda y protección de datos',
    text1: 'Nuestro equipo de soporte estará encantado de ayudarle si tiene alguna pregunta:',
    phone: 'teléfono',
    mail: 'correo electrónico',
    information: 'información',
    documentation: 'Documentación en línea',
    documentationText: 'En nuestra documentación en línea encontrará instrucciones y vídeos para todas las áreas importantes de Blink.',
    dataPolicy: 'protección de Datos',
    support: 'Apoyo',
    moreActions: 'Otras acciones',
    protocol: 'protocolo',
    showChatUserSecret: 'Compartir chat con otro dispositivo',
    scanCodeAnotherDevice: '¡Escanee el siguiente código QR en su nuevo dispositivo o ingrese su código de chat personal en el nuevo dispositivo!',
    yourPersonalCode: 'Tu código de chat personal',
    permissions: {
      title: 'Permisos',
      grantPermission: 'conceder permiso',
      camera: 'cámara',
      cameraHint: 'Necesario para el registro de tiempos mediante código objeto.',
      nfc: 'NFC',
      nfcHint: 'Necesario para el registro de tiempo mediante etiqueta NFC.',
      media: 'Tomar imagen e imagen de la biblioteca.',
      mediaHint: 'Necesario para capturar imágenes y documentos relacionados con sus tiempos y boletos.',
      location: 'Ubicación',
      locationHint: 'Necesario para registrar su ubicación en el momento de la grabación del tiempo.',
      notifications: 'Notificaciones push',
      notificationsHint: 'Necesario para recibir notificaciones push para mensajes de chat, notificaciones parpadeantes y stickers.',
      locationDisabledOnSystem: 'Los servicios de ubicación están deshabilitados. Actívelo en la configuración de su dispositivo.'
    }
  },
  connectionState: {
    connecting: 'Conectar...',
    connected: 'Atados juntos.',
    disconnecting: 'La conexión está desconectada...',
    disconnected: 'conexión perdida',
    denied: 'No se pudo establecer la conexión.'
  },
  noOneToFind: 'No se encontró ninguno.',
  nothingToFind: 'No se encontró nada.',
  noLocationFound: 'No se encontraron objetos.',
  searchPlaceholder: 'Buscar...',
  employeeSearchPlaceholder: 'Nombre, primer nombre',
  typeToSearch: 'Toca para buscar...',
  typeToSearchEmployee: 'Ingrese el nombre de la persona para iniciar un chat',
  createChatWithEmployee: 'buscando empleados',
  createChatWithLocationTeam: 'Objeto de búsqueda',
  createTeamleaderChat: 'todos mis empleados',
  yourLanguageSelection: 'Su selección de idioma:',
  loginInfo: 'Para registrarse, escanee su código QR personal:',
  updating: 'Blink me se está actualizando.',
  notice: 'Aviso',
  importantNotice: 'Nota IMPORTANTE',
  continue: 'Más',
  intro: {
    slide1: {
      header: 'Bienvenido',
      text: 'Diviértete con tu aplicación Blink me.'
    },
    slide2: {
      header: 'Charlar',
      text: 'Chatea directamente con tus compañeros o entérate de las últimas novedades de grupos interesantes.'
    },
    slide3: {
      header: 'Entradas',
      text: 'Aquí puede ver sus tareas y crear aplicaciones.'
    }
  },
  pleaseWait: 'Espere por favor...',
  skip: 'Saltar',
  startAgain: 'Empezar de nuevo',
  lock: {
    createPinHeader: 'Tu PIN personal',
    createPinInfo: 'Para proteger sus datos, puede establecer un PIN aquí que deberá ingresar cada vez que use la aplicación Blink me.',
    validatePin: 'Por favor ingrese el PIN nuevamente.',
    tryAgain: 'El PIN no coincide. Por favor inténtelo de nuevo.',
    triedToOften: 'Lamentablemente su PIN no coincide. ¿Quieres reiniciar el proceso o omitirlo y hacerlo más tarde?',
    enterPinHeader: 'Entrada de pin',
    enterPinInfo: 'Ingrese su PIN para desbloquear la aplicación.',
    remainingTrys: '{{amount}} intentos más. Luego deberás iniciar sesión en la aplicación nuevamente.',
    tooManyEnters: 'Lamentablemente, ya no es posible registrarse con un PIN. Inicie sesión nuevamente con su tarjeta de identificación de Blink.',
    reSignIn: 'Nuevo registro',
    setpin: 'Entrada de PIN al usar la aplicación',
    skip: '¿No quieres asignar un PIN ahora? Puede activar esta función en cualquier momento a través de la configuración de la aplicación, incluso más tarde.',
    dontUsePin: 'no use un PIN',
    deactivatePinHeader: 'Desactivar PIN',
    deactivatePinInfo: 'Ingrese su PIN para desactivar la protección.'
  },
  active: {
    yourTickets: 'Tus entradas',
    createNewTask: 'Crear un nuevo billete',
    assignee: 'editor',
    location: 'objeto',
    dueDate: 'Debido a',
    createTask: 'Crear Ticket',
    taskCreated: 'El ticket ha sido creado.',
    backToTasks: 'Volver a entradas',
    createdAt: 'creado en',
    attachments: 'Archivos adjuntos',
    addFileOrImage: 'Agregar archivo o foto',
    addImage: 'agregar una foto',
    comments: 'Comentarios',
    addComment: 'agregar comentario',
    addCommentInfo: 'El comentario es visible para cualquiera que pueda ver este ticket.',
    commentCreated: 'El comentario ha sido guardado.',
    showClosed: 'Mostrar entradas cerradas',
    resetFilter: 'restablecer todos los filtros',
    changeTicketStatusTo: 'Cambiar el estado del ticket a:',
    editTask: 'Editar ticket',
    saveTask: 'Guardar billete',
    taskSaved: 'El billete ha sido guardado.',
    deleteImage: 'Imagen clara',
    deleteImageConfirmation: '¿Realmente quieres borrar la imagen?',
    deleteAttachment: 'Eliminar archivo adjunto',
    deleteAttachmentConfirmation: '¿Está seguro de que desea eliminar este archivo adjunto?',
    deleteComment: 'Eliminar comentario',
    deleteCommentConfirmation: '¿Realmente quieres eliminar el comentario?',
    changeAssignee: 'Cambiar editor',
    duplicateTask: 'Boleto duplicado',
    duplicateTaskConfirmation: '¿Quieres duplicar este ticket?',
    taskDuplicated: 'El billete estaba duplicado.',
    deleteTask: 'Eliminar billete',
    deleteTaskConfirmation: '¿Realmente deseas eliminar este ticket?',
    taskDeleted: 'El billete ha sido eliminado.',
    assigneeChanged: 'Se ha cambiado el procesador.',
    statusChanged: 'El estado del ticket ha sido cambiado.',
    startDate: 'comenzar',
    endDate: 'Fin',
    doctorVisitDate: 'Fecha de la visita al médico.',
    doctorVisitDescription: 'Fecha de la visita al médico: {{date}}',
    chooseDate: 'Elige una fecha',
    comment: 'Comentario (opcional)',
    dateError: 'La fecha de finalización no puede ser anterior a la fecha de inicio.',
    employee: 'Empleados',
    pleaseConnectToLoad: 'Establezca una conexión a Internet para cargar sus boletos.'
  },
  emptyState: {
    chats: '¡Crea un chat!',
    chat: 'Escribe el primer mensaje :)',
    tasks: 'No se encontraron entradas.',
    attachments: 'No hay archivos adjuntos disponibles.',
    comments: 'No hay comentarios disponibles.',
    vacation: 'No se ingresaron vacaciones para este año.'
  },
  formError: {
    min: 'El valor debe ser mayor que {{value}}.',
    min2: 'El valor debe ser mayor o igual a {{value}}.',
    max: 'El valor debe ser menor o igual a {{value}}.',
    requiredBecause: 'Valor requerido porque {{name}} está completado.',
    isRequired: '{{name}} es obligatorio.',
    numberInUse: 'El número de personal ya está en uso.',
    maxlength: '{{name}} no puede contener más de {{value}} caracteres.',
    faultyBlinkId: 'ID de parpadeo incorrecto'
  },
  ngSelect: {
    typeToSeach: 'Por favor ingrese 3 letras o más.',
    loading: 'cargando datos...',
    notFound: 'No se encontró {{title}}.',
    clearAll: 'Restablecen a los predeterminados'
  },
  placeholder: {
    employee: 'Buscar por nombre, apellido, ID de empleado, ID de Blink',
    location: 'Buscar por nombre, número o códigos de objeto',
    date: 'Elige una fecha',
    searchTasks: 'Buscar entradas...'
  },
  hasChat: 'Iniciar chat ahora',
  hasNoChatYet: 'Aún no ha instalado la aplicación Blink me.',
  noBlinkActiveLicence: 'Sin licencia',
  noBlinkActiveLicenceInfo: 'Actualmente no tienes una licencia para esta aplicación. Por favor contacte a su supervisor.',
  noBlinkMeLicence: 'Sin licencia',
  noBlinkMeLicenceInfo: 'Esta aplicación no se puede utilizar con su sistema. Utilice Blink Time desde la aplicación o Playstore.',
  noSuitableAppFound: 'No se encontró ninguna aplicación para abrir el archivo.',
  myChats: 'Tus charlas',
  hasNoChatsHome: '¡Empiece a chatear ahora! Puede hablar con todos los colegas y participar en grupos de objetos y equipos.',
  hasNoTicketsHome: 'No hay entradas abiertas disponibles.',
  myTickets: 'Tus entradas',
  myEmployees: 'mis empleados',
  group: 'grupo',
  confirmLogoutWithChat: 'Si cierra sesión, sus mensajes de chat se eliminarán. ¿Te gustaria continuar?',
  message: 'Noticias',
  messageNotDecryptable: 'No se pudo descifrar el mensaje.',
  chat: {
    userAlreadyExists: 'Ya has configurado el chat en otro dispositivo. Para poder utilizar el chat en este dispositivo, debe vincular los dispositivos. Alternativamente, puede reiniciar la configuración, pero luego el chat ya no podrá usarse en el otro dispositivo.',
    linkDevices: 'Vincular dispositivos',
    withoutChat: 'Continuar sin chat',
    setupAgain: 'Restablecer chat',
    setupAgainText: 'Si configura el chat nuevamente, todos los mensajes de los chats individuales existentes se eliminarán y el chat ya no podrá usarse en otros dispositivos. ¿Te gustaria continuar?',
    LinkDevicesText: 'Para vincular los dispositivos para chatear, abra Configuración en su otro dispositivo y seleccione "Compartir chat con otro dispositivo".',
    personalToken: 'Tu código de chat personal',
    wrongCode: 'El código de chat proporcionado es incorrecto.',
    beingPrepared: 'Se está preparando el chat.',
    twilioAccessDenied: 'No se pudo establecer la conexión con el servicio de chat. Comuníquese con el soporte técnico de Blink.',
    notificationsDenied: 'Las notificaciones están deshabilitadas en su dispositivo/navegador. Vaya a Configuración y habilite las notificaciones para que Blink me reciba notificaciones. Luego puede activar las notificaciones en su configuración personal.',
    deleted: 'El chat ha sido eliminado.',
    unavailable: 'El chat no está disponible',
    participant: 'Partícipe',
    teamChatDescription: 'Todos los empleados para los cuales está registrado como gerente se agregan a este grupo de chat.',
    locationChatDescription: 'Los empleados se agregan automáticamente si tienen un horario para este objeto en las últimas cuatro semanas o en las próximas cuatro semanas.',
    createEmployeeChatDescription: 'Se creará un chat privado con el empleado seleccionado.',
    teamChatCreationTitle: 'Crear grupo de chat',
    createTeamChatDescription: 'Se crea un grupo de chat en el que se agregan automáticamente todos los empleados de los que estás registrado como gerente.',
    createLocationChatDescription: 'Se crea un grupo de chat para el objeto seleccionado, donde los empleados se agregan automáticamente si tienen un plan para ello en las últimas cuatro semanas o en las próximas cuatro semanas.'
  },
  protocol: {
    SEND_EMAIL: 'Enviar por correo electrónico',
    DELETE: 'Borrar registro',
    deleteMessage: '¿Realmente desea eliminar el registro? Esta acción no se puede deshacer.',
    Send: 'Enviar',
    SuccessMessage: 'El registro se envió correctamente al soporte de Blink.',
    ConfirmMessage: 'Primero comuníquese con el equipo de soporte y solo envíe el registro si se solicita.',
    Description: 'Descripción del problema'
  },
  timeTracking: {
    title: 'Seguimiento del tiempo',
    start: 'Comenzar',
    startDescription: 'Haga clic en Iniciar para iniciar el seguimiento del tiempo.',
    started: 'Comenzó',
    stop: 'Para detener',
    duration: 'Período de tiempo',
    syncButtonLabel: 'Tiempos de carga grabados sin conexión',
    offlineSyncMessage: 'Para transferir los tiempos guardados debes estar en línea.',
    successfulSync: 'Se suben los tiempos',
    scanNowQrCode: 'Código QR',
    scanNowQrCodeDescription: 'Escanee el código QR en el objeto.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Acerque su teléfono inteligente a la etiqueta NFC del objeto.',
    nfcDescription: 'Detección mediante NFC: acerque su teléfono móvil al código objeto',
    noTimeTrackingMethodAvailableDescription: 'No hay ningún método de seguimiento de tiempo configurado para este usuario. Por favor contacte al líder de su equipo.',
    noLocationCode: 'No hay código disponible',
    noLocationCodeDescription: 'Seleccione esta opción si no hay ningún código QR o etiqueta NFC en el objeto. Es obligatorio dejar un comentario.',
    noLocationCodeConfirm: '¿Está seguro de que desea comenzar sin código objeto? Debes proporcionar un comentario para detenerlo.',
    wrongCode: '¡El código QR escaneado no es un código objeto!',
    readNotice: '¡Por favor lea las instrucciones!',
    warning: 'advertencia',
    lessThanAMinuteTitle: 'Registro de tiempo completo',
    lessThanAMinute: 'Empezaste hace menos de 1 minuto. ¿Estás seguro de que quieres parar?',
    continue: 'Continuar grabando',
    timeAlert: {
      differenceInMinutesHint: 'La hora de su teléfono inteligente difiere de la hora del servidor. La diferencia está registrada.',
      differentTimeZoneHint: 'La zona horaria de su teléfono inteligente difiere de la zona horaria del servidor. La diferencia está registrada.'
    },
    leaveComment: {
      withCode: 'La información sobre el objeto/servicio no estaba clara. Por favor díganos en qué propiedad/servicio trabajó.',
      withoutCode: 'No se capturó ningún código objeto al inicio o al detenerse. Explique por qué comenzó/detuvo sin un objeto.'
    },
    comment: 'comentario',
    enterComment: 'Comentar sobre el seguimiento del tiempo',
    differentQrCode: {
      title: '¿Cambiar objeto?',
      continueInLocation: 'Continuar trabajando en {{locationName}}.',
      automaticStopInLocation: 'La grabación de tiempo en {{locationName}} se detendrá automáticamente.',
      stopInLocation: 'Detener la grabación de tiempo en {{locationName}}.',
      stop: 'Para detener',
      stopNoLocation: 'Detener la grabación del tiempo.',
      continueNoOldLocation: 'El registro de tiempo para el objeto anterior se detiene automáticamente.',
      continueWorking: 'Sigue trabajando',
      continueWorkingInNewLocation: 'Continuar trabajando en nueva propiedad.',
      stopWithLocationActivity: 'Detenga la grabación de tiempo el {{locationActivity}}.',
      continueWithNewLocationActivity: 'Continuar trabajando con {{locationActivity}}',
      stopOldLocationActivity: 'La grabación de tiempo el {{locationActivity}} se detiene automáticamente.',
      stopOldNoLocationActivity: 'La grabación de tiempo sin interpretación se detiene automáticamente.',
      continueNoLocationActivity: 'Continuar trabajando sin rendimiento.',
      sameLocationTitle: '¿Cambiar rendimiento?',
      differentLocationTitle: '¿Cambiar objeto?'
    },
    saveAndStop: 'Guardar y detener',
    noLocationScanned: '- Ningún objeto -',
    noLocationFound: '- Objeto no claro -',
    timerDifference: '{{h}} horas {{mm}} minutos',
    stopSuccess: 'Hora registrada exitosamente',
    stopSuccessToast: 'La grabación de tiempo se detuvo exitosamente.',
    stopTimeTracking: 'Para detener',
    manual: {
      time: 'Tiempo',
      locationSelect: 'Seleccionar objeto',
      loadMore: 'carga más',
      trackTime: 'Tiempo record',
      location: 'objeto',
      locationActivity: 'Actuación',
      date: 'Fecha',
      startTime: 'De',
      endTime: 'Hasta',
      create: 'Confirmar',
      createAgain: 'Registrar tiempo adicional para este objeto',
      errorUntil: 'Desde el tiempo debe ser menor que Hasta el tiempo',
      worktimeDifference: 'Horas Laborales:',
      worktimeDifferenceValue: '{{hours}} horas {{minutes}} minutos',
      worktimeDifferenceValueMinutes: '{{minutes}} minutos',
      success: 'Hora registrada exitosamente',
      break: 'Romper:',
      breakMinutes: '{{minutes}} minutos',
      breakHoursAndMinutes: '{{hours}} horas {{minutes}} minutos',
      noActivity: '- sin actuación -',
      locationPlaceHolder: 'Buscar por nombre, número o códigos de objeto',
      conflictError: 'Ya existe un registro de tiempo en los períodos especificados. No se permiten superposiciones. Por favor comprueba tus detalles.',
      currentDayDuration: 'Ya grabado',
      expectedDuration: 'Horas de trabajo esperadas',
      expectedDurationIncludedCurrent: 'incluyendo la entrada actual'
    },
    differenceInMinutesHint: 'La hora de su teléfono inteligente difiere de la hora del servidor. La diferencia está registrada.',
    differentTimeZoneHint: 'La zona horaria de su teléfono inteligente difiere de la zona horaria del servidor. La diferencia está registrada.',
    proposed: {
      capture: 'Captura',
      time: 'Tiempo',
      captureTime: 'Tiempo record',
      button: 'Confirmar',
      title: 'Confirmar horario laboral',
      includingBreak: '(incluido el descanso)',
      from: 'De',
      to: 'Hasta',
      break: 'Romper',
      hour: 'Reloj',
      confirm: {
        description: 'Por favor confirma tu horario laboral',
        confirm: 'Si, eso es correcto',
        editButton: 'No, eso no está bien'
      },
      edit: {
        description: 'Por favor especifica la duración',
        breakIncluded: 'Incluyendo {{ duration }} descanso',
        comment: 'comentario',
        yourComment: 'tu comentario',
        minutes: 'minutos',
        hours: 'Horas'
      },
      reasons: {
        overtime: 'Horas extras debido al cliente',
        faster: 'hecho más rápido',
        other: 'Misceláneas',
        representative: 'yo era un sustituto'
      },
      description: 'Confirme su horario de trabajo tan pronto como haya terminado de trabajar en la propiedad.'
    },
    codeNotAssignable: '¡El código QR escaneado no se puede asignar!',
    running: 'La grabación de tiempo está en marcha'
  },
  timeSheet: {
    title: 'Tiempos y planificación',
    noPlannings: 'Sin planificación',
    planned: 'Planificado',
    workingTime: 'Horas Laborales',
    workloadDuration: 'tiempo de trabajo semanal',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Debería',
    workedDuration: 'Es',
    hasWorkload: 'trabajar ese dia',
    pleaseConnectToLoad: 'Establezca una conexión a Internet para cargar sus tiempos.',
    footer: {
      planned: 'Planificado',
      worklogsTotal: 'Horas totales',
      workloadTotal: 'Horas según contrato',
      difference: 'diferencia'
    },
    homepageTitle: 'Tu jornada laboral'
  },
  planning: {
    planRoute: 'Planificar ruta',
    title: 'planificación',
    modifyMinutes: 'Tarifa plana:',
    includingPause: 'Romper:',
    noNavAppFound: 'No se encontró ninguna aplicación de navegación'
  },
  worklog: {
    title: 'Tiempo registrado',
    absenceTitle: 'ausencia',
    workingTime: 'Horas Laborales',
    breakDuration: 'Romper',
    reduce: 'tarifa plana',
    attachments: 'Archivos adjuntos',
    comments: 'Comentarios',
    comment: 'comentario',
    noLocation: 'Ningún objeto especificado',
    changedBy: 'La hora cambió de {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: '¿Está seguro de que desea eliminar este archivo adjunto?',
    attachmentDeleted: 'El archivo adjunto ha sido eliminado',
    confirmCommentDelete: '¿Estás seguro de que quieres eliminar este comentario?',
    commentDeleted: 'El comentario fue eliminado.'
  },
  tabs: {
    home: 'Hogar',
    times: 'veces',
    chat: 'Charlar',
    tickets: 'Entradas',
    more: 'más'
  },
  profile: {
    title: 'perfil',
    testing: 'Pruebas',
    testingDescription: 'Esta área solo es visible cuando está conectada a un sistema de prueba.',
    testingAllowQrCodeOnWeb: 'Permitir la entrada manual de código QR',
    pushNotifications: 'Notificaciones push',
    reloadUserContext: 'Recargar contexto de usuario',
    pushNotificationSuccessful: 'Notificación push recibida exitosamente',
    editProfilePicture: 'cambiar foto de perfil',
    editProfilePictureTitle: 'Seleccionar sección',
    pushNotificationNotice: 'Se requiere la autorización "Editar configuración del cliente" para la prueba.',
    send: 'Enviar',
    profilePicture: 'Foto de perfil'
  },
  help: { title: 'Ayuda' },
  myVacations: {
    vacation: 'Vacaciones',
    amountAvailableVacationDays: '{{amount}} días de vacaciones aún disponibles',
    totalVacationDays: 'Derecho a vacaciones {{ total }} días',
    remainingVacationDays: '({{ total }} días {{ sign }} {{ remaining }} días restantes de vacaciones {{ previousYear }})',
    xDaysVacations: '{{ total }} días de vacaciones',
    title: 'Tus vacaciones'
  },
  externalLinks: {
    title: 'enlaces externos',
    notice: 'Este enlace fue proporcionado por su empresa. Estás saliendo del sistema Blink.'
  },
  stickers: {
    title: 'Tus pegatinas',
    sticker: 'Pegatinas',
    sender: 'Recipiente',
    text: 'Acerca de',
    date: 'Fecha',
    emptyState: 'Desafortunadamente aún no tienes pegatinas.',
    senderDescription: 'Esta pegatina te la envió {{sender}} el {{date}}',
    readed: 'leer',
    notReaded: 'no lo leí todavía',
    newStickerToaster: '¡Nueva pegatina de parpadeo!',
    open: 'Abierto',
    next: 'Más',
    titleCases: {
      case1: '¡Felicitaciones por tu primera calcomanía!',
      case2: '¡Genial, ahora ya tienes <b>{{count}}</b> pegatinas!',
      case3: '¡Felicitaciones por su décima calcomanía!',
      case4: '30 pegatinas - ¡guau!',
      case5: 'Ahora se está llenando de gente aquí: <b>{{count}}</b> ¡Stickers!'
    },
    confirmDelete: '¿Realmente quieres eliminar estas pegatinas?'
  },
  autoTranslate: {
    isTranslated: 'Traducido automáticamente',
    translationError: 'La traducción automática no es posible actualmente.'
  },
  confirmLogout: '¿Realmente quieres cerrar sesión?',
  migration: {
    wrongVersion: 'En la versión actual de Blink me, no se admite el inicio de sesión a través de la aplicación Blink Time. Actualice la aplicación Blink me a la última versión.'
  },
  eLearning: {
    pendingTab: 'Para hacer ({{value}})',
    completedTab: 'Hecho ({{value}})',
    completed: 'Terminado',
    pending: 'Abierto',
    dueFalling: 'Atrasado',
    title: 'aprendizaje electrónico',
    course: 'curso',
    dueDate: 'Válida hasta',
    topics: 'asignaturas',
    completeDate: 'Terminado el',
    status: { Pending: 'abierto', Done: 'terminado' },
    infos: {
      pendingExists: 'Puedes encontrar todos tus cursos de formación abiertos aquí.',
      finishOverdue: 'Realice la capacitación que ya debe realizarse lo más rápido posible.',
      allCompleted: '¡Excelente! Ha completado con éxito todos sus cursos de formación.',
      emptyState: 'No tienes formación asignada.'
    }
  },
  checklists: {
    title: 'Formularios',
    description: 'Rellenar un formulario para un objeto',
    button: 'Seleccionar objeto'
  },
  pendingTab: 'Ejecución ({{value}})',
  completedTab: 'Completado ({{value}})',
  workOrders: {
    title: 'Órdenes',
    homepageTitle: 'Tus pedidos',
    responsible: 'Responsable',
    customerContact: 'Contacto con el cliente',
    trackedTime: 'Registro de tiempo para el pedido n.°{{value}}',
    show: 'Ver pedido',
    trackInfo: {
      confirmAndAcknowledge: 'Confirme las posiciones después de la implementación y realice una inspección in situ.',
      acknowledgeOnly: 'Por favor realice una inspección en el sitio.',
      confirmOnly: 'Confirme las posiciones después de la implementación.'
    },
    pleaseConnectToLoad: 'Establezca una conexión a Internet para cargar sus pedidos.',
    status: { new: 'Nuevo', inProgress: 'Ejecución', completed: 'Terminado' },
    emptyState: 'No hay pedidos abiertos disponibles.',
    emptyStateCompleted: 'No hay pedidos completados disponibles.',
    details: {
      title: 'Detalles del pedido',
      positionsTab: 'Posiciones ({{value}})',
      acknowledgementsTab: 'disminuye ({{value}})'
    },
    acknowledgement: {
      title: 'aceptación',
      start: 'Iniciar aceptación',
      acknowledgedAtDate: 'Eliminado el',
      itemSelectDescription: 'Seleccione todas las posiciones que se eliminarán.',
      customerInformationDescription: 'Proporcione los datos de contacto del cliente (comprador). Después de la aceptación, se enviará una confirmación a este correo electrónico.',
      created: 'Aceptación guardada exitosamente',
      selectAll: 'Seleccionar todo',
      emptyState: 'No hay aceptaciones.'
    },
    items: {
      items: 'posiciones',
      acknowledge: 'Confirmar posición',
      confirmAcknowledge: 'Por la presente usted confirma que el trabajo se ha realizado. El estado del puesto se actualiza a "Completado".',
      status: {
        open: 'Abierto',
        planned: 'Planificado',
        performed: 'Hecho',
        acknowledged: 'Remoto',
        billed: 'facturado'
      },
      emptyState: 'No hay puestos disponibles.'
    },
    trackTime: {
      description: 'Seleccione un trabajo para el que desee realizar un seguimiento del tiempo.',
      chooseWorkOrder: 'Seleccionar pedido',
      choosePosition: 'Seleccionar posición'
    }
  }
};
