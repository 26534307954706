import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {
  transform(value: any) {
    return (value * 100).toFixed(2).replace(/[.,]00$/, "") + '%';
  }
}
