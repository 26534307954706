import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-language-flag-stack',
  template: `
    <ng-container *ngIf="languageIds">
      <ng-container *ngFor="let languageId of languageIds; let i = index">
        <ui-language-flag *ngIf="languageIds.length <= maxVisible || i < maxVisible - 1"
                          class="language-flag-stack-item"
                          [languageId]="languageId">
        </ui-language-flag>
      </ng-container>
      <div *ngIf="languageIds.length > maxVisible"
           class="language-flag-stack-item language-flag-stack-item__badge">
        +{{ languageIds.length - maxVisible - 1 }}
      </div>
    </ng-container>
  `,
  styles: `
    :host {
      display: flex;

      .language-flag-stack-item {
        border-radius: 20px;
        border: 2px solid white;
        margin-right: -8px;

        &__badge {
          background-color: var(--ion-color-primary);
          padding: 3px 4px;
          font-size: 12px;
          display: flex;
          align-items: center;
        }
      }
    }
  `
})
export class UiLanguageFlagStackComponent {
  @Input() languageIds: number[] = [];
  @Input() maxVisible = 3;
}
