import { Injectable } from '@angular/core';
import { FileSharer } from '@byteowls/capacitor-filesharer';

@Injectable({
  providedIn: 'root'
})
export class FileShareService {
  shareFile(filename: string, contentType: string, base64Data: string) {
    return FileSharer.share({
      filename,
      contentType,
      base64Data
    });
  }
}
