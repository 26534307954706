import { Component } from '@angular/core';
import { GlobalTranslateService } from '@blink/util';
import { BlinkIcon } from '@blink/ui';
import { UiTranslateDisplayService } from './ui-translate-display.service';

@Component({
  selector: 'ui-translate-display-toggle',
  template: `
    <div class="link-base"
         (click)="translateDisplayService.showSourceText = !translateDisplayService.showSourceText">
      <!--      <ui-icon [icon]="BlinkIcon.Translate"></ui-icon>-->
      {{ (translateDisplayService.showSourceText ? t.hideTranslationSource : t.showTranslationSource) | translate }}
    </div>
  `
})
export class UiTranslateDisplayToggleComponent {
  constructor(public t: GlobalTranslateService,
              public translateDisplayService: UiTranslateDisplayService) {
  }

  protected readonly BlinkIcon = BlinkIcon;
}
