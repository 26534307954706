
export interface BlinkApp {
  Id: number,
  Active: boolean,
  InternalId: BlinkApps,
  Name: string,
  Type: BlinkAppType
  disabled: boolean;
}

export enum BlinkApps {
  BlinkTime = '1',
  BlinkActive = '2',
  BlinkDrive = '3',
  BlinkCheck = '4',
  BlinkPlan = '5',
  BlinkInsight = '6',
  BlinkMe = '7',
  BlinkCheckUnlimited = '8',
  BlinkOrderStandard = '9',
  BlinkOrderProfessional = '10',
  BlinkOrderEnterprise = '11',
  BlinkMakeIntegration = '12',
  BlinkAi = '13',
  BlinkCheckProfessional = '14',
  BlinkWorkOrder = '15',
  BlinkTimeApp = '16',
  BlinkManagerApp = '17',
  BlinkCheckApp = '18',
  BlinkOrderApp = '19'
}


export enum BlinkAppType {
  License = "License",
  App = 'App'
}
