<ion-item class="blink-item-input item-interactive"
          lines="none"
          color="input"
          [class.small-input]="small"
          [class.is-open]="isOpen">
  <ion-label *ngIf="label"
             position="stacked"
             [class.required]="required">
    {{ label | translate }}
  </ion-label>
  <ng-select #ngSelect
             [isOpen]="isOpen"
             [(ngModel)]="value"
             (add)="add.emit($event)"
             (remove)="remove.emit($event)"
             [searchable]="searchable"
             [clearable]="clearable"
             [items]="mergedItems$ | async"
             bindValue="id"
             bindLabel="name"
             appendTo="body"
             [virtualScroll]="virtualScroll"
             [multiple]="multiple"
             [hideSelected]="multiple"
             [addTag]="allowCustom ? createCustom : false"
             [minTermLength]="2"
             [loading]="loading || typeaheadLoading"
             [readonly]="readonly"
             [addTagText]="addCustomText | translate"
             [notFoundText]="notFoundText | translate"
             [placeholder]="placeholder | translate"
             [typeToSearchText]="t.typeToSearchText | translate"
             [loadingText]="t.loading | translate"
             [disabled]="!enabled || disabled">


    <ng-template *ngIf="!wrapText && !itemTemplate"
                 ng-label-tmp ng-option-tmp
                 let-item="item" let-clear="clear">
        <span *ngIf="multiple && clear" class="ng-value-icon left"
              (click)="clear(item); $event.preventDefault(); $event.stopPropagation();"
              aria-hidden="true">
          <ui-icon [icon]="BlinkIcon.Close" size="xs"></ui-icon>
        </span>
      <span [class.ng-value-label]="clear" [utilDataTestId]="'itemA-' + item.id">{{ item.name | translate }}</span>
    </ng-template>

    <ng-template *ngIf="wrapText && !itemTemplate" ng-label-tmp ng-option-tmp let-item="item">
      <div class="wrap-text" [utilDataTestId]="'itemB-' + item.id">
        {{ item.name | translate }}
      </div>
    </ng-template>

    <ng-template *ngIf="itemTemplate" ng-label-tmp ng-option-tmp let-item="item">
      <div [class.wrap-text]="wrapText">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {item}"></ng-container>
      </div>
    </ng-template>
  </ng-select>

  <ion-button *ngIf="clearable && value"
              class="input-select-action-button"
              slot="end" fill="clear"
              (click)="clear($event); $event.preventDefault(); $event.stopPropagation();">
    <ui-icon [icon]="BlinkIcon.Close"></ui-icon>
  </ion-button>

  <ion-button class="input-select-action-button mr-5"
              slot="end" fill="clear">
    <ui-icon class="dropdown-chevron" slot="end"
             [icon]="BlinkIcon.ChevronDown"
             style="margin: auto;">
    </ui-icon>
  </ion-button>
</ion-item>
<small *ngIf="info" class="input-info">
  {{ info | translate }}
</small>
