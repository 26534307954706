import { Component, Input } from '@angular/core';
import { InfoType } from '../enums/info-type';
import { BlinkIcon } from '../ui-icon';
import { InfoTypeValues } from './ui-info-type';

@Component({
  selector: 'ui-info',
  templateUrl: './ui-info.component.html',
  styleUrls: ['./ui-info.component.scss'],
})
export class UiInfoComponent {
  InfoType = InfoType;

  icon: BlinkIcon = null;
  @Input() translate: string;
  @Input() type: keyof typeof InfoType;
  @Input() custom: { color: string, icon: BlinkIcon } = null;

  get typeInfo(): InfoTypeValues {
    const type = InfoType[this.type] || this.type;
    switch (type) {
      case InfoType.Info:
        return { color: 'info-success', icon: BlinkIcon.Info };
      case InfoType.Warning:
        return { color: 'info-warning', icon: BlinkIcon.Alarm };
      case InfoType.Error:
        return { color: 'info-error', icon: BlinkIcon.Alarm };
      case InfoType.Custom:
        return this.custom;
      default:
        return { color: 'info-success', icon: BlinkIcon.Info };
    }
  }
}
