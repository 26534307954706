export default {
  cancel: 'Annulla',
  blinkSlogan: "Blink - L'app per gli addetti alla pulizia degli edifici",
  all: 'Tutti',
  hello: 'Ciao',
  description: 'Descrizione',
  ok: 'Ok',
  reset: 'Reset',
  edit: 'Modifica',
  finish: 'Blocco',
  notice: 'Nota',
  content: 'Contenuto',
  back: 'Indietro',
  close: 'Chiudere',
  backToHome: 'Torna alla pagina iniziale',
  proceed: 'Ulteriori',
  done: 'Pronto',
  delete: 'Cancellare',
  successful: 'Successo',
  error: 'Errore',
  ERROR_OCCURRED: 'Si è verificato un errore o i dati non sono corretti. Si prega di riprovare.',
  internetRequired: 'Internet richiesto',
  configuration: 'Configurazione',
  logo: 'Logo',
  uploadLogo: 'Carica il logo',
  deleteLogo: 'Cancellare il logo',
  duplicate: 'Duplicato',
  importExport: 'Importazione / Esportazione',
  import: 'Importazione',
  doImport: 'Importazione',
  export: 'Esportazione',
  doExport: 'Esportazione',
  excelExport: 'Esportazione in Excel',
  excelExportDownloaded: 'Esportazione Excel scaricata',
  excelExportError: 'Esportazione di Excel non riuscita',
  pdfDownload: 'Scaricamento PDF',
  pdfDownloaded: 'PDF scaricato',
  configSaved: 'La configurazione è stata salvata.',
  send: 'Inviare',
  connecting: 'Collegare...',
  or: 'o',
  select: 'Selezionare',
  today: 'Oggi',
  camera: 'Macchina fotografica',
  gallery: 'Galleria',
  files: 'File',
  upload: 'Caricare i file',
  chooseFiles: 'Selezionare i file',
  filesTransferring: 'Trasmettere i file...',
  filesTransferringWait: 'Si prega di attendere mentre i file vengono trasmessi.',
  filesTransferred: 'I file sono stati trasmessi con successo.',
  download: 'Scaricare',
  showInactive: 'Mostra inattivo',
  copyLink: 'Copiare il link',
  linkCopied: 'Il link è stato copiato negli appunti.',
  permissions: 'Autorizzazioni',
  email: 'E-mail',
  emailConfig: 'Configurazione della posta elettronica',
  title: 'Titolo',
  user: 'Utente',
  logout: 'Disconnettersi',
  system: 'Sistema',
  appVersion: 'Versione (App)',
  apiVersionCore: 'Versione\n(API principale)',
  apiVersionCheck: "Versione\n(Controllare l'API)",
  deviceInfo: 'Informazioni sul dispositivo',
  installBlinkTime: 'Installare il tempo di lampeggio',
  installBlinkMe: 'Installare Blink Me',
  installing: 'La versione {{version}} è installata ...',
  companyRequired: 'Selezionare il cliente',
  companyRequiredInfo: 'È necessario selezionare un client per continuare ad operare come sysadmin.',
  pleaseChoose: 'Selezionare',
  pleaseFill: 'Compilare',
  noEntitiesFound: 'Non è stato possibile trovare alcun elemento.',
  addCustom: 'Creare un elemento:',
  typeToSearchText: 'Inserire due o più caratteri...',
  loading: 'I dati vengono caricati...',
  offline: 'Non in linea',
  youAreOffline: 'Sei offline!',
  by: 'da',
  date: 'Data',
  time: 'Tempo',
  searchTitle: 'Ricerca',
  searchTerm: 'Parola chiave di ricerca',
  searchLoginUser: 'Ricerca dei dipendenti',
  searchLoginUserPlaceholder: 'Ricerca per cognome o nome',
  location: 'Oggetto',
  searchLocation: 'Oggetto di ricerca',
  searchLocationPlaceholder: "Ricerca per nome dell'oggetto, numero o tag",
  deactivate: 'Disattivare',
  active: 'Attivo',
  inactive: 'Inattivo',
  tag: 'Giorno',
  tags: 'Tag',
  color: 'Colore',
  icon: 'Icona',
  language: 'Lingua',
  general: 'Generale',
  yes: 'Sì',
  no: 'No',
  searchThrough: 'Sfogliare',
  name: 'Nome',
  create: 'Creare',
  save: 'Risparmiare',
  home: 'Casa',
  profile: 'Profilo',
  templates: 'Modelli',
  noAssignment: 'Non assegnato',
  sort: {
    change: 'Ordine di modifica',
    save: 'Salvare la sequenza',
    saved: 'La sequenza è stata salvata.'
  },
  signature: {
    signature: 'Firma',
    pleaseSign: 'Si prega di firmare',
    clickHereToSign: 'Clicca qui per firmare',
    addSignature: 'Aggiungere la firma',
    save: 'Subentrare',
    delete: 'Cancellare'
  },
  formError: {
    min: 'Il valore deve essere maggiore di {{value}}.',
    min2: 'Il valore deve essere maggiore o uguale a {{value}}.',
    max: 'Il valore deve essere minore o uguale a {{value}}.',
    requiredBecause: 'Valore necessario perché {{name}} è compilato.',
    isRequired: '{{name}} è obbligatorio.',
    numberInUse: 'Il numero del personale è già in uso.',
    maxlength: '{{name}} non deve contenere più di {{value}}. caratteri.',
    email: 'Inserire un indirizzo e-mail valido.',
    faultyBlinkId: 'ID lampeggiante difettoso'
  },
  scan: {
    choose: 'Assegnazione di un nuovo ID di lampeggio',
    text: 'Scansionare il codice QR sulla scheda di registrazione.',
    scan_now: 'Scansione ora',
    enter_manually: 'Inserire manualmente'
  },
  removeImage: "Cancellare l'immagine",
  tickets: { assignee: 'Editore', dueTo: 'Da pagare fino a' },
  ngSelect: {
    typeToSearch: 'Inserire 3 o più lettere.',
    loading: 'caricare i dati...',
    notFound: 'Nessun {{title}} trovato.',
    clearAll: 'Reset'
  },
  help: {
    pageTitle: 'Aiuto',
    text1: 'Il nostro team di assistenza sarà lieto di rispondere a qualsiasi domanda:',
    phone: 'Telefono',
    mail: 'E-mail',
    information: 'Info',
    documentation: 'Documentazione online',
    documentationText: 'Nella nostra documentazione online troverete istruzioni e video per tutte le aree più importanti di Blink.',
    support: 'Supporto'
  },
  REGISTRATION: {
    WELCOME: 'Benvenuti!',
    chooseMethod: 'Quale metodo desidera utilizzare per la registrazione?',
    withBlinkId: "Carta d'identità BLINK",
    withCredentials: 'Nome utente / Password',
    REGISTER: 'Registro',
    ALREADY_REGISTERED: 'Ho un account Blink.',
    INVALID_CARD: 'Carta non valida.',
    noBlinkId: 'Il codice QR scansionato non è un ID Blink valido',
    scanBlinkId: 'Scansione ID lampeggiante',
    ERROR: 'Non è stato possibile completare la registrazione. Si prega di inserire un altro indirizzo e-mail o numero di cellulare.',
    NO_INTERNET_EXPLORER: 'Purtroppo Blink non supporta Internet Explorer. Si prega di utilizzare Microsoft Edge, Firefox o Chrome. Grazie per la comprensione.',
    loginNow: 'Registrati ora',
    help: 'Aiuto per la registrazione',
    helpText: `L'applicazione Blink Manager consente di gestire orari, personale e configurazione del sistema. Blink Manager è riservato ai supervisori, ai gestori di immobili, alla direzione e all'amministrazione! Per la pura registrazione degli orari, utilizzare la "Blink Time App"! <br/><br/>Ci sono due metodi per registrarsi:<br/><br/><b>Iscriversi con la carta d'identità Blink</b><br/><br/>Se si possiede una carta d'identità Blink, scegliere questo metodo. Alla prima registrazione vi verrà chiesto un indirizzo e-mail o un numero di cellulare. Questo ci serve per inviarvi una password unica per la registrazione. Non è necessario ricordare la password una tantum. Quando vi registrerete nuovamente, vi invieremo una nuova password unica.<br/><br/><b>Accesso con nome utente e password</b><br/><br/>Riceverete il nome utente e la password dall'amministratore di sistema solo su richiesta. Questo metodo è disponibile solo per gli utenti con diritti amministrativi.<br/><br/><b>Assistenza</b><br/><br/>Se avete problemi di accesso, scrivete un'e-mail all'indirizzo support@blink-time.de o chiamate il numero 0911 240 330 0.`,
    userOffline: 'Per utilizzare Blink me, collegarsi a Internet.',
    login: 'Registrati ora',
    codeError: "Controllare l'iscrizione e riprovare."
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Congratulazioni!',
    YOU_DID_IT: "L'avete fatto.",
    SIGNED_IN: 'Registrato',
    SIGNED_IN_TEXT: "La registrazione è avvenuta con successo e ora è possibile utilizzare l'applicazione.",
    START_USAGE: "Ora è possibile utilizzare l'app.",
    START: 'Inizio'
  },
  FORGOT_PASSWORD: {
    emailSent: "Vi è stata inviata un'e-mail con la quale potete modificare i vostri dati di accesso.",
    FORGOT_PASSWORD: 'Password dimenticata?',
    errors: {
      notAllowed: 'Non è possibile reimpostare la password per questo utente.',
      invalidToken: 'Il codice per reimpostare la password è scaduto. Si prega di richiedere un nuovo codice.'
    },
    reset: {
      backToRoot: 'Torna alla pagina di accesso',
      renewCode: 'Richiesta di un nuovo codice',
      resetPassword: 'Reimpostare la password',
      success: 'La password è stata reimpostata con successo.',
      password1: 'La nuova password',
      password2: 'Ripetere la password',
      validationError: 'La password non è conforme alle nostre linee guida sulle password!',
      passwordsNotEqual: 'Le password non sono identiche! Si prega di controllare la propria iscrizione.',
      passwordValidation: 'La password deve essere lunga almeno 6 caratteri e contenere una lettera, un numero e un carattere speciale.'
    },
    FORGOT_PASSWORD_TEXT: 'Inserisci il tuo indirizzo email e il sistema di destinazione per reimpostare la password.',
    FORGOT_PASSWORD_PLACEHOLDER: 'e-mail'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Controllare i dati',
    TEXT: 'Se dobbiamo reimpostare la password, abbiamo bisogno del vostro indirizzo e-mail e, facoltativamente, del vostro numero di cellulare.',
    EMAIL: 'Indirizzo e-mail',
    MOBILE: 'Numero di cellulare'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Inserire il codice',
    EMAIL_TEXT: 'Inserite il codice che vi abbiamo inviato al seguente indirizzo e-mail:',
    SMS_TEXT: 'Inserite il codice che vi abbiamo inviato al seguente numero di cellulare:',
    NO_CODE: 'Non ho ricevuto alcun codice.',
    CODE_LENGTH_ERROR: 'Il codice inserito è più lungo di sei caratteri. Immettere nuovamente il codice.',
    CODE_SENT: 'Il codice è stato inviato.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Nessun codice',
    TEXT1: 'Non hai ricevuto un codice?',
    RESEND_CODE: 'Inviare nuovamente il codice',
    TEXT2: "Se l'indirizzo e-mail o il numero di cellulare memorizzati nel sistema sono cambiati, contattare l'amministratore."
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: "Inserire l'ID e il codice Blink. Tutte le informazioni sono riportate sulla scheda di registrazione.",
    textCredentials: "Il nome utente e la password devono essere forniti dall'amministratore del sistema.",
    BLINK_ID_INVALID: 'ID lampeggiante difettoso',
    loginCardNotFound: 'Il Blink ID è già assegnato o non esiste.',
    WRONG_CREDENTIALS: "I dati inseriti non sono corretti. Si prega di controllare l'inserimento.",
    loginViaBlinkId: 'Registrazione con BlinkID',
    loginViaCredentials: 'Accesso con i dati di accesso',
    CODE: 'Codice',
    codePlaceholder: 'Il vostro codice di accesso personale',
    company: 'Sistema',
    password: 'Password',
    FORGOT_PASSWORD: 'Ho dimenticato la mia password',
    username: 'e-mail'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'Identificazione',
    TEXT_1: 'Per potervi identificare in modo univoco, vi invieremo un codice unico via SMS o e-mail.',
    TEXT_2: "A tal fine, inserire il numero di cellulare o l'indirizzo e-mail."
  },
  update: {
    update: 'Aggiornamento',
    latestVersion: 'È disponibile una nuova versione. Aggiornate la vostra applicazione per poterla sfruttare appieno.',
    warnVersion: 'Si sta utilizzando una versione obsoleta di questa applicazione. Si prega di aggiornarla il prima possibile.',
    blockVersion: 'Si sta utilizzando una versione obsoleta di questa applicazione che non è più supportata. Si prega di aggiornare ora.'
  },
  inProgress: 'In corso',
  video_offline: 'I video non possono essere visualizzati in modalità offline, connettiti a Internet.',
  location_offline: "L'oggetto non è salvato offline, connettiti a Internet per recuperare il codice QR.",
  invalidObjectCode: 'Nessun codice oggetto valido.',
  invalidValue: 'valore non valido',
  ERROR: {
    ERROR_400: "C'è stato un errore. Per favore riprova più tardi. (Codice errore: 400)",
    ERROR_403: "Non hai l'autorizzazione per eseguire questa azione",
    REFRESH_TOKEN_403: 'I tuoi dati di accesso non sono più validi. Per favore accedi di nuovo!',
    ERROR_500: "C'è stato un errore. Per favore riprova più tardi. (Codice errore: 500)"
  },
  list: {
    entityPluralDefault: 'Risultati',
    emptyState: 'Non esiste alcun {{entityPlural}}.',
    noResults: 'Impossibile trovare {{entityPlural}}.',
    selectAll: 'Seleziona tutto {{entityPlural}}',
    massAction: 'Promozione per {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Seleziona tutto {{count}}',
    massActionDeSelectAllGlobal: 'Deseleziona tutto {{count}}'
  },
  languages: {
    ar: 'Arabo',
    de: 'Tedesco',
    es: 'spagnolo',
    fr: 'francese',
    hr: 'croato',
    it: 'Italiano',
    pt: 'portoghese',
    ru: 'russo',
    bg: 'bulgaro',
    en: 'Inglese',
    fa: 'persiano',
    el: 'greco',
    hu: 'ungherese',
    pl: 'Polacco',
    ro: 'rumeno',
    tr: 'Turco',
    uk: 'ucraino',
    cs: 'ceco',
    sk: 'slovacco'
  },
  now: 'Ora',
  allCompanies: 'Tutti i clienti',
  createDate: 'Data di creazione',
  company: 'cliente',
  new: 'Nuovo',
  of: 'da',
  request: {
    deleteTitle: 'Elimina {{item}}',
    confirmDelete: "Vuoi veramente eliminare la voce '{{itemName}}'?",
    itemAdded: '{{item}} è stato creato.',
    itemAddedError: 'Impossibile creare {{item}}.',
    itemSaved: '{{item}} è stato salvato.',
    itemSavedError: 'Impossibile salvare {{item}}.',
    itemRemoved: '{{item}} è stato eliminato.',
    itemRemovedError: 'Impossibile eliminare {{item}}.'
  },
  news: { pageTitle: 'Notizia', edit: 'Modifica le notizie' },
  profiles: {
    loginProfiles: 'Profili di accesso',
    add: 'Profilo di accesso',
    logout: "Se esci, tutti i dati e i profili locali verranno eliminati! Vuoi annullare l'iscrizione?",
    login: 'Registrati',
    deleteConfirmation: 'Vuoi davvero eliminare il profilo?',
    changed: 'Profilo modificato con successo',
    deleted: 'Profilo eliminato con successo'
  },
  deleteAttachment: {
    confirmTitle: 'Elimina allegato',
    confirmText: 'Sei sicuro di voler eliminare questo allegato?',
    deleted: "L'allegato è stato eliminato"
  },
  comments: {
    addComment: 'Aggiungi un commento',
    addCommentInfo: 'Il commento è visibile a chiunque possa visualizzare questo ticket.',
    comment: 'commento',
    deleteComment: 'Elimina commento',
    deleteCommentConfirmation: 'Vuoi davvero eliminare il commento?',
    commentCreated: 'Il commento è stato salvato.',
    commentDeleted: 'Il commento è stato eliminato'
  },
  confirm: 'Confermare',
  firstName: 'Nome di battesimo',
  lastName: 'Cognome',
  status: 'stato',
  attachment: 'Allegato',
  timeFramePicker: {
    title: 'Seleziona un periodo',
    today: 'Oggi',
    currentWeek: 'Settimana corrente',
    currentMonth: 'Corrente mese',
    custom: 'Costume',
    startDate: 'inizio',
    endDate: 'FINE',
    lastWeek: 'La settimana scorsa',
    lastMonth: 'Il mese scorso'
  },
  cw: 'chilowatt',
  contextMenu: { openInNewTab: 'Apri il collegamento in una nuova scheda' }
};
