import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { standardColors } from '../../shared/standard-colors';
import { BlinkIcon } from '../../ui-icon';


@Component({
  selector: 'ui-input-select-color',
  templateUrl: './ui-input-select-color.component.html',
  styleUrls: ['./ui-input-select-color.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiInputSelectColorComponent),
    multi: true
  }]
})
export class UiInputSelectColorComponent implements ControlValueAccessor, OnInit {

  @Input() colors: string[] = standardColors;
  selectedColor: string;
  isMobile = false;
  protected readonly BlinkIcon = BlinkIcon;

  ngOnInit() {
    this.isMobile = Capacitor.isNativePlatform();
  }

  writeValue(color: string): void {
    this.selectedColor = color;
  }

  onSelect(color: string) {
    this.selectedColor = color;
    this.propagateChange(color);
  }

  propagateChange = (value: number | string) => undefined;

  propagateTouched = () => undefined;

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
}
