import { Component } from '@angular/core';
import { GlobalTranslateService } from '@blink/util';
import { ModalController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { BlinkIcon } from '@blink/ui';
import { FileShareService } from '../../file/file-share.service';


@Component({
  selector: 'blink-image-modal',
  templateUrl: './blink-image-modal.component.html',
  styleUrls: ['./blink-image-modal.component.scss']
})
export class BlinkImageModalComponent {
  protected readonly BlinkIcon = BlinkIcon;

  view: 'normal' | 'confirmDelete' = 'normal';

  src: string;
  deletable: boolean;
  fileName: string;
  viewerTitle: string;

  constructor(public t: GlobalTranslateService,
              private modalController: ModalController,
              private fileShareService: FileShareService) {
  }

  share() {
    const filename = this.fileName || 'img.jpg';
    const dataUrl = this.src;
    const contentType = dataUrl.substring(dataUrl.indexOf(":") + 1, dataUrl.indexOf(";"))
    const base64Data = dataUrl.split('base64,')[1];

    this.fileShareService.shareFile(filename, contentType, base64Data);
  }

  close() {
    this.modalController.dismiss();
  }

  delete() {
    this.modalController.dismiss(null, 'delete')
  }

  protected readonly Capacitor = Capacitor;
}
