<ion-item button class="checklist-item"
          lines="none"
          (click)="checklistClick.emit(checklist.Id)"
          style="--inner-padding-end: 0;">
  <div slot="start" class="icon-container" style="background-color:{{checklist.Color}}">
    <i slot="start" class="fal fa-{{checklist.Icon ? checklist.Icon : 'tasks'}} fa-lg"
       [style.color]="checklist.Color | contrastColor"></i>
  </div>

  <ui-translate-display property="Name"
                        [defaultText]="checklist.Name"
                        [translations]="checklist.ChecklistTranslations"
                        [currentLanguageId]="sessionRepo.selectedLanguageId$ | async"
                        [mainTextExtra]="checklist.Status === 'Inactive' ? '(' + (t.inactive | translate) + ')' : ''">
  </ui-translate-display>

  <!--  {{ checklist.Name }}-->
  <!--  <ng-container *ngIf="checklist.Status === 'Inactive'">-->
  <!--    ({{ t.inactive | translate }})-->
  <!--  </ng-container>-->

  <ng-container *ngIf="platform.width() > 501">

    <div class="ml-10" style="line-height: 0;">
      <ui-language-flag-stack [languageIds]="translationLanguageIds"></ui-language-flag-stack>
    </div>


    <ion-button *ngIf="showCopyPublicLinkButton" fill="outline" color="primary"
                class="ml-5" slot="end"
                [uiTooltip]="t.copyLink" uiTooltipFlow="left"
                (click)="$event.stopPropagation(); copyShortUrl();">
      <ui-icon slot="icon-only" [icon]="BlinkIcon.Link"></ui-icon>
    </ion-button>
    <ion-button *ngIf="showDuplicateButton" fill="outline" color="primary"
                class="ml-5" slot="end"
                [uiTooltip]="t.duplicate" uiTooltipFlow="left"
                (click)="$event.stopPropagation(); duplicateClick.emit(checklist.Id)">
      <ui-icon slot="icon-only" [icon]="BlinkIcon.Duplicate"></ui-icon>
    </ion-button>

    <ion-button *ngIf="showExportButton" fill="outline" color="primary"
                class="ml-5 mr-15" slot="end"
                [uiTooltip]="t.export" uiTooltipFlow="left"
                (click)="$event.stopPropagation(); exportClick.emit(checklist.Id)">
      <ui-icon slot="icon-only" [icon]="BlinkIcon.Export"></ui-icon>
    </ion-button>
  </ng-container>


  <ion-button *ngIf="showContextMenu()" slot="end" class="ml-0"
              fill="clear" (click)="$event.stopPropagation(); checklistDropdown($event, checklist.Id)">
    <i slot="icon-only" class="fal fa-ellipsis-v-alt"></i>
  </ion-button>

</ion-item>
