import { ApplicationInsightsService } from './application-insights.service';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor(private applicationInsights: ApplicationInsightsService) {
    super();
  }

  override handleError(error: Error) {
    if (environment.production) {
      this.applicationInsights.logException(error); // Manually log exception
      console.error(error);
    } else {
      throw error;
    }
  }
}
