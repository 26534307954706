import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { UiFileTransferProgress } from './file-transfer-progress';

@Component({
  selector: 'ui-dialog-file-transfer-progress',
  template: `
    <ui-page title="typedGlobal.filesTransferring"></ui-page>
    <div class="ion-padding" style="max-height: 500px; overflow: auto;" data-testid="fileTransferProgress">
      <p class="mt-0">
        {{ 'typedGlobal.filesTransferringWait'  | translate }}
      </p>
      <ion-row *ngFor="let vm of dismissProgress$ | async"
               class="ion-justify-content-between ion-align-items-center mt-10">
        <ion-col size="auto">
          {{vm.fileName}}
        </ion-col>
        <ion-col size="auto" class="ion-text-right" style="font-size: 12px;">
          {{(vm.loadedBytes / 1000).toFixed(2)}}/{{(vm.bytes / 1000).toFixed(2)}} KB
        </ion-col>
        <ion-col size="12">
          <ion-progress-bar
            [type]="vm.loadedBytes ? 'determinate' : 'indeterminate'"
            [value]="vm.loadedBytes / vm.bytes">
          </ion-progress-bar>
        </ion-col>
      </ion-row>
    </div>
  `
})
export class UiDialogFileTransferProgressComponent implements OnInit {
  progress$: Observable<UiFileTransferProgress[]>;
  dismissProgress$: Observable<UiFileTransferProgress[]>;

  constructor(private modalController: ModalController) {
  }

  ngOnInit(): void {
    this.dismissProgress$ = this.progress$.pipe(
      tap((x: UiFileTransferProgress[]) => {
        if (x.every(y => y.bytes > 0 && y.bytes === y.loadedBytes)) {
          this.modalController.dismiss();
        }
      })
    );
  }
}
