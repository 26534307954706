import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { BlinkIcon } from './blink-icon';

type FaIconSize =
  '2xs'
  | 'xs'
  | 'sm'
  | 'lg'
  | 'xl'
  | '2xl'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x';

@Component({
  selector: 'ui-icon',
  templateUrl: './ui-icon.component.html',
  styleUrls: ['./ui-icon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiIconComponent {
  @Input() icon: BlinkIcon | string;
  @Input() customIcon: string;
  @Input() size: FaIconSize = null;
  @Input() fixedWidth = false;
  @Input() solidIcon = false;
  @Input() badgeCount = 0;

  constructor() {
    window['uiComp'] = this;
  }

  _elementClass: string[] = [];
  _dataTestId: string;

  @Input('class')
  @HostBinding('class')
  get elementClass(): string {

    const faClasses = [this.solidIcon ? 'fas' : 'fal', this.icon || ('fa-' + this.customIcon)];

    if (this.fixedWidth && !this.badgeCount) {
      faClasses.push('fa-fw');
    }
    if (this.size) {
      faClasses.push(`fa-${this.size}`)
    }
    if (this.badgeCount) {
      faClasses.push('badge-width')
    }

    return this._elementClass.concat(faClasses).join(' ');
  }

  set elementClass(val: string) {
    this._elementClass = val.split(' ');
  }

  @Input('data-testid')
  @HostBinding('attr.data-testid')
  get dataTestId(): string {
    return this._dataTestId ?? 'icn_' + (this.icon || this.customIcon);
  }

  set dataTestId(val: string) {
    this._dataTestId = `icn_${val}`;
  }
}
