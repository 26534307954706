export default {
  home: {
    pageTitle: 'Domovská stránka',
    welcome: 'Vitajte v aplikácii Blink me. Bavte sa chatovaním,\n\nVáš Blink tím',
    startChat: 'začať',
    ticket: 'lístok',
    timeLicenseMissing: 'Nebola vám pridelená licencia Blink Time!'
  },
  conversations: { pageTitle: 'Správy', yourChats: 'Vaše rozhovory', chat: 'Chat' },
  settings: {
    settings: 'nastavenie',
    pushEnabled: 'Prijímať upozornenia push',
    system: 'systém',
    languageSelection: 'výber jazyka',
    currentSettings: 'Aktuálne nastavenia',
    user: 'užívateľ',
    appVersion: 'Verzia (aplikácia)',
    apiVersion: 'Verzia (API)',
    deviceInfo: 'Verzia zariadenia',
    buildVersion: 'verzia (zostava)',
    helpAndDataPolicy: 'Pomoc a ochrana údajov',
    text1: 'Náš tím podpory vám rád pomôže, ak máte nejaké otázky:',
    phone: 'telefón',
    mail: 'e-mail',
    information: 'Info',
    documentation: 'Online dokumentácia',
    documentationText: 'V našej online dokumentácii nájdete pokyny a videá pre všetky dôležité oblasti Blink.',
    dataPolicy: 'ochrana dát',
    support: 'podpora',
    moreActions: 'Ďalšie akcie',
    protocol: 'protokol',
    showChatUserSecret: 'Zdieľajte chat s iným zariadením',
    scanCodeAnotherDevice: 'Naskenujte nasledujúci QR kód na svojom novom zariadení alebo zadajte svoj osobný chatovací kód na novom zariadení!',
    yourPersonalCode: 'Váš osobný kód chatu',
    permissions: {
      title: 'Povolenia',
      grantPermission: 'udeliť povolenie',
      camera: 'fotoaparát',
      cameraHint: 'Nevyhnutné pre záznam času cez objektový kód.',
      nfc: 'NFC',
      nfcHint: 'Nevyhnutné pre záznam času cez NFC tag.',
      media: 'Vezmite obrázok a obrázok z knižnice',
      mediaHint: 'Nevyhnutné na zachytenie obrázkov a dokumentov súvisiacich s vašimi časmi a lístkami.',
      location: 'Poloha',
      locationHint: 'Potrebné zaznamenať vašu polohu v čase nahrávania času.',
      locationDisabledOnSystem: 'Lokalizačné služby sú vypnuté. Aktivujte si to v nastaveniach svojho zariadenia.',
      notifications: 'Push notifikácie',
      notificationsHint: 'Nevyhnutné na prijímanie upozornení push na správy z chatu, upozornenia na blikanie a nálepky.'
    }
  },
  connectionState: {
    connecting: 'Pripojiť...',
    connected: 'Zviazané.',
    disconnecting: 'Spojenie je odpojené...',
    disconnected: 'prerušené spojenie',
    denied: 'Spojenie sa nepodarilo nadviazať.'
  },
  noOneToFind: 'Žiadna sa nenašla.',
  nothingToFind: 'Nič sa nedalo nájsť.',
  noLocationFound: 'Nenašli sa žiadne objekty.',
  searchPlaceholder: 'Hľadaj...',
  employeeSearchPlaceholder: 'Meno krstné Meno',
  typeToSearch: 'Klepnutím spustíte vyhľadávanie...',
  typeToSearchEmployee: 'Ak chcete začať rozhovor, zadajte meno osoby',
  createChatWithEmployee: 'Hľadáme zamestnancov',
  createChatWithLocationTeam: 'Hľadaný objekt',
  createTeamleaderChat: 'Všetci moji zamestnanci',
  yourLanguageSelection: 'Váš výber jazyka:',
  loginInfo: 'Ak sa chcete zaregistrovať, naskenujte svoj osobný QR kód:',
  updating: 'Blink me sa aktualizuje.',
  notice: 'Všimnite si',
  importantNotice: 'Dôležitá poznámka',
  continue: 'Ďalej',
  intro: {
    slide1: { header: 'Vitajte', text: 'Bavte sa s aplikáciou Blink me.' },
    slide2: {
      header: 'Chat',
      text: 'Chatujte priamo so svojimi kolegami alebo zistite najnovšie správy zo zaujímavých skupín.'
    },
    slide3: {
      header: 'Vstupenky',
      text: 'Tu môžete vidieť svoje úlohy a vytvárať aplikácie.'
    }
  },
  pleaseWait: 'Prosím čakajte...',
  skip: 'Preskočiť',
  startAgain: 'Začať odznova',
  lock: {
    createPinHeader: 'Váš osobný PIN',
    createPinInfo: 'Na ochranu svojich údajov si tu môžete nastaviť PIN, ktorý musíte zadať pri každom použití aplikácie Blink me.',
    validatePin: 'Zadajte PIN znova.',
    tryAgain: 'PIN sa nezhoduje. Skúste to znova.',
    triedToOften: 'Váš PIN sa bohužiaľ nezhoduje. Chcete proces reštartovať alebo ho preskočiť a urobiť to neskôr?',
    enterPinHeader: 'Zadanie PIN',
    enterPinInfo: 'Na odomknutie aplikácie zadajte svoj PIN.',
    remainingTrys: 'Ďalšie pokusy: {{amount}}. Potom sa musíte znova prihlásiť do aplikácie.',
    tooManyEnters: 'Žiaľ, registrácia pomocou PIN už nie je možná. Prihláste sa znova pomocou svojej Blink ID karty.',
    reSignIn: 'Nová registrácia',
    setpin: 'zadanie PIN kódu pri používaní aplikácie',
    skip: 'Nechcete teraz prideliť PIN? Túto funkciu môžete kedykoľvek aktivovať prostredníctvom nastavení aplikácie, a to aj neskôr.',
    dontUsePin: 'nepoužívajte PIN',
    deactivatePinHeader: 'Zakázať PIN',
    deactivatePinInfo: 'Na deaktiváciu ochrany zadajte svoj PIN.'
  },
  active: {
    yourTickets: 'Vaše lístky',
    createNewTask: 'Vytvorte nový lístok',
    assignee: 'editor',
    location: 'objekt',
    dueDate: 'Splatný',
    createTask: 'Vytvorte lístok',
    taskCreated: 'Lístok bol vytvorený.',
    backToTasks: 'Späť k lístkom',
    createdAt: 'vytvorené na',
    attachments: 'Prílohy',
    addFileOrImage: 'Pridajte súbor alebo fotografiu',
    addImage: 'pridaj fotku',
    comments: 'Komentáre',
    addComment: 'pridať komentár',
    addCommentInfo: 'Komentár je viditeľný pre každého, kto môže zobraziť tento tiket.',
    commentCreated: 'Komentár bol uložený.',
    showClosed: 'Pozrite si hotové lístky',
    resetFilter: 'resetujte všetky filtre',
    changeTicketStatusTo: 'Zmeniť stav lístka na:',
    editTask: 'Upraviť lístok',
    saveTask: 'Uložiť lístok',
    taskSaved: 'Lístok bol uložený.',
    deleteImage: 'Jasný obraz',
    deleteImageConfirmation: 'Naozaj chcete odstrániť obrázok?',
    deleteAttachment: 'Odstrániť prílohu',
    deleteAttachmentConfirmation: 'Naozaj chcete odstrániť túto prílohu?',
    deleteComment: 'Odstrániť komentár',
    deleteCommentConfirmation: 'Naozaj chcete odstrániť komentár?',
    changeAssignee: 'Zmeniť editor',
    duplicateTask: 'Duplicitný lístok',
    duplicateTaskConfirmation: 'Chcete duplikovať tento lístok?',
    taskDuplicated: 'Lístok bol duplikovaný.',
    deleteTask: 'Odstrániť lístok',
    deleteTaskConfirmation: 'Naozaj chcete odstrániť tento lístok?',
    taskDeleted: 'Lístok bol vymazaný.',
    assigneeChanged: 'Procesor bol zmenený.',
    statusChanged: 'Stav lístka bol zmenený.',
    startDate: 'začať',
    endDate: 'Koniec',
    doctorVisitDate: 'Dátum návštevy lekára',
    doctorVisitDescription: 'Dátum návštevy lekára: {{date}}',
    chooseDate: 'Vyberte dátum',
    comment: 'Komentár (voliteľné)',
    dateError: 'Dátum ukončenia nemôže byť skorší ako dátum začiatku',
    employee: 'zamestnancov',
    pleaseConnectToLoad: 'Ak chcete načítať vstupenky, vytvorte si internetové pripojenie.'
  },
  emptyState: {
    chats: 'Vytvorte čet!',
    chat: 'Napíšte prvú správu :)',
    tasks: 'Nenašli sa žiadne lístky.',
    attachments: 'Nie sú k dispozícii žiadne prílohy.',
    comments: 'Nie sú k dispozícii žiadne komentáre.',
    vacation: 'Na tento rok nebola zadaná žiadna dovolenka'
  },
  formError: {
    min: 'Hodnota musí byť väčšia ako {{value}}.',
    min2: 'Hodnota musí byť väčšia alebo rovná {{value}}.',
    max: 'Hodnota musí byť menšia alebo rovná {{value}}.',
    requiredBecause: 'Hodnota je povinná, pretože {{name}} je vyplnené.',
    isRequired: '{{name}} je povinné.',
    numberInUse: 'Osobné číslo sa už používa.',
    maxlength: '{{name}} nemôže obsahovať viac ako {{value}} znakov.',
    faultyBlinkId: 'Nesprávne ID blikania'
  },
  ngSelect: {
    typeToSeach: 'Zadajte 3 alebo viac písmen.',
    loading: 'načítavam dáta...',
    notFound: 'Nenašli sa žiadne {{title}}.',
    clearAll: 'Obnoviť pôvodné nastavenia'
  },
  placeholder: {
    employee: 'Vyhľadávanie podľa mena a priezviska, osobného čísla, Blink ID',
    location: 'Vyhľadávajte podľa názvu, čísla alebo kódov objektov',
    date: 'Vyberte dátum',
    searchTasks: 'Hľadať lístky...'
  },
  hasChat: 'Začnite četovať teraz',
  hasNoChatYet: 'Žiaľ, ešte som si nenainštaloval aplikáciu Blink me.',
  noBlinkActiveLicence: 'Žiadna licencia',
  noBlinkActiveLicenceInfo: 'V súčasnosti nemáte licenciu na túto aplikáciu. Kontaktujte svojho nadriadeného.',
  noBlinkMeLicence: 'Žiadna licencia',
  noBlinkMeLicenceInfo: 'Túto aplikáciu nie je možné použiť s vaším systémom. Použite čas žmurkania z aplikácie alebo obchodu Play.',
  noSuitableAppFound: 'Nenašla sa žiadna aplikácia na otvorenie súboru.',
  myChats: 'Vaše rozhovory',
  hasNoChatsHome: 'Začnite četovať teraz! Môžete sa porozprávať so všetkými kolegami a zúčastniť sa objektových a tímových skupín.',
  hasNoTicketsHome: 'Nie sú k dispozícii žiadne otvorené vstupenky.',
  myTickets: 'Vaše lístky',
  myEmployees: 'Moji zamestnanci',
  group: 'skupina',
  confirmLogoutWithChat: 'Ak sa odhlásite, vaše chatové správy budú vymazané. Chcete pokračovať?',
  confirmLogout: 'Naozaj sa chcete odhlásiť?',
  message: 'Správy',
  messageNotDecryptable: 'Správu sa nepodarilo dešifrovať.',
  chat: {
    userAlreadyExists: 'Už ste si nastavili čet na inom zariadení. Ak chcete na tomto zariadení používať čet, musíte zariadenia prepojiť. Prípadne môžete reštartovať nastavenie, ale potom už nebude možné chat používať na druhom zariadení.',
    linkDevices: 'Prepojte zariadenia',
    withoutChat: 'Pokračujte bez rozhovoru',
    setupAgain: 'Obnoviť čet',
    setupAgainText: 'Ak chat znova nastavíte, všetky správy z existujúcich individuálnych chatov sa vymažú a chat už nebude možné používať na iných zariadeniach! Chcete pokračovať?',
    LinkDevicesText: 'Ak chcete prepojiť zariadenia na četovanie, otvorte Nastavenia na druhom zariadení a vyberte „Zdieľať čet s iným zariadením“.',
    personalToken: 'Váš osobný kód chatu',
    wrongCode: 'Poskytnutý četový kód je nesprávny.',
    beingPrepared: 'Chat sa pripravuje.',
    twilioAccessDenied: 'Nepodarilo sa vytvoriť pripojenie k chatovej službe. Kontaktujte podporu Blink.',
    notificationsDenied: 'Upozornenia sú na vašom zariadení/prehliadači zakázané. Prejdite do Nastavenia a povoľte upozornenia pre Blink me, aby ste dostávali upozornenia. Upozornenia si potom môžete aktivovať vo svojich osobných nastaveniach.',
    deleted: 'Chat bol odstránený',
    unavailable: 'Chat nie je k dispozícii',
    participant: 'Účastník',
    teamChatDescription: 'Všetci zamestnanci, pre ktorých ste registrovaný ako manažér, sú pridaní do tejto chatovej skupiny.',
    locationChatDescription: 'Zamestnanci sa automaticky pridajú, ak majú plán pre tento objekt na posledné štyri týždne alebo nasledujúce štyri týždne.',
    createEmployeeChatDescription: 'S vybraným zamestnancom sa vytvorí súkromný chat.',
    teamChatCreationTitle: 'Vytvorte chatovú skupinu',
    createTeamChatDescription: 'Vytvorí sa chatová skupina, do ktorej sa automaticky pridajú všetci zamestnanci, pre ktorých ste registrovaný ako manažér.',
    createLocationChatDescription: 'Pre vybraný objekt sa vytvorí četovacia skupina, do ktorej sa automaticky pridajú zamestnanci, ak na to majú plán na posledné štyri týždne alebo na ďalšie štyri týždne.'
  },
  protocol: {
    SEND_EMAIL: 'Poslať e-mailom',
    DELETE: 'Vymazať denník',
    deleteMessage: 'Naozaj chcete odstrániť denník? Túto akciu nie je možné vrátiť späť.',
    Send: 'Odoslať',
    SuccessMessage: 'Protokol bol úspešne odoslaný do Blink Support.',
    ConfirmMessage: 'Najprv kontaktujte tím podpory a odošlite denník iba na požiadanie.',
    Description: 'popis problému'
  },
  timeTracking: {
    title: 'Sledovanie času',
    start: 'Štart',
    startDescription: 'Kliknutím na štart spustíte sledovanie času',
    started: 'Začaté',
    stop: 'Prestať',
    duration: 'Dĺžka času',
    syncButtonLabel: 'Časy nahrávania zaznamenané offline',
    offlineSyncMessage: 'Ak chcete preniesť uložené časy, musíte byť online.',
    successfulSync: 'Časy sú nahrané',
    scanNowQrCode: 'QR kód',
    scanNowQrCodeDescription: 'Naskenujte QR kód v objekte.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Pridržte svoj smartfón oproti nálepke NFC v objekte.',
    nfcDescription: 'Detekcia cez NFC: Podržte svoj mobilný telefón proti kódu objektu',
    noTimeTrackingMethodAvailableDescription: 'Pre tohto používateľa nie je nakonfigurovaná žiadna metóda sledovania času. Kontaktujte svojho vedúceho tímu.',
    noLocationCode: 'Nie je k dispozícii žiadny kód',
    noLocationCodeDescription: 'Túto možnosť vyberte, ak sa v objekte nenachádza žiadny QR kód ani nálepka NFC. Poskytnutie komentára je povinné.',
    noLocationCodeConfirm: 'Naozaj chcete začať bez objektového kódu? Ak chcete zastaviť, musíte zadať komentár.',
    wrongCode: 'Naskenovaný QR kód nie je objektový kód!',
    readNotice: 'Prečítajte si prosím pokyny!',
    warning: 'POZOR',
    lessThanAMinuteTitle: 'Kompletné zaznamenávanie času',
    lessThanAMinute: 'Začali ste pred menej ako 1 minútou. Naozaj chcete prestať?',
    continue: 'Pokračujte v nahrávaní',
    timeAlert: {
      differenceInMinutesHint: 'Čas na vašom smartfóne sa líši od času na serveri. Rozdiel je zaznamenaný',
      differentTimeZoneHint: 'Časové pásmo vášho smartfónu sa líši od časového pásma servera. Rozdiel je zaznamenaný.'
    },
    leaveComment: {
      withCode: 'Informácie o objekte/službe neboli jasné. Povedzte nám, v ktorej nehnuteľnosti/službe ste pracovali.',
      withoutCode: 'Pri spustení alebo zastavení nebol zachytený žiadny kód objektu. Vysvetlite, prečo ste začali/zastavili bez predmetu.'
    },
    comment: 'komentovať',
    enterComment: 'Komentujte sledovanie času',
    differentQrCode: {
      title: 'Zmeniť objekt?',
      continueInLocation: 'Pokračujte v práci v {{locationName}}.',
      automaticStopInLocation: 'Nahrávanie času v {{locationName}} sa automaticky zastaví.',
      stopInLocation: 'Zastaviť zaznamenávanie času v {{locationName}}.',
      stop: 'Prestať',
      stopNoLocation: 'Zastavte zaznamenávanie času.',
      continueNoOldLocation: 'Nahrávanie času pre predchádzajúci objekt sa automaticky zastaví.',
      continueWorking: 'Pracuj ďalej',
      continueWorkingInNewLocation: 'Pokračujte v práci v novej nehnuteľnosti.',
      stopWithLocationActivity: 'Zastaviť zaznamenávanie času na {{locationActivity}}.',
      continueWithNewLocationActivity: 'Pokračovať v práci s {{locationActivity}}',
      stopOldLocationActivity: 'Nahrávanie času na {{locationActivity}} sa automaticky zastaví.',
      stopOldNoLocationActivity: 'Nahrávanie času bez výkonu sa automaticky zastaví.',
      continueNoLocationActivity: 'Pokračujte v práci bez výkonu.',
      sameLocationTitle: 'Zmeniť výkon?',
      differentLocationTitle: 'Zmeniť objekt?'
    },
    saveAndStop: 'Uložiť a zastaviť',
    noLocationScanned: '- Žiadny predmet -',
    noLocationFound: '- Objekt nie je jasný -',
    timerDifference: '{{h}} hodín {{mm}} minút',
    stopSuccess: 'Čas bol úspešne zaznamenaný',
    stopSuccessToast: 'Nahrávanie času sa úspešne zastavilo.',
    stopTimeTracking: 'Prestať',
    manual: {
      time: 'čas',
      locationSelect: 'Vyberte objekt',
      loadMore: 'načítať viac',
      trackTime: 'Čas záznamu',
      location: 'objekt',
      locationActivity: 'Výkon',
      date: 'Dátum',
      startTime: 'Od',
      endTime: 'Až kým',
      create: 'Potvrďte',
      createAgain: 'Zaznamenajte dodatočný čas pre tento objekt',
      errorUntil: 'Čas od času musí byť menší ako čas od času',
      worktimeDifference: 'Pracovný čas:',
      worktimeDifferenceValue: '{{hours}} hodín {{minutes}} minút',
      worktimeDifferenceValueMinutes: '{{minutes}} minút',
      success: 'Čas bol úspešne zaznamenaný',
      break: 'Prestávka:',
      breakMinutes: '{{minutes}} minút',
      breakHoursAndMinutes: '{{hours}} hodín {{minutes}} minút',
      noActivity: '-žiadny výkon-',
      locationPlaceHolder: 'Vyhľadávajte podľa názvu, čísla alebo kódov objektov',
      conflictError: 'V určených obdobiach už prebieha záznam času. Presahy nie sú povolené. Skontrolujte svoje údaje.',
      currentDayDuration: 'Už zaznamenané',
      expectedDuration: 'Predpokladaný pracovný čas',
      expectedDurationIncludedCurrent: 'vrátane aktuálneho vstupu'
    },
    differenceInMinutesHint: 'Čas na vašom smartfóne sa líši od času na serveri. Rozdiel je zaznamenaný',
    differentTimeZoneHint: 'Časové pásmo vášho smartfónu sa líši od časového pásma servera. Rozdiel je zaznamenaný.',
    codeNotAssignable: 'Naskenovaný QR kód nie je možné priradiť!',
    proposed: {
      capture: 'Zachytiť',
      time: 'čas',
      captureTime: 'Čas záznamu',
      button: 'Potvrďte',
      title: 'Potvrďte pracovný čas',
      includingBreak: '(vrátane prestávky)',
      from: 'Od',
      to: 'Až kým',
      break: 'Prestávka',
      hour: 'Hodiny',
      confirm: {
        description: 'Potvrďte svoju pracovnú dobu',
        confirm: 'Áno, to je správne',
        editButton: 'Nie, to nie je správne'
      },
      edit: {
        description: 'Uveďte prosím trvanie',
        breakIncluded: 'Vrátane prestávky {{ duration }}',
        comment: 'komentovať',
        yourComment: 'tvoj komentár',
        minutes: 'minút',
        hours: 'hodiny'
      },
      reasons: {
        overtime: 'Nadčasy kvôli zákazníkovi',
        faster: 'Hotovo rýchlejšie',
        other: 'Zmiešaný',
        representative: 'Bol som náhradník'
      },
      description: 'Potvrďte svoju pracovnú dobu hneď, ako skončíte prácu v nehnuteľnosti.'
    },
    running: 'Nahrávanie času beží'
  },
  timeSheet: {
    title: 'Časy a plánovanie',
    noPlannings: 'Žiadne plánovanie',
    planned: 'Plánované',
    workingTime: 'pracovný čas',
    workloadDuration: 'Týždenný pracovný čas',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Mal by',
    workedDuration: 'Je',
    hasWorkload: 'Pracujte v ten deň',
    pleaseConnectToLoad: 'Ak chcete načítať svoje časy, vytvorte si internetové pripojenie.',
    footer: {
      planned: 'Plánované',
      worklogsTotal: 'Celkový počet hodín',
      workloadTotal: 'Hodiny podľa zmluvy',
      difference: 'rozdiel'
    },
    homepageTitle: 'Váš pracovný deň'
  },
  planning: {
    modifyMinutes: 'Paušálna sadzba:',
    planRoute: 'Naplánovať trasu',
    title: 'plánovanie',
    includingPause: 'Prestávka:',
    noNavAppFound: 'Nenašla sa žiadna navigačná aplikácia'
  },
  worklog: {
    title: 'Zaznamenaný čas',
    absenceTitle: 'neprítomnosť',
    workingTime: 'pracovný čas',
    breakDuration: 'Prestávka',
    reduce: 'paušálne',
    attachments: 'Prílohy',
    comments: 'Komentáre',
    comment: 'komentovať',
    noLocation: 'Nie je zadaný žiadny objekt',
    changedBy: 'Čas sa zmenil z {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'Naozaj chcete odstrániť túto prílohu?',
    attachmentDeleted: 'Príloha bola odstránená',
    confirmCommentDelete: 'Naozaj chcete odstrániť tento komentár?',
    commentDeleted: 'Komentár bol odstránený'
  },
  tabs: {
    home: 'Domov',
    times: 'krát',
    chat: 'Chat',
    tickets: 'Vstupenky',
    more: 'viac'
  },
  profile: {
    title: 'profilu',
    testing: 'Testovanie',
    testingDescription: 'Táto oblasť je viditeľná iba pri pripojení k testovaciemu systému.',
    testingAllowQrCodeOnWeb: 'Povoliť manuálne zadanie QR kódu',
    pushNotifications: 'Push notifikácie',
    reloadUserContext: 'Znova načítať UserContext',
    pushNotificationSuccessful: 'Push notifikácia bola úspešne prijatá',
    editProfilePicture: 'zmeniť profilový obrázok',
    editProfilePictureTitle: 'Vyberte sekciu',
    pushNotificationNotice: 'Na test je potrebná autorizácia „Upraviť nastavenia klienta“.',
    send: 'Odoslať',
    profilePicture: 'Profilový obrázok'
  },
  help: { title: 'Pomoc' },
  myVacations: {
    vacation: 'Dovolenka',
    amountAvailableVacationDays: '{{amount}} dní dovolenky je stále k dispozícii',
    totalVacationDays: 'Nárok na dovolenku {{ total }} dní',
    remainingVacationDays: '({{ total }} dní {{ sign }} {{ remaining }} dní zostávajúcej dovolenky {{ previousYear }})',
    xDaysVacations: '{{ total }} dní dovolenky',
    title: 'Vaša dovolenka'
  },
  externalLinks: {
    title: 'vonkajšie odkazy',
    notice: 'Tento odkaz poskytla vaša spoločnosť. Opúšťate systém Blink.'
  },
  stickers: {
    title: 'Vaše nálepky',
    sticker: 'Nálepky',
    sender: 'Príjemca',
    text: 'Čo sa týka',
    date: 'Dátum',
    emptyState: 'Bohužiaľ ešte nemáte žiadne nálepky',
    senderDescription: 'Túto nálepku vám odoslal {{sender}} dňa {{date}}',
    readed: 'čítať',
    notReaded: 'ešte som to nečítal',
    newStickerToaster: 'Nová blikajúca nálepka!',
    open: 'OTVORENÉ',
    next: 'Ďalej',
    titleCases: {
      case1: 'Gratulujeme k vašej prvej nálepke!',
      case2: 'Skvelé, teraz už máte nálepky <b>{{count}}</b>!',
      case3: 'Gratulujeme k 10. nálepke!',
      case4: '30 nálepiek - wow!',
      case5: 'Teraz to tu začína byť preplnené: nálepky <b>{{count}}</b>!'
    },
    confirmDelete: 'Naozaj chcete odstrániť tieto nálepky?'
  },
  autoTranslate: {
    isTranslated: 'Automaticky preložené',
    translationError: 'Automatický preklad momentálne nie je možný'
  },
  migration: {
    wrongVersion: 'V aktuálnej verzii Blink me nie je podporované prihlásenie cez aplikáciu Blink Time. Aktualizujte aplikáciu Blink me na najnovšiu verziu'
  },
  eLearning: {
    pendingTab: 'Úloha ({{value}})',
    completedTab: 'Hotovo ({{value}})',
    completed: 'Dokončené',
    pending: 'OTVORENÉ',
    dueFalling: 'Po splatnosti',
    title: 'E-learning',
    course: 'kurz',
    dueDate: 'Platný do',
    topics: 'predmetov',
    completeDate: 'Dokončené dňa',
    status: { Pending: 'OTVORENÉ', Done: 'dokončené' },
    infos: {
      pendingExists: 'Všetky svoje otvorené školenia nájdete tu.',
      finishOverdue: 'Prosím, urobte školenie, ktoré je už naplánované, čo najskôr.',
      allCompleted: 'Výborne! Úspešne ste absolvovali všetky svoje vzdelávacie kurzy.',
      emptyState: 'Nemáte pridelené žiadne školenie.'
    }
  },
  checklists: {
    title: 'Formuláre',
    description: 'Vyplňte formulár pre objekt',
    button: 'Vyberte objekt'
  },
  pendingTab: 'Vykonanie ({{value}})',
  completedTab: 'Dokončené ({{value}})',
  workOrders: {
    title: 'objednávky',
    homepageTitle: 'Vaše objednávky',
    responsible: 'Zodpovedný',
    customerContact: 'Kontakt na zákazníka',
    trackedTime: 'Záznam času pre objednávku č. {{value}}',
    show: 'Zobraziť objednávku',
    trackInfo: {
      confirmAndAcknowledge: 'Po implementácii potvrďte pozície a vykonajte akceptačný test na mieste.',
      acknowledgeOnly: 'Vykonajte kontrolu na mieste.',
      confirmOnly: 'Po realizácii potvrďte pozície.'
    },
    pleaseConnectToLoad: 'Ak chcete načítať svoje objednávky, vytvorte si internetové pripojenie.',
    status: { new: 'Nové', inProgress: 'Poprava', completed: 'Dokončené' },
    emptyState: 'Nie sú k dispozícii žiadne otvorené objednávky.',
    emptyStateCompleted: 'Nie sú k dispozícii žiadne dokončené objednávky.',
    details: {
      title: 'Podrobnosti objednávky',
      positionsTab: 'Pozície ({{value}})',
      acknowledgementsTab: 'Zníženia ({{value}})'
    },
    acknowledgement: {
      title: 'prijatie',
      start: 'Začnite prijímať',
      acknowledgedAtDate: 'Odstránené dňa',
      itemSelectDescription: 'Vyberte všetky pozície, ktoré chcete odstrániť.',
      customerInformationDescription: 'Uveďte prosím kontaktné údaje zákazníka (kupujúceho). Po prijatí bude na tento email zaslané potvrdenie.',
      created: 'Prijatie bolo úspešne uložené',
      selectAll: 'Vybrať všetko',
      emptyState: 'Neexistujú žiadne prijatia.'
    },
    items: {
      items: 'pozície',
      acknowledge: 'Potvrďte polohu',
      confirmAcknowledge: 'Týmto potvrdzujete, že práca bola vykonaná. Stav pozície sa aktualizuje na „Dokončené“.',
      status: {
        open: 'OTVORENÉ',
        planned: 'Plánované',
        performed: 'Hotovo',
        acknowledged: 'Odstránené',
        billed: 'Fakturované'
      },
      emptyState: 'Nie sú k dispozícii žiadne pozície.'
    },
    trackTime: {
      description: 'Vyberte úlohu, pre ktorú chcete sledovať čas.',
      chooseWorkOrder: 'Vyberte poradie',
      choosePosition: 'Vyberte polohu'
    }
  }
};
