export default {
  home: {
    pageTitle: 'Домашняя страница',
    welcome: 'Добро пожаловать в приложение Blink me. Приятного общения,\n' +
      '\n' +
      'Ваша команда Блинк',
    startChat: 'начинать',
    ticket: 'билет',
    timeLicenseMissing: 'Вам не присвоена лицензия Blink Time!'
  },
  conversations: { pageTitle: 'Новости', yourChats: 'Ваши чаты', chat: 'Чат' },
  settings: {
    settings: 'Настройки',
    pushEnabled: 'Получайте push-уведомления',
    system: 'система',
    languageSelection: 'Выбор языка',
    currentSettings: 'Текущие настройки',
    user: 'пользователь',
    appVersion: 'Версия (приложение)',
    apiVersion: 'Версия (API)',
    deviceInfo: 'Версия устройства',
    buildVersion: 'версия (сборка)',
    helpAndDataPolicy: 'Помощь и защита данных',
    text1: 'Наша служба поддержки будет рада помочь вам, если у вас возникнут вопросы:',
    phone: 'телефон',
    mail: 'электронная почта',
    information: 'Информация',
    documentation: 'Онлайн-документация',
    documentationText: 'В нашей онлайн-документации вы найдете инструкции и видеоролики по всем важным областям Blink.',
    dataPolicy: 'защита данных',
    support: 'Поддерживать',
    moreActions: 'Дальнейшие действия',
    protocol: 'протокол',
    showChatUserSecret: 'Поделиться чатом с другим устройством',
    scanCodeAnotherDevice: 'Отсканируйте следующий QR-код на своем новом устройстве или введите свой личный код чата на новом устройстве!',
    yourPersonalCode: 'Ваш личный код чата',
    permissions: {
      title: 'Разрешения',
      grantPermission: 'дать разрешение',
      camera: 'камера',
      cameraHint: 'Необходимо для записи времени через объектный код.',
      nfc: 'НФК',
      nfcHint: 'Необходимо для записи времени по NFC-метке.',
      media: 'Возьмите изображение и изображение из библиотеки',
      mediaHint: 'Необходимо для захвата изображений и документов, связанных с вашим временем и билетами.',
      location: 'Расположение',
      locationHint: 'Необходимо зафиксировать ваше местоположение в момент записи времени.',
      notifications: 'Всплывающее уведомление',
      notificationsHint: 'Необходимо для получения push-уведомлений о сообщениях чата, мигающих уведомлений и стикеров.',
      locationDisabledOnSystem: 'Службы определения местоположения отключены. Пожалуйста, активируйте это в настройках вашего устройства.'
    }
  },
  connectionState: {
    connecting: 'Соединять...',
    connected: 'Связаны вместе.',
    disconnecting: 'Соединение разорвано...',
    disconnected: 'соединение потеряно',
    denied: 'Соединение не может быть установлено.'
  },
  noOneToFind: 'Ничего не найдено.',
  nothingToFind: 'Ничего не найти.',
  noLocationFound: 'Объекты не найдены.',
  searchPlaceholder: 'Искать...',
  employeeSearchPlaceholder: 'Фамилия, имя',
  typeToSearch: 'Нажмите, чтобы найти...',
  typeToSearchEmployee: 'Введите имя человека, чтобы начать чат',
  createChatWithEmployee: 'Ищем сотрудников',
  createChatWithLocationTeam: 'Поиск объекта',
  createTeamleaderChat: 'Все мои сотрудники',
  yourLanguageSelection: 'Ваш выбор языка:',
  loginInfo: 'Для регистрации отсканируйте свой личный QR-код:',
  updating: 'Blink me обновляется.',
  notice: 'Уведомление',
  importantNotice: 'Важное замечание',
  continue: 'Дальше',
  intro: {
    slide1: {
      header: 'Добро пожаловать',
      text: 'Получайте удовольствие от использования приложения Blink me.'
    },
    slide2: {
      header: 'Чат',
      text: 'Общайтесь напрямую с коллегами или узнавайте последние новости интересных групп.'
    },
    slide3: {
      header: 'Билеты',
      text: 'Здесь вы можете видеть свои задачи и создавать заявки.'
    }
  },
  pleaseWait: 'Пожалуйста, подождите...',
  skip: 'Пропускать',
  startAgain: 'Начать заново',
  lock: {
    createPinHeader: 'Ваш личный ПИН-код',
    createPinInfo: 'Чтобы защитить ваши данные, вы можете установить здесь PIN-код, который вы должны будете вводить каждый раз, когда используете приложение Blink me.',
    validatePin: 'Пожалуйста, введите PIN-код еще раз.',
    tryAgain: 'ПИН-код не совпадает. Пожалуйста, попробуйте еще раз.',
    triedToOften: 'К сожалению, ваш PIN-код не совпадает. Хотите перезапустить процесс или пропустить его и сделать позже?',
    enterPinHeader: 'Запись PIN-кода',
    enterPinInfo: 'Пожалуйста, введите свой PIN-код, чтобы разблокировать приложение.',
    remainingTrys: 'Еще {{amount}} попыток. Затем вам придется снова войти в приложение.',
    tooManyEnters: 'К сожалению, регистрация с помощью ПИН-кода больше невозможна. Пожалуйста, войдите снова, используя свою карту Blink ID.',
    reSignIn: 'Новая регистрация',
    setpin: 'Ввод PIN-кода при использовании приложения',
    skip: 'Разве вы не хотите назначить PIN-код сейчас? Вы можете активировать эту функцию в любой момент через настройки приложения, в том числе и позже.',
    dontUsePin: 'не используйте PIN-код',
    deactivatePinHeader: 'Отключить ПИН-код',
    deactivatePinInfo: 'Пожалуйста, введите свой PIN-код, чтобы отключить защиту.'
  },
  active: {
    yourTickets: 'Ваши билеты',
    createNewTask: 'Создать новый билет',
    assignee: 'редактор',
    location: 'объект',
    dueDate: 'Подлежит исполнению в',
    createTask: 'Создать билет',
    taskCreated: 'Билет создан.',
    backToTasks: 'Вернуться к билетам',
    createdAt: 'создано на',
    attachments: 'Вложения',
    addFileOrImage: 'Добавить файл или фотографию',
    addImage: 'добавить фото',
    comments: 'Комментарии',
    addComment: 'добавить комментарий',
    addCommentInfo: 'Комментарий виден всем, кто может просмотреть этот билет.',
    commentCreated: 'Комментарий сохранен.',
    showClosed: 'Посмотреть готовые билеты',
    resetFilter: 'сбросить все фильтры',
    changeTicketStatusTo: 'Измените статус заявки на:',
    editTask: 'Редактировать билет',
    saveTask: 'Сохранить билет',
    taskSaved: 'Билет сохранен.',
    deleteImage: 'Очистить изображение',
    deleteImageConfirmation: 'Вы действительно хотите удалить изображение?',
    deleteAttachment: 'Удалить вложение',
    deleteAttachmentConfirmation: 'Вы уверены, что хотите удалить это вложение?',
    deleteComment: 'Удалить комментарий',
    deleteCommentConfirmation: 'Вы действительно хотите удалить комментарий?',
    changeAssignee: 'Изменить редактор',
    duplicateTask: 'Дубликат билета',
    duplicateTaskConfirmation: 'Вы хотите дублировать этот билет?',
    taskDuplicated: 'Билет был продублирован.',
    deleteTask: 'Удалить билет',
    deleteTaskConfirmation: 'Вы уверены, что хотите удалить этот билет?',
    taskDeleted: 'Билет удален.',
    assigneeChanged: 'Процессор поменян.',
    statusChanged: 'Статус билета изменился.',
    startDate: 'начинать',
    endDate: 'Конец',
    doctorVisitDate: 'Дата визита к врачу',
    doctorVisitDescription: 'Дата визита к врачу: {{date}}',
    chooseDate: 'Выберите дату',
    comment: 'Комментарий (необязательно)',
    dateError: 'Дата окончания не может быть раньше даты начала.',
    employee: 'Сотрудники',
    pleaseConnectToLoad: 'Пожалуйста, установите подключение к Интернету, чтобы загрузить билеты.'
  },
  emptyState: {
    chats: 'Создайте чат!',
    chat: 'Напишите первое сообщение :)',
    tasks: 'Билетов не найти.',
    attachments: 'Нет доступных вложений.',
    comments: 'Комментарии отсутствуют.',
    vacation: 'В этом году отпуск не введен'
  },
  formError: {
    min: 'Значение должно быть больше {{value}}.',
    min2: 'Значение должно быть больше или равно {{value}}.',
    max: 'Значение должно быть меньше или равно {{value}}.',
    requiredBecause: 'Необходимое значение, поскольку {{name}} заполнено.',
    isRequired: '{{name}} является обязательным.',
    numberInUse: 'Табельный номер уже используется.',
    maxlength: '{{name}} не может содержать более {{value}} символов.',
    faultyBlinkId: 'Неверный идентификатор мигания'
  },
  ngSelect: {
    typeToSeach: 'Пожалуйста, введите 3 буквы или более.',
    loading: 'Загрузка данных...',
    notFound: '{{title}} не найдено.',
    clearAll: 'Восстановление значений по умолчанию'
  },
  placeholder: {
    employee: 'Поиск по имени и фамилии, табельному номеру, Blink ID',
    location: 'Поиск по имени, номеру или коду объекта',
    date: 'Выберите дату',
    searchTasks: 'Искать билеты...'
  },
  hasChat: 'Начать чат сейчас',
  hasNoChatYet: 'К сожалению, я еще не установил приложение Blink me.',
  noBlinkActiveLicence: 'Нет лицензии',
  noBlinkActiveLicenceInfo: 'В настоящее время у вас нет лицензии на это приложение. Пожалуйста, свяжитесь со своим руководителем.',
  noBlinkMeLicence: 'Нет лицензии',
  noBlinkMeLicenceInfo: 'Это приложение нельзя использовать с вашей системой. Используйте Blink Time из приложения или Playstore.',
  noSuitableAppFound: 'Не найдено приложение для открытия файла.',
  myChats: 'Ваши чаты',
  hasNoChatsHome: 'Начните общаться прямо сейчас! Вы можете общаться со всеми коллегами и участвовать в группах объектов и команд.',
  hasNoTicketsHome: 'Открытых билетов нет.',
  myTickets: 'Ваши билеты',
  myEmployees: 'Мои сотрудники',
  group: 'группа',
  confirmLogoutWithChat: 'Если вы выйдете из системы, ваши сообщения в чате будут удалены. Желаете ли вы продолжить?',
  messageNotDecryptable: 'Сообщение не удалось расшифровать.',
  chat: {
    userAlreadyExists: 'Вы уже настроили чат на другом устройстве. Чтобы иметь возможность использовать чат на этом устройстве, необходимо связать устройства. Альтернативно, вы можете перезапустить настройку, но тогда чат уже нельзя будет использовать на другом устройстве.',
    linkDevices: 'Связать устройства',
    withoutChat: 'Продолжить без чата',
    setupAgain: 'Сбросить чат',
    setupAgainText: 'Если вы настроите чат заново, все сообщения из существующих отдельных чатов будут удалены и чат больше нельзя будет использовать на других устройствах! Желаете ли вы продолжить?',
    LinkDevicesText: 'Чтобы связать устройства для чата, откройте «Настройки» на другом устройстве и выберите «Поделиться чатом с другим устройством».',
    personalToken: 'Ваш личный код чата',
    wrongCode: 'Предоставленный код чата неверен.',
    beingPrepared: 'Чат готовится.',
    twilioAccessDenied: 'Не удалось установить соединение с чат-сервисом. Пожалуйста, свяжитесь со службой поддержки Blink.',
    notificationsDenied: 'Уведомления отключены на вашем устройстве/браузере. Перейдите в «Настройки» и включите уведомления «Мигните меня, чтобы получать уведомления». Затем вы можете активировать уведомления в своих личных настройках.',
    deleted: 'Чат удален',
    unavailable: 'Чат недоступен',
    participant: 'Участник',
    teamChatDescription: 'В эту группу чата добавляются все сотрудники, у которых вы зарегистрированы как менеджер.',
    locationChatDescription: 'Сотрудники добавляются автоматически, если у них есть график по этому объекту за последние четыре недели или следующие четыре недели.',
    createEmployeeChatDescription: 'С выбранным сотрудником будет создан приватный чат.',
    teamChatCreationTitle: 'Создать чат-группу',
    createTeamChatDescription: 'Создаётся чат-группа, в которую автоматически добавляются все сотрудники, у которых вы зарегистрированы как руководитель.',
    createLocationChatDescription: 'Для выбранного объекта создается чат-группа, куда автоматически добавляются сотрудники, если у них есть план на это на последние четыре недели или ближайшие четыре недели.'
  },
  protocol: {
    SEND_EMAIL: 'Послать по электронной почте',
    DELETE: 'Очистить журнал',
    deleteMessage: 'Вы действительно хотите удалить журнал? Это действие не может быть отменено.',
    Send: 'Отправлять',
    SuccessMessage: 'Журнал был успешно отправлен в службу поддержки Blink.',
    ConfirmMessage: 'Сначала обратитесь в службу поддержки и отправляйте журнал только по запросу.',
    Description: 'описание проблемы'
  },
  timeTracking: {
    title: 'Учет времени',
    start: 'Начинать',
    startDescription: 'Нажмите «Пуск», чтобы начать учет времени',
    started: 'Начал',
    stop: 'Остановиться',
    duration: 'Протяженность времени',
    syncButtonLabel: 'Время загрузки записано офлайн',
    offlineSyncMessage: 'Для передачи сохраненного времени вы должны быть онлайн.',
    successfulSync: 'Время загружено',
    scanNowQrCode: 'QR код',
    scanNowQrCodeDescription: 'Сканируйте QR-код на объекте.',
    scanNowNfc: 'НФК',
    scanNowNfcDescription: 'Поднесите смартфон к наклейке NFC на объекте.',
    nfcDescription: 'Обнаружение через NFC: поднесите свой мобильный телефон к объектному коду.',
    noTimeTrackingMethodAvailableDescription: 'Для этого пользователя не настроен метод учета времени. Пожалуйста, свяжитесь с руководителем вашей группы.',
    noLocationCode: 'Нет доступного кода',
    noLocationCodeDescription: 'Выберите этот вариант, если на объекте нет QR-кода или наклейки NFC. Предоставление комментария обязательно.',
    noLocationCodeConfirm: 'Вы уверены, что хотите начать без объектного кода? Вы должны оставить комментарий, чтобы остановиться.',
    wrongCode: 'Сканированный QR-код не является объектным кодом!',
    readNotice: 'Пожалуйста, прочтите инструкцию!',
    warning: 'предупреждение',
    lessThanAMinuteTitle: 'Полная запись времени',
    lessThanAMinute: 'Вы начали менее 1 минуты назад. Вы уверены, что хотите остановиться?',
    continue: 'Продолжить запись',
    timeAlert: {
      differenceInMinutesHint: 'Время на вашем смартфоне отличается от времени на сервере. Разница фиксируется',
      differentTimeZoneHint: 'Часовой пояс вашего смартфона отличается от часового пояса сервера. Разница фиксируется.'
    },
    leaveComment: {
      withCode: 'Информация об объекте/услуге была неясна. Пожалуйста, сообщите нам, в каком объекте/службе вы работали.',
      withoutCode: 'Ни один объектный код не был записан ни при запуске, ни при остановке. Пожалуйста, объясните, почему вы начали/остановили игру без объекта.'
    },
    comment: 'комментарий',
    enterComment: 'Комментарий по отслеживанию времени',
    differentQrCode: {
      title: 'Сменить объект?',
      continueInLocation: 'Продолжить работу в {{locationName}}.',
      automaticStopInLocation: 'Запись времени в {{locationName}} автоматически остановится.',
      stopInLocation: 'Остановить запись времени в {{locationName}}.',
      stop: 'Остановиться',
      stopNoLocation: 'Остановить запись времени.',
      continueNoOldLocation: 'Запись времени для предыдущего объекта автоматически останавливается.',
      continueWorking: 'Продолжать работать',
      continueWorkingInNewLocation: 'Продолжайте работать в новой недвижимости.',
      stopWithLocationActivity: 'Остановить запись времени на {{locationActivity}}.',
      continueWithNewLocationActivity: 'Продолжить работу с {{locationActivity}}',
      stopOldLocationActivity: 'Запись времени на {{locationActivity}} автоматически останавливается.',
      stopOldNoLocationActivity: 'Запись времени без выступления автоматически останавливается.',
      continueNoLocationActivity: 'Продолжайте работать без производительности.',
      sameLocationTitle: 'Изменить производительность?',
      differentLocationTitle: 'Сменить объект?'
    },
    saveAndStop: 'Сохранить и остановить',
    noLocationScanned: '- Нет объекта -',
    noLocationFound: '- Объект не ясен -',
    timerDifference: '{{h}} часов {{mm}} минут',
    stopSuccess: 'Время успешно записано',
    stopSuccessToast: 'Запись времени успешно остановлена.',
    stopTimeTracking: 'Остановиться',
    manual: {
      time: 'Время',
      locationSelect: 'Выберите объект',
      loadMore: 'Загрузи больше',
      trackTime: 'Время записи',
      location: 'объект',
      locationActivity: 'Производительность',
      date: 'Дата',
      startTime: 'От',
      endTime: 'До',
      create: 'Подтверждать',
      createAgain: 'Запишите дополнительное время для этого объекта',
      errorUntil: 'Время от времени должно быть меньше времени до',
      worktimeDifference: 'Рабочее время:',
      worktimeDifferenceValue: '{{hours}} часов {{minutes}} минут',
      worktimeDifferenceValueMinutes: '{{minutes}} минут',
      success: 'Время успешно записано',
      break: 'Перерыв:',
      breakMinutes: '{{minutes}} минут',
      breakHoursAndMinutes: '{{hours}} часов {{minutes}} минут',
      noActivity: '- нет производительности -',
      locationPlaceHolder: 'Поиск по имени, номеру или коду объекта',
      conflictError: 'Уже ведется запись времени в указанные периоды. Перекрытия не допускаются. Пожалуйста, проверьте свои данные.',
      currentDayDuration: 'Уже записано',
      expectedDuration: 'Ожидаемое рабочее время',
      expectedDurationIncludedCurrent: 'включая текущий вход'
    },
    differenceInMinutesHint: 'Время на вашем смартфоне отличается от времени на сервере. Разница фиксируется',
    differentTimeZoneHint: 'Часовой пояс вашего смартфона отличается от часового пояса сервера. Разница фиксируется.',
    proposed: {
      capture: 'Захватывать',
      time: 'Время',
      captureTime: 'Время записи',
      button: 'Подтверждать',
      title: 'Подтвердите рабочее время',
      includingBreak: '(включая перерыв)',
      from: 'От',
      to: 'До',
      break: 'Перерыв',
      hour: 'Часы',
      confirm: {
        description: 'Пожалуйста, подтвердите ваше рабочее время',
        confirm: 'Да точно',
        editButton: 'Нет, это неправильно'
      },
      edit: {
        description: 'Пожалуйста, укажите продолжительность',
        breakIncluded: 'Включая {{ duration }} перерыв',
        comment: 'комментарий',
        yourComment: 'ваш комментарий',
        minutes: 'минуты',
        hours: 'Часы'
      },
      reasons: {
        overtime: 'Сверхурочные по вине клиента',
        faster: 'Готово быстрее',
        other: 'Разнообразный',
        representative: 'я был заменой'
      },
      description: 'Подтвердите свое рабочее время, как только закончите работу на объекте.'
    },
    codeNotAssignable: 'Отсканированный QR-код не может быть присвоен!',
    running: 'Запись времени идет'
  },
  timeSheet: {
    title: 'Время и планирование',
    noPlannings: 'Нет планирования',
    planned: 'Планируется',
    workingTime: 'рабочее время',
    workloadDuration: 'Еженедельное рабочее время',
    workloadDurationShort: 'ВАЗ',
    plannedDuration: 'Должен',
    workedDuration: 'Является',
    hasWorkload: 'Работа в этот день',
    pleaseConnectToLoad: 'Пожалуйста, установите подключение к Интернету, чтобы загрузить ваше время.',
    footer: {
      planned: 'Планируется',
      worklogsTotal: 'Все часы',
      workloadTotal: 'Часы работы по договору',
      difference: 'разница'
    },
    homepageTitle: 'Ваш рабочий день'
  },
  planning: {
    planRoute: 'Спланировать маршрут',
    title: 'планирование',
    modifyMinutes: 'Единая ставка:',
    includingPause: 'Перерыв:',
    noNavAppFound: 'Навигационное приложение не найдено'
  },
  worklog: {
    title: 'Записанное время',
    absenceTitle: 'отсутствие',
    workingTime: 'рабочее время',
    breakDuration: 'Перерыв',
    reduce: 'Единая ставка',
    attachments: 'Вложения',
    comments: 'Комментарии',
    comment: 'комментарий',
    noLocation: 'Объект не указан',
    changedBy: 'Время изменилось с {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'Вы уверены, что хотите удалить это вложение?',
    attachmentDeleted: 'Вложение удалено',
    confirmCommentDelete: 'Вы уверенны, что хотите удалить этот комментарий?',
    commentDeleted: 'Комментарий был удален'
  },
  tabs: {
    home: 'Дом',
    times: 'раз',
    chat: 'Чат',
    tickets: 'Билеты',
    more: 'более'
  },
  profile: {
    title: 'профиль',
    testing: 'Тестирование',
    testingDescription: 'Эта область видна только при подключении к тестовой системе.',
    testingAllowQrCodeOnWeb: 'Разрешить ручной ввод QR-кода',
    pushNotifications: 'Всплывающее уведомление',
    reloadUserContext: 'Перезагрузить пользовательский контекст',
    pushNotificationSuccessful: 'Push-уведомление успешно получено',
    editProfilePicture: 'изменить изображение профиля',
    editProfilePictureTitle: 'Выберите раздел',
    pushNotificationNotice: 'Для проведения теста необходима авторизация «Редактирование настроек клиента».',
    send: 'Отправлять',
    profilePicture: 'Изображение профиля'
  },
  help: { title: 'Помощь' },
  myVacations: {
    vacation: 'Отпуск',
    amountAvailableVacationDays: '{{amount}} дней отпуска еще есть',
    totalVacationDays: 'Право на отпуск {{ total }} дн.',
    remainingVacationDays: '({{ total }} дн. {{ sign }} {{ remaining }} дн. осталось отпуска {{ previousYear }})',
    xDaysVacations: '{{ total }} дней отпуска',
    title: 'Твой отпуск'
  },
  externalLinks: {
    title: 'Внешние ссылки',
    notice: 'Эта ссылка предоставлена вашей компанией. Вы покидаете систему Blink.'
  },
  stickers: {
    title: 'Ваши стикеры',
    sticker: 'Наклейки',
    sender: 'Получатель',
    text: 'Касательно',
    date: 'Дата',
    emptyState: 'К сожалению, у вас пока нет стикеров.',
    senderDescription: 'Этот стикер был отправлен вам пользователем {{sender}} в {{date}}.',
    readed: 'читать',
    notReaded: 'еще не читал',
    newStickerToaster: 'Новая наклейка «Мигание»!',
    open: 'Открыть',
    next: 'Дальше',
    titleCases: {
      case1: 'Поздравляем с первой наклейкой!',
      case2: 'Отлично, теперь у вас уже есть стикеры <b>{{count}}</b>!',
      case3: 'Поздравляем с 10-й наклейкой!',
      case4: '30 наклеек - вау!',
      case5: 'Теперь здесь становится тесно: <b>{{count}}</b> Стикеры!'
    },
    confirmDelete: 'Вы действительно хотите удалить эти стикеры?'
  },
  autoTranslate: {
    isTranslated: 'Автоматически переведено',
    translationError: 'Автоматический перевод в настоящее время невозможен.'
  },
  confirmLogout: 'Вы действительно хотите выйти из системы?',
  message: 'Новости',
  migration: {
    wrongVersion: 'В текущей версии Blink me вход через приложение Blink Time не поддерживается. Пожалуйста, обновите приложение Blink me до последней версии.'
  },
  eLearning: {
    pendingTab: 'Сделать ({{value}})',
    completedTab: 'Готово ({{value}})',
    completed: 'Завершенный',
    pending: 'Открыть',
    dueFalling: 'Просрочено',
    title: 'Электронное обучение',
    course: 'курс',
    dueDate: 'Годен до',
    topics: 'предметы',
    completeDate: 'Завершено',
    status: { Pending: 'открыть', Done: 'завершенный' },
    infos: {
      pendingExists: 'Здесь вы можете найти все открытые курсы обучения.',
      finishOverdue: 'Пожалуйста, пройдите обучение, которое уже назначено, как можно быстрее.',
      allCompleted: 'Отличный! Вы успешно прошли все курсы обучения.',
      emptyState: 'У вас нет назначенного обучения.'
    }
  },
  checklists: {
    title: 'Формы',
    description: 'Заполните форму на объект',
    button: 'Выберите объект'
  },
  pendingTab: 'Выполнение ({{value}})',
  completedTab: 'Завершено ({{value}})',
  workOrders: {
    title: 'Заказы',
    homepageTitle: 'Ваши заказы',
    responsible: 'Ответственный',
    customerContact: 'Контакт с клиентом',
    trackedTime: 'Учет времени для заказа №{{value}}',
    show: 'Посмотреть заказ',
    trackInfo: {
      confirmAndAcknowledge: 'Пожалуйста, подтвердите позиции после внедрения и проведите приемочные испытания на месте.',
      acknowledgeOnly: 'Пожалуйста, проведите проверку на месте.',
      confirmOnly: 'Пожалуйста, подтвердите позиции после реализации.'
    },
    pleaseConnectToLoad: 'Пожалуйста, установите подключение к Интернету для загрузки ваших заказов.',
    status: {
      new: 'Новый',
      inProgress: 'Исполнение',
      completed: 'Завершенный'
    },
    emptyState: 'Открытых ордеров нет.',
    emptyStateCompleted: 'Нет готовых заказов.',
    details: {
      title: 'Детали заказа',
      positionsTab: 'Позиции ({{value}})',
      acknowledgementsTab: 'уменьшается ({{value}})'
    },
    acknowledgement: {
      title: 'принятие',
      start: 'Начать прием',
      acknowledgedAtDate: 'Удален',
      itemSelectDescription: 'Выберите все позиции, которые необходимо удалить.',
      customerInformationDescription: 'Укажите контактные данные заказчика (покупателя). После принятия на этот адрес электронной почты будет отправлено подтверждение.',
      created: 'Принятие успешно сохранено',
      selectAll: 'Выбрать все',
      emptyState: 'Приемов нет.'
    },
    items: {
      items: 'позиции',
      acknowledge: 'Подтвердить позицию',
      confirmAcknowledge: 'Настоящим вы подтверждаете, что работа выполнена. Статус позиции изменится на «Завершено».',
      status: {
        open: 'Открыть',
        planned: 'Планируется',
        performed: 'Сделанный',
        acknowledged: 'Удаленный',
        billed: 'выставлен счет'
      },
      emptyState: 'Нет доступных позиций.'
    },
    trackTime: {
      description: 'Выберите работу, для которой хотите отслеживать время.',
      chooseWorkOrder: 'Выберите заказ',
      choosePosition: 'Выберите позицию'
    }
  }
};
