export default {
  home: {
    pageTitle: 'Strona główna',
    welcome: 'Witamy w aplikacji Blink me. Miłej rozmowy,\n\nTwój zespół Blink',
    startChat: 'zaczynać',
    ticket: 'bilet',
    timeLicenseMissing: 'Nie przypisano Ci licencji Blink Time!'
  },
  conversations: { pageTitle: 'Aktualności', yourChats: 'Twoje czaty', chat: 'Czat' },
  settings: {
    settings: 'Ustawienia',
    pushEnabled: 'Otrzymuj powiadomienia push',
    system: 'system',
    languageSelection: 'wybór języka',
    currentSettings: 'Obecne ustawienia',
    user: 'użytkownik',
    appVersion: 'Wersja (aplikacja)',
    apiVersion: 'Wersja (API)',
    deviceInfo: 'Wersja urządzenia',
    buildVersion: 'wersja (kompilacja)',
    helpAndDataPolicy: 'Pomoc i ochrona danych',
    text1: 'Nasz zespół wsparcia chętnie Ci pomoże, jeśli masz jakiekolwiek pytania:',
    phone: 'telefon',
    mail: 'e-mail',
    information: 'informacje',
    documentation: 'Dokumentacja internetowa',
    documentationText: 'W naszej dokumentacji online znajdziesz instrukcje i filmy dotyczące wszystkich ważnych obszarów Blink.',
    dataPolicy: 'Ochrona danych',
    support: 'Wsparcie',
    moreActions: 'Dalsze działania',
    protocol: 'protokół',
    showChatUserSecret: 'Udostępnij czat innemu urządzeniu',
    scanCodeAnotherDevice: 'Zeskanuj następujący kod QR na swoim nowym urządzeniu lub wprowadź swój osobisty kod czatu na nowym urządzeniu!',
    yourPersonalCode: 'Twój osobisty kod czatu',
    permissions: {
      title: 'Uprawnienia',
      grantPermission: 'dać pozwolenie',
      camera: 'kamera',
      cameraHint: 'Niezbędne do rejestracji czasu za pomocą kodu wynikowego.',
      nfc: 'NFC',
      nfcHint: 'Niezbędne do rejestracji czasu za pomocą tagu NFC.',
      media: 'Pobierz obraz i obraz z biblioteki',
      mediaHint: 'Niezbędne do przechwytywania obrazów i dokumentów związanych z Twoimi czasami i biletami.',
      location: 'Lokalizacja',
      locationHint: 'Konieczne jest zarejestrowanie swojej lokalizacji w momencie rejestracji czasu.',
      notifications: 'Powiadomienia push',
      notificationsHint: 'Niezbędne do otrzymywania powiadomień push dla wiadomości na czacie, powiadomień o mrugnięciu i naklejek.',
      locationDisabledOnSystem: 'Usługi lokalizacyjne są wyłączone. Aktywuj tę opcję w ustawieniach swojego urządzenia.'
    }
  },
  connectionState: {
    connecting: 'Łączyć...',
    connected: 'Związane razem.',
    disconnecting: 'Połączenie zostało rozłączone...',
    disconnected: 'utracono połączenie',
    denied: 'Nie można nawiązać połączenia.'
  },
  noOneToFind: 'Żadnego do znalezienia.',
  nothingToFind: 'Nic nie można znaleźć.',
  noLocationFound: 'Nie znaleziono żadnych obiektów.',
  searchPlaceholder: 'Szukać...',
  employeeSearchPlaceholder: 'Imię pierwsze imię',
  typeToSearch: 'Kliknij, aby wyszukać...',
  typeToSearchEmployee: 'Wpisz imię i nazwisko osoby, aby rozpocząć czat',
  createChatWithEmployee: 'Szukam pracowników',
  createChatWithLocationTeam: 'Wyszukaj obiekt',
  createTeamleaderChat: 'Wszyscy moi pracownicy',
  yourLanguageSelection: 'Twój wybór języka:',
  loginInfo: 'Aby się zarejestrować, zeskanuj swój osobisty kod QR:',
  updating: 'Blink me jest aktualizowany.',
  notice: 'Ogłoszenie',
  importantNotice: 'Ważna uwaga',
  continue: 'Dalej',
  intro: {
    slide1: {
      header: 'Powitanie',
      text: 'Baw się dobrze dzięki aplikacji Blink me.'
    },
    slide2: {
      header: 'Czat',
      text: 'Rozmawiaj bezpośrednio ze swoimi współpracownikami lub otrzymuj najświeższe informacje od interesujących grup.'
    },
    slide3: {
      header: 'Bilety',
      text: 'Tutaj możesz przeglądać swoje zadania i tworzyć aplikacje.'
    }
  },
  pleaseWait: 'Proszę czekać...',
  skip: 'Pominąć',
  startAgain: 'Rozpocząć od nowa',
  lock: {
    createPinHeader: 'Twój osobisty PIN',
    createPinInfo: 'Aby chronić swoje dane, możesz ustawić tutaj PIN, który będziesz musiał wprowadzać za każdym razem, gdy korzystasz z aplikacji Blink me.',
    validatePin: 'Proszę wprowadzić ponownie PIN.',
    tryAgain: 'PIN nie pasuje. Spróbuj jeszcze raz.',
    triedToOften: 'Niestety, Twój PIN nie pasuje. Czy chcesz rozpocząć proces od nowa, czy pominąć go i zrobić to później?',
    enterPinHeader: 'Przypnij wpis',
    enterPinInfo: 'Wprowadź kod PIN, aby odblokować aplikację.',
    remainingTrys: 'Jeszcze {{amount}} prób. Następnie musisz ponownie zalogować się do aplikacji.',
    tooManyEnters: 'Niestety rejestracja za pomocą PIN-u nie jest już możliwa. Zaloguj się ponownie za pomocą karty Blink ID.',
    reSignIn: 'Nowa rejestracja',
    setpin: 'Wprowadzanie kodu PIN podczas korzystania z aplikacji',
    skip: 'Nie chcesz teraz przypisać PIN-u? Możesz aktywować tę funkcję w dowolnym momencie w ustawieniach aplikacji, także później.',
    dontUsePin: 'nie używaj kodu PIN',
    deactivatePinHeader: 'Wyłącz PIN',
    deactivatePinInfo: 'Wprowadź swój PIN, aby dezaktywować ochronę.'
  },
  active: {
    yourTickets: 'Twoje bilety',
    createNewTask: 'Utwórz nowy bilet',
    assignee: 'redaktor',
    location: 'obiekt',
    dueDate: 'Termin na',
    createTask: 'Utwórz bilet',
    taskCreated: 'Bilet został utworzony.',
    backToTasks: 'Wracając do biletów',
    createdAt: 'utworzony na',
    attachments: 'Załączniki',
    addFileOrImage: 'Dodaj plik lub zdjęcie',
    addImage: 'Dodaj zdjęcie',
    comments: 'Uwagi',
    addComment: 'Dodaj komentarz',
    addCommentInfo: 'Komentarz jest widoczny dla każdego, kto może wyświetlić to zgłoszenie.',
    commentCreated: 'Komentarz został zapisany.',
    showClosed: 'Zobacz zrealizowane bilety',
    resetFilter: 'zresetuj wszystkie filtry',
    changeTicketStatusTo: 'Zmień status zgłoszenia na:',
    editTask: 'Edytuj bilet',
    saveTask: 'Zapisz bilet',
    taskSaved: 'Bilet został zapisany.',
    deleteImage: 'Jasny obraz',
    deleteImageConfirmation: 'Czy na pewno chcesz usunąć obraz?',
    deleteAttachment: 'Usuń załącznik',
    deleteAttachmentConfirmation: 'Czy na pewno chcesz usunąć ten załącznik?',
    deleteComment: 'Usuń komentarz',
    deleteCommentConfirmation: 'Czy na pewno chcesz usunąć komentarz?',
    changeAssignee: 'Zmień redaktora',
    duplicateTask: 'Duplikat biletu',
    duplicateTaskConfirmation: 'Czy chcesz zduplikować ten bilet?',
    taskDuplicated: 'Bilet został zdublowany.',
    deleteTask: 'Usuń bilet',
    deleteTaskConfirmation: 'Czy na pewno chcesz usunąć ten bilet?',
    taskDeleted: 'Bilet został usunięty.',
    assigneeChanged: 'Edytor został zmieniony.',
    statusChanged: 'Status biletu uległ zmianie.',
    startDate: 'zaczynać',
    endDate: 'Koniec',
    doctorVisitDate: 'Data wizyty lekarskiej',
    doctorVisitDescription: 'Data wizyty lekarskiej: {{date}}',
    chooseDate: 'Wybierz datę',
    comment: 'Komentarz (opcjonalnie)',
    dateError: 'Data zakończenia nie może być wcześniejsza niż data rozpoczęcia',
    employee: 'Pracownicy',
    pleaseConnectToLoad: 'Aby załadować bilety, nawiąż połączenie internetowe.'
  },
  emptyState: {
    chats: 'Utwórz czat!',
    chat: 'Napisz pierwszą wiadomość :)',
    tasks: 'Nie znaleziono żadnych biletów.',
    attachments: 'Brak załączników.',
    comments: 'Brak komentarzy.',
    vacation: 'W tym roku nie wpisano żadnego urlopu'
  },
  formError: {
    min: 'Wartość musi być większa niż {{value}}.',
    min2: 'Wartość musi być większa lub równa {{value}}.',
    max: 'Wartość musi być mniejsza lub równa {{value}}.',
    requiredBecause: 'Wartość wymagana, ponieważ {{name}} jest wypełnione.',
    isRequired: '{{name}} jest obowiązkowe.',
    numberInUse: 'Numer personalny jest już w użyciu.',
    maxlength: '{{name}} nie może zawierać więcej niż {{value}} znaków.',
    faultyBlinkId: 'Nieprawidłowy identyfikator mrugnięcia'
  },
  ngSelect: {
    typeToSeach: 'Proszę wpisać 3 lub więcej liter.',
    loading: 'ładowanie danych...',
    notFound: 'Nie znaleziono {{title}}.',
    clearAll: 'Przywróć ustawienia domyślne'
  },
  placeholder: {
    employee: 'Szukaj według imienia i nazwiska, numeru personelu, identyfikatora Blink',
    location: 'Szukaj według nazwy, numeru lub kodów obiektu',
    date: 'Wybierz datę',
    searchTasks: 'Szukaj biletów...'
  },
  hasChat: 'Rozpocznij czat teraz',
  hasNoChatYet: 'Niestety nie zainstalowałem jeszcze aplikacji Blink me.',
  noBlinkActiveLicence: 'Brak licencji',
  noBlinkActiveLicenceInfo: 'Obecnie nie masz licencji na tę aplikację. Skontaktuj się ze swoim przełożonym.',
  noBlinkMeLicence: 'Brak licencji',
  noBlinkMeLicenceInfo: 'Tej aplikacji nie można używać w Twoim systemie. Użyj czasu migania z aplikacji lub Sklepu Play.',
  noSuitableAppFound: 'Nie znaleziono aplikacji umożliwiającej otwarcie pliku.',
  myChats: 'Twoje czaty',
  hasNoChatsHome: 'Zacznij rozmawiać już teraz! Możesz rozmawiać ze wszystkimi współpracownikami i uczestniczyć w grupach obiektowych i zespołowych.',
  hasNoTicketsHome: 'Brak dostępnych biletów otwartych.',
  myTickets: 'Twoje bilety',
  myEmployees: 'Moi pracownicy',
  group: 'Grupa',
  confirmLogoutWithChat: 'Jeśli się wylogujesz, Twoje wiadomości na czacie zostaną usunięte. Czy chciałbyś kontynuować?',
  messageNotDecryptable: 'Nie udało się odszyfrować wiadomości.',
  chat: {
    userAlreadyExists: 'Skonfigurowałeś już czat na innym urządzeniu. Aby móc korzystać z czatu również na tym urządzeniu, musisz połączyć urządzenia. Alternatywnie możesz ponownie uruchomić konfigurację, ale wtedy nie będzie można już korzystać z czatu na drugim urządzeniu.',
    linkDevices: 'Połącz urządzenia',
    withoutChat: 'Kontynuuj bez czatu',
    setupAgain: 'Zresetuj czat',
    setupAgainText: 'Jeśli ponownie skonfigurujesz czat, wszystkie wiadomości z istniejących indywidualnych czatów zostaną usunięte, a czatu nie będzie można już używać na innych urządzeniach! Czy chciałbyś kontynuować?',
    LinkDevicesText: 'Aby połączyć urządzenia na potrzeby czatu, otwórz Ustawienia na drugim urządzeniu i wybierz „Udostępnij czat innemu urządzeniu”.',
    personalToken: 'Twój osobisty kod czatu',
    wrongCode: 'Podany kod czatu jest nieprawidłowy.',
    beingPrepared: 'Czat jest w przygotowaniu.',
    twilioAccessDenied: 'Nie można nawiązać połączenia z usługą czatu. Skontaktuj się z pomocą techniczną Blink.',
    notificationsDenied: 'Powiadomienia są wyłączone na Twoim urządzeniu/przeglądarce. Przejdź do ustawień i włącz powiadomienia dla Mrugnij, aby otrzymywać powiadomienia. Następnie możesz aktywować powiadomienia w ustawieniach osobistych.',
    deleted: 'Czat został usunięty',
    unavailable: 'Czat jest niedostępny',
    participant: 'Uczestnik',
    teamChatDescription: 'Do tej grupy czatu dodawani są wszyscy pracownicy, dla których jesteś zarejestrowany jako menedżer.',
    locationChatDescription: 'Pracownicy są dodawani automatycznie, jeśli mają harmonogram dla tego obiektu w ciągu ostatnich czterech tygodni lub kolejnych czterech tygodni.',
    createEmployeeChatDescription: 'Z wybranym pracownikiem zostanie utworzony prywatny czat.',
    teamChatCreationTitle: 'Utwórz grupę czatu',
    createTeamChatDescription: 'Tworzona jest grupa czatu, w której automatycznie dodawani są wszyscy pracownicy, dla których jesteś zarejestrowany jako menedżer.',
    createLocationChatDescription: 'Dla wybranego obiektu tworzona jest grupa czatu, do której automatycznie dodawani są pracownicy, jeśli mają na to plan w ciągu ostatnich czterech tygodni lub kolejnych czterech tygodni.'
  },
  protocol: {
    SEND_EMAIL: 'Wysłać e-mailem',
    DELETE: 'Wyczyść log',
    deleteMessage: 'Czy na pewno chcesz usunąć dziennik? Tej akcji nie można cofnąć.',
    Send: 'Wysłać',
    SuccessMessage: 'Dziennik został pomyślnie wysłany do pomocy technicznej Blink.',
    ConfirmMessage: 'Najpierw skontaktuj się z zespołem pomocy technicznej i wyślij dziennik tylko na żądanie.',
    Description: 'opis problemu'
  },
  timeTracking: {
    title: 'Śledzenie czasu',
    start: 'Początek',
    startDescription: 'Kliknij przycisk Start, aby rozpocząć śledzenie czasu',
    started: 'Rozpoczęty',
    stop: 'Zatrzymać',
    duration: 'Długość czasu',
    syncButtonLabel: 'Czas przesyłania zarejestrowany w trybie offline',
    offlineSyncMessage: 'Aby przenieść zapisane czasy, musisz być online.',
    successfulSync: 'Czasy zostały przesłane',
    scanNowQrCode: 'Kod QR',
    scanNowQrCodeDescription: 'Zeskanuj kod QR znajdujący się w obiekcie.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Przyłóż smartfon do naklejki NFC znajdującej się w przedmiocie.',
    nfcDescription: 'Wykrywanie poprzez NFC: Przyłóż telefon komórkowy do kodu obiektowego',
    noTimeTrackingMethodAvailableDescription: 'Dla tego użytkownika nie skonfigurowano metody śledzenia czasu. Skontaktuj się z liderem swojego zespołu.',
    noLocationCode: 'Brak dostępnego kodu',
    noLocationCodeDescription: 'Wybierz tę opcję, jeśli w obiekcie nie ma kodu QR ani naklejki NFC. Dodanie komentarza jest obowiązkowe.',
    noLocationCodeConfirm: 'Czy na pewno chcesz zacząć bez kodu wynikowego? Aby zatrzymać, musisz dodać komentarz.',
    wrongCode: 'Zeskanowany kod QR nie jest kodem obiektowym!',
    readNotice: 'Przeczytaj instrukcje!',
    warning: 'ostrzeżenie',
    lessThanAMinuteTitle: 'Pełny zapis czasu',
    lessThanAMinute: 'Zacząłeś mniej niż minutę temu. Czy na pewno chcesz przestać?',
    continue: 'Kontynuuj nagrywanie',
    timeAlert: {
      differenceInMinutesHint: 'Czas na Twoim smartfonie różni się od czasu na serwerze. Różnica jest rejestrowana',
      differentTimeZoneHint: 'Strefa czasowa Twojego smartfona różni się od strefy czasowej serwera. Różnica jest rejestrowana.'
    },
    leaveComment: {
      withCode: 'Specyfikacja obiektu/usługi nie była jasna. Proszę o informację w jakim obiekcie/usługach Pan pracował.',
      withoutCode: 'Na początku i na końcu nie wykryto żadnego kodu obiektowego. Wyjaśnij, dlaczego zacząłeś/zatrzymałeś bez przedmiotu.'
    },
    comment: 'komentarz',
    enterComment: 'Skomentuj śledzenie czasu',
    differentQrCode: {
      title: 'Zmienić obiekt?',
      continueInLocation: 'Kontynuuj pracę w {{locationName}}.',
      automaticStopInLocation: 'Rejestracja czasu w {{locationName}} zatrzyma się automatycznie.',
      stopInLocation: 'Zatrzymaj rejestrację czasu w {{locationName}}.',
      stop: 'Zatrzymać',
      stopNoLocation: 'Zatrzymaj rejestrację czasu.',
      continueNoOldLocation: 'Rejestracja czasu dla poprzedniego obiektu zostaje automatycznie zatrzymana.',
      continueWorking: 'Pracuj dalej',
      continueWorkingInNewLocation: 'Kontynuuj pracę w nowej nieruchomości.',
      stopWithLocationActivity: 'Zatrzymaj rejestrację czasu na {{locationActivity}}.',
      continueWithNewLocationActivity: 'Kontynuuj współpracę z {{locationActivity}}',
      stopOldLocationActivity: 'Rejestracja czasu na urządzeniu {{locationActivity}} zostaje automatycznie zatrzymana.',
      stopOldNoLocationActivity: 'Rejestracja czasu bez występu zostaje automatycznie zatrzymana.',
      continueNoLocationActivity: 'Kontynuuj pracę bez wydajności.',
      sameLocationTitle: 'Zmienić wydajność?',
      differentLocationTitle: 'Zmienić obiekt?'
    },
    saveAndStop: 'zapisz i przestań',
    noLocationScanned: '- Brak obiektu -',
    noLocationFound: '- Obiekt niejasny -',
    timerDifference: '{{h}} godzin {{mm}} minut',
    stopSuccess: 'Czas został pomyślnie zarejestrowany',
    stopSuccessToast: 'Rejestracja czasu została pomyślnie zatrzymana.',
    stopTimeTracking: 'Zatrzymać',
    manual: {
      time: 'Czas',
      locationSelect: 'wybierz obiekt',
      loadMore: 'Załaduj więcej',
      trackTime: 'Rekordowy czas',
      location: 'obiekt',
      locationActivity: 'Wydajność',
      date: 'Data',
      startTime: 'Z',
      endTime: 'Dopóki',
      create: 'Potwierdzać',
      createAgain: 'Zapisz dodatkowy czas dla tego obiektu',
      errorUntil: 'Od czasu musi być mniejsze niż Do czasu',
      worktimeDifference: 'Godziny pracy:',
      worktimeDifferenceValue: '{{hours}} godzin {{minutes}} minut',
      worktimeDifferenceValueMinutes: '{{minutes}} minut',
      success: 'Czas został pomyślnie zarejestrowany',
      break: 'Przerwa:',
      breakMinutes: '{{minutes}} minut',
      breakHoursAndMinutes: '{{hours}} godzin {{minutes}} minut',
      noActivity: '- brak wydajności -',
      locationPlaceHolder: 'Szukaj według nazwy, numeru lub kodów obiektu',
      conflictError: 'W określonych okresach istnieje już zapis czasu. Nakładanie się nie jest dozwolone. Sprawdź szczegóły.',
      currentDayDuration: 'Już nagrano',
      expectedDuration: 'Oczekiwane godziny pracy',
      expectedDurationIncludedCurrent: 'łącznie z wejściem prądowym'
    },
    differenceInMinutesHint: 'Czas na Twoim smartfonie różni się od czasu na serwerze. Różnica jest rejestrowana',
    differentTimeZoneHint: 'Strefa czasowa Twojego smartfona różni się od strefy czasowej serwera. Różnica jest rejestrowana.',
    proposed: {
      capture: 'Schwytać',
      time: 'Czas',
      captureTime: 'Rekordowy czas',
      button: 'Potwierdzać',
      title: 'Potwierdź godziny pracy',
      includingBreak: '(w tym przerwa)',
      from: 'Z',
      to: 'Dopóki',
      break: 'Przerwa',
      hour: 'Zegar',
      confirm: {
        description: 'Prosimy o potwierdzenie godzin pracy',
        confirm: 'Tak to prawda',
        editButton: 'Nie, to nie w porządku'
      },
      edit: {
        description: 'Proszę określić czas trwania',
        breakIncluded: 'Łącznie z {{ duration }} przerwą',
        comment: 'komentarz',
        yourComment: 'Twój komentarz',
        minutes: 'minuty',
        hours: 'godziny'
      },
      reasons: {
        overtime: 'Nadgodziny z winy klienta',
        faster: 'Zrobione szybciej',
        other: 'Różnorodny',
        representative: 'Byłem zastępcą'
      },
      description: 'Potwierdź swoje godziny pracy zaraz po zakończeniu pracy w obiekcie.'
    },
    codeNotAssignable: 'Zeskanowanego kodu QR nie można przypisać!',
    running: 'Rejestracja czasu trwa'
  },
  timeSheet: {
    title: 'Czasy i planowanie',
    noPlannings: 'Żadnego planowania',
    planned: 'Zaplanowany',
    workingTime: 'godziny pracy',
    workloadDuration: 'tygodniowy czas pracy',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Powinien',
    workedDuration: 'Jest',
    hasWorkload: 'Działa tego dnia',
    pleaseConnectToLoad: 'Proszę nawiązać połączenie internetowe, aby załadować swoje czasy.',
    footer: {
      planned: 'Zaplanowany',
      worklogsTotal: 'Suma godzin',
      workloadTotal: 'godzin zgodnie z umową',
      difference: 'różnica'
    },
    homepageTitle: 'Twój dzień pracy'
  },
  planning: {
    planRoute: 'zaplanować trasę',
    title: 'planowanie',
    modifyMinutes: 'Ryczałt:',
    includingPause: 'Przerwa:',
    noNavAppFound: 'Nie znaleziono aplikacji nawigacyjnej'
  },
  worklog: {
    title: 'śledzony czas',
    absenceTitle: 'brak',
    workingTime: 'godziny pracy',
    breakDuration: 'Przerwa',
    reduce: 'ryczałt',
    attachments: 'Załączniki',
    comments: 'Uwagi',
    comment: 'komentarz',
    noLocation: 'Nie określono obiektu',
    changedBy: 'Czas został zmieniony z {{firstName}} na {{lastName}}.',
    confirmAttachmentDelete: 'Czy na pewno chcesz usunąć ten załącznik?',
    attachmentDeleted: 'Załącznik został usunięty',
    confirmCommentDelete: 'Czy na pewno chcesz usunąć ten komentarz?',
    commentDeleted: 'Komentarz został usunięty'
  },
  tabs: {
    home: 'Dom',
    times: 'czasy',
    chat: 'Czat',
    tickets: 'Bilety',
    more: 'więcej'
  },
  profile: {
    title: 'profil',
    testing: 'Testowanie',
    testingDescription: 'Obszar ten jest widoczny tylko po podłączeniu do systemu testowego.',
    testingAllowQrCodeOnWeb: 'Zezwalaj na ręczne wprowadzanie kodu QR',
    pushNotifications: 'powiadomienia push',
    reloadUserContext: 'Załaduj ponownie kontekst użytkownika',
    pushNotificationSuccessful: 'Powiadomienie push zostało odebrane pomyślnie',
    editProfilePicture: 'Zmień zdjęcie profilowe',
    editProfilePictureTitle: 'wybierz sekcję',
    pushNotificationNotice: 'Do testu wymagana jest autoryzacja „Edytuj ustawienia klienta”.',
    send: 'Wysłać',
    profilePicture: 'Zdjęcie profilowe'
  },
  help: { title: 'Pomoc' },
  myVacations: {
    vacation: 'Wakacje',
    amountAvailableVacationDays: 'Nadal dostępne jest {{amount}} dni urlopu',
    totalVacationDays: 'Prawo do urlopu {{ total }} dni',
    remainingVacationDays: '({{ total }} dni {{ sign }} {{ remaining }} pozostałych dni urlopu {{ previousYear }})',
    xDaysVacations: '{{ total }} dni urlopu',
    title: 'Twoje wakacje'
  },
  externalLinks: {
    title: 'Linki zewnętrzne',
    notice: 'Ten link został udostępniony przez Twoją firmę. Opuszczasz system Blink.'
  },
  stickers: {
    title: 'Twoje naklejki',
    sticker: 'naklejki',
    sender: 'Odbiorca',
    text: 'W sprawie',
    date: 'Data',
    emptyState: 'Niestety nie masz jeszcze żadnych naklejek',
    senderDescription: 'Ta naklejka została wysłana do Ciebie przez {{sender}} w dniu {{date}}',
    readed: 'Czytać',
    notReaded: 'jeszcze tego nie przeczytałem',
    newStickerToaster: 'Nowe naklejki mrugające!',
    open: 'otwarty',
    next: 'Dalej',
    titleCases: {
      case1: 'Gratulujemy pierwszej naklejki!',
      case2: 'Świetnie, teraz masz już naklejki <b>{{count}}</b>!',
      case3: 'Gratulujemy 10. naklejki!',
      case4: '30 naklejek – wow!',
      case5: 'Robi się tu tłoczno: naklejki <b>{{count}}</b>!'
    },
    confirmDelete: 'Czy na pewno chcesz usunąć te naklejki?'
  },
  autoTranslate: {
    isTranslated: 'Przetłumaczone automatycznie',
    translationError: 'Tłumaczenie automatyczne obecnie nie jest możliwe'
  },
  confirmLogout: 'Czy na pewno chcesz się wylogować?',
  message: 'Aktualności',
  migration: {
    wrongVersion: 'W aktualnej wersji aplikacji Blink me logowanie poprzez aplikację Blink Time nie jest obsługiwane. Zaktualizuj aplikację Blink me do najnowszej wersji'
  },
  eLearning: {
    pendingTab: 'Do zrobienia ({{value}})',
    completedTab: 'Gotowe ({{value}})',
    completed: 'Zakończony',
    pending: 'otwarty',
    dueFalling: 'Zaległy',
    title: 'E-learning',
    course: 'kurs',
    dueDate: 'Obowiązuje do',
    topics: 'tematy',
    completeDate: 'Zakończono',
    status: { Pending: 'otwarty', Done: 'zakończony' },
    infos: {
      pendingExists: 'Wszystkie otwarte szkolenia znajdziesz tutaj.',
      finishOverdue: 'Proszę o jak najszybsze zrealizowanie zaległego szkolenia.',
      allCompleted: 'Doskonały! Pomyślnie ukończyłeś wszystkie kursy szkoleniowe.',
      emptyState: 'Nie masz przypisanego szkolenia.'
    }
  },
  checklists: {
    title: 'Formularze',
    description: 'Wypełnij formularz dla obiektu',
    button: 'Wybierz obiekt'
  },
  pendingTab: 'Wykonanie ({{value}})',
  completedTab: 'Ukończono ({{value}})',
  workOrders: {
    title: 'Święcenia',
    homepageTitle: 'Twoje zamówienia',
    responsible: 'Odpowiedzialny',
    customerContact: 'Kontakt z klientem',
    trackedTime: 'Rejestracja czasu dla zamówienia nr{{value}}',
    show: 'Zobacz zamówienie',
    trackInfo: {
      confirmAndAcknowledge: 'Prosimy o potwierdzenie stanowisk po wykonaniu i przeprowadzenie oględzin na miejscu.',
      acknowledgeOnly: 'Prosimy o dokonanie oględzin na miejscu.',
      confirmOnly: 'Proszę o potwierdzenie stanowisk po wdrożeniu.'
    },
    pleaseConnectToLoad: 'Aby załadować zamówienia, należy nawiązać połączenie internetowe.',
    status: { new: 'Nowy', inProgress: 'Wykonanie', completed: 'Zakończony' },
    emptyState: 'Brak dostępnych otwartych zamówień.',
    emptyStateCompleted: 'Brak dostępnych zrealizowanych zamówień.',
    details: {
      title: 'Szczegóły zamówienia',
      positionsTab: 'Pozycje ({{value}})',
      acknowledgementsTab: 'maleje ({{value}})'
    },
    acknowledgement: {
      title: 'przyjęcie',
      start: 'Rozpocznij akceptację',
      acknowledgedAtDate: 'Usunięto',
      itemSelectDescription: 'Wybierz wszystkie pozycje do usunięcia.',
      customerInformationDescription: 'Prosimy o podanie danych kontaktowych Klienta (kupującego). Po akceptacji, na ten adres e-mail zostanie wysłane potwierdzenie.',
      created: 'Akceptacja została zapisana pomyślnie',
      selectAll: 'Zaznacz wszystko',
      emptyState: 'Nie ma żadnych przyjęć.'
    },
    items: {
      items: 'pozycje',
      acknowledge: 'Potwierdź pozycję',
      confirmAcknowledge: 'Niniejszym potwierdzasz, że praca została wykonana. Status stanowiska zostaje zaktualizowany na „Zakończone”.',
      status: {
        open: 'Otwarte',
        planned: 'Planowany',
        performed: 'Zrobione',
        acknowledged: 'REMOVED',
        billed: 'Rozliczono'
      },
      emptyState: 'Brak dostępnych stanowisk.'
    },
    trackTime: {
      description: 'Wybierz zadanie, dla którego chcesz śledzić czas.',
      chooseWorkOrder: 'Wybierz zamówienie',
      choosePosition: 'Wybierz pozycję'
    }
  }
};
