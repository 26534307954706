import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { barcodeScannerStyle } from "./barcode-scanner.style";
import { CapacitorPluginTranslateService } from "../i18n/capacitor-plugin-translate.service";
import { uiCancelButton, UiDialogService, uiPrimaryButton } from "@blink/ui";
import { TranslateService } from "@ngx-translate/core";
import { BarcodeScannerOptions } from "./barcode-scanner-options";
import { AuthModeEnum, isValidJson } from '@blink/util';
import { BarcodeFormat, BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';

@Injectable({
  providedIn: "root"
})
export class BarcodeScannerService {
  private barcodeScannerElId = "barcode-scanner";

  constructor(private uiDialogService: UiDialogService,
              private translate: TranslateService,
              private t: CapacitorPluginTranslateService,) {
    window["bss"] = this;
    this.addBarcodeScannerStyling();
  }

  private get appRootEl() {
    return window.document.querySelector("ion-app");
  }

  public async scan(options: BarcodeScannerOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!Capacitor.isNativePlatform()) {
        const result = prompt(this.translate.instant(
          this.t.barcodeScanner.enterPrompt,
          { thing: this.translate.instant(options.thingToScan) })
        );
        if (result) {
          resolve(this.removeUnusedCharacters(result));
        } else {
          reject();
        }
      } else {
        BarcodeScanner.checkPermissions().then(granted => {
          if (granted) {
            resolve(this.startScan(options));
          } else {
            return this.uiDialogService.confirm({
              title: this.t.barcodeScanner.permissionHeader,
              text: this.t.barcodeScanner.permissionMessage,
              buttons: [
                uiCancelButton(() => reject()),
                uiPrimaryButton(
                  this.t.global.proceed,
                  async () => {
                    await BarcodeScanner.openSettings();
                    if (await BarcodeScanner.checkPermissions()) {
                      resolve(this.startScan(options));
                    }
                    reject();
                  }
                )
              ]
            });
          }
        });
      }
    });
  }

  public scanBlinkId(): Promise<{ system: string, password: string, blinkId: string; authMode: AuthModeEnum }> {
    const options: BarcodeScannerOptions = {
      targetedFormats: [BarcodeFormat.QrCode],
      thingToScan: this.t.barcodeScanner.blinkId
    };
    return this.scan(options).then(result => {
      if (result && isValidJson(result)) {
        const data = JSON.parse(result.replace(/'/g, "\""));
        return {
          system: data.co,
          password: data.op,
          blinkId: data.id,
          authMode: AuthModeEnum.SIMPLE
        };
      } else {
        // this.logRepository.add(`Invalid BlinkId: ${result}`, true);
        return this.noBlinkIdError();
      }
    }).catch(() => {
      // this.logRepository.add(`Error reading BlinkId: ${err}`, true);
      return this.noBlinkIdError();
    });
  }

  public scanBlinkLocationCode() {
    const options: BarcodeScannerOptions = {
      targetedFormats: [BarcodeFormat.QrCode],
      thingToScan: this.t.barcodeScanner.objectCode
    };
    return this.scan(options).then(result => {
      if (!result || !this.isValidBlinkLocationQrCode(result)) {
        // this.logRepository.add(`Invalid Blink Location QrCode: ${result}`, true);
        return false;
      }
      return this.getLocationCodeFromBlinkLocationQrCode(result);
    });
  }

  private getLocationCodeFromBlinkLocationQrCode(scan) {
    return scan.substring(scan.indexOf("code=") + 5);
  }

  private isValidBlinkLocationQrCode(scan) {
    let code = scan;
    if (code) {
      if (code.indexOf("mobile.blink-time.de") > -1) {
        return true;
      } else {
        try {
          code = JSON.parse(scan);
          return (Boolean(code.lid) && Boolean(code.ln) && Boolean(code.cc));
        } catch (e) {
          return false;
        }
      }
    }
    return false;
  }

  private hideWebViewAndAppRoot() {
    this.appRootEl.classList.add("display-none");
    document.body.style.background = "black";
    setTimeout(() => document.body.style.background = "", 100);
  }

  private noBlinkIdError() {
    void this.uiDialogService.toast(this.t.barcodeScanner.isNoBlinkId);
    return null;
  }

  private async startScan(options: BarcodeScannerOptions) {
    return new Promise(resolve => {
      this.createBarcodeScannerElement(options);
      this.hideWebViewAndAppRoot();

      BarcodeScanner.addListener(
        'barcodeScanned',
        async result => {
          this.hideBarcodeScannerElement();
          await BarcodeScanner.stopScan();
          resolve(result.barcode ? this.removeUnusedCharacters(result.barcode.rawValue) : null);
        },
      );

      BarcodeScanner.startScan({
        formats: options?.targetedFormats
      });
    });
  }

  private createBarcodeScannerElement(options: BarcodeScannerOptions) {
    const barcodeScannerEl = document.createElement("div");
    barcodeScannerEl.id = this.barcodeScannerElId;
    barcodeScannerEl.setAttribute('name', this.barcodeScannerElId);

    const iconWrapperEl = document.createElement("div");
    iconWrapperEl.classList.add("barcode-scanner-icon-wrapper");

    const iconEl = document.createElement("i");
    iconEl.classList.add("fal", "fa-square-dashed");

    const promptEl = document.createElement("div");
    promptEl.classList.add("scan-prompt");
    promptEl.textContent = this.translate.instant(
      this.t.barcodeScanner.scanPrompt,
      { thing: this.translate.instant(options.thingToScan) }
    );

    iconWrapperEl.appendChild(iconEl);
    iconWrapperEl.appendChild(promptEl);


    const footerEl = document.createElement("div");
    footerEl.classList.add("barcode-scanner-footer");

    const cancelButtonEl = document.createElement("button");
    cancelButtonEl.classList.add("barcode-scanner-cancel");
    cancelButtonEl.textContent = this.translate.instant(this.t.global.cancel);
    cancelButtonEl.onclick = () => this.hideBarcodeScannerElement();


    footerEl.appendChild(cancelButtonEl);

    barcodeScannerEl.appendChild(iconWrapperEl);
    barcodeScannerEl.appendChild(footerEl);
    document.body.appendChild(barcodeScannerEl);
  }

  private hideBarcodeScannerElement() {
    if (this.appRootEl) {
      this.appRootEl.classList.remove("display-none");
    }

    //Done with while because there can be multiple barcode scanner elements
    while (document.getElementsByName(this.barcodeScannerElId).length > 0) {
      document.getElementsByName(this.barcodeScannerElId)[0].remove();
    }
  }

  private addBarcodeScannerStyling() {
    const barcodeStyleEl = document.createElement("style");
    barcodeStyleEl.textContent = barcodeScannerStyle;
    document.head.appendChild(barcodeStyleEl);
  }

  private removeUnusedCharacters(barcodeData: string): string {
    return barcodeData.replace('\\n', '')
      .replace('\n', '')
      .replace('\\r', '')
      .replace('\r', '');
  }
}
