import { NgModule } from '@angular/core';
import { UiLanguageSelectComponent } from './ui-language-select.component';
import { UiLanguageSelectPopoverComponent } from './ui-language-select-popover.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UtilModule } from '@blink/util';
import { UiIconModule } from '@blink/ui';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    UiLanguageSelectComponent,
    UiLanguageSelectPopoverComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    UtilModule,
    UiIconModule
  ],
  exports: [UiLanguageSelectComponent]
})
export class UiLanguageSelectModule {
}
