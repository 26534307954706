import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem, UiExternalLinkOptions } from '@blink/ui';
import { Observable } from 'rxjs';
import { BlinkCompany } from '@blink/shared-blink-types';
import { Capacitor } from '@capacitor/core';
import { Profile } from '@blink/api';

@Component({
  selector: 'blink-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
})
export class AppShellComponent {

  @Input() showMenu: boolean;
  @Input() uiMenuItems: MenuItem[] = [];
  @Input() showTabs: boolean;
  @Input() tabs: MenuItem[] = [];
  @Input() externalLinks: UiExternalLinkOptions[] = [];
  @Input() newVersionAvailable: boolean;
  @Input() companies$: Observable<BlinkCompany[]>;
  @Input() selectedCompanyId$: Observable<number>;
  @Input() loginProfiles$: Observable<Profile[]>;
  @Input() activeProfile$: Observable<Profile>;

  @Output() companySelected = new EventEmitter<any>();
  @Output() customMenuItemClicked = new EventEmitter<MenuItem>();
  @Output() loginProfileSelected = new EventEmitter<Profile>();


  get isLogin() {
    return location.href.includes('#/login');
  }

  showIosOffset(): boolean {
    return Capacitor.getPlatform() === 'ios'
  }
}
