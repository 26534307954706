import { Pipe, PipeTransform } from "@angular/core";
import dayjs from 'dayjs';

@Pipe({
  name: "day"
})
export class DayPipe implements PipeTransform {
  transform(value: string, format = 'DD') {
    return value ? format === 'week' ? dayjs(value).week() : dayjs(value).format(format) : null;
  }
}
