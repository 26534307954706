<ui-page [title]="t.appName"
         [menuButton]="true">
  <ui-card class="ion-padding">
    {{ t.global.hello | translate }} <b>{{ currentUserName }}</b>
  </ui-card>

  <ng-container *ngIf="(checklistsExists$ | async).length as checklistsExist; else noChecklistsExists">


    <check-select-checklist
      [showLocationSelectButton]="isOnline"
      [locationChecklistsExists$]="locationChecklistsExists$"
      [notStartedChecklists$]="notStartedChecklists$"
      [startedChecklists$]="startedChecklists$"
      (notStartedChecklistClick)="fillChecklistService.showCheckListDetail($event, true)"
      (startedChecklistClick)="fillChecklistService.showCheckListDetail($event, false)"
      (locationSelectButtonClick)="selectLocationChecklist()"
      (scanQrCode)="scanQrCode()">
    </check-select-checklist>

    <ui-card *ngIf="results$ | async as results">
      <ion-list class="checklistsSection" *ngIf="isOnline && results.length > 0">
        <ion-list-header>
          <ion-title>{{ fillCheckTranslate.home.checklistDatas_label | translate }}</ion-title>
        </ion-list-header>

        <bc-results-line-item *ngFor="let result of results"
                              [result]="result"
                              (reload)="fetchResults()">
        </bc-results-line-item>

        <ion-item button *ngIf="results.length > 0" [routerLink]="['/results']">
          <ion-title class="checkListDataShowAll">{{ fillCheckTranslate.home.showAll | translate }}</ion-title>
        </ion-item>

        <div class="spinner-container-small" *ngIf="resultsLoading">
          <ion-spinner color="primary"></ion-spinner>
        </div>

      </ion-list>
    </ui-card>
  </ng-container>

  <ng-template #noChecklistsExists>
    <div class="spinner-container-small"
         *ngIf="resultsLoading; else notLoading">
      <ion-spinner color="primary"></ion-spinner>
    </div>

    <ng-template #notLoading>
      <ui-empty-state>
        {{ t.noChecklists | translate }}
      </ui-empty-state>
    </ng-template>
  </ng-template>
</ui-page>

<!--<ng-container *ngIf="checklistLanguages$ | async as languages">-->
<!--  <ion-footer *ngIf="languages.length > 1">-->
<!--    <ion-toolbar>-->
<!--      <ion-buttons class="language-select ion-justify-content-center">-->
<!--        <ion-button *ngFor="let language of languages"-->
<!--                    (click)="setChecklistsLanguage(language)"-->
<!--                    [class.selected-language]="(selectedLanguage$ | async) === language">-->
<!--          <img src="assets/imgs/language-flags/flag_{{language}}.png">-->
<!--        </ion-button>-->
<!--      </ion-buttons>-->
<!--    </ion-toolbar>-->
<!--  </ion-footer>-->
<!--</ng-container>-->
