import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlinkLanguage } from '@blink/shared-blink-types';
import { BlinkLanguageRepository, BlinkTranslateApi, DeeplRequest } from '@blink/api';
import { GlobalTranslateService } from '@blink/util';
import { UiTranslateFormConfig, UiTranslateFormTranslation } from './ui-translate-form-translation';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { BlinkIcon, UiLoadingService } from '@blink/ui';
import { QuillModules } from 'ngx-quill';

@Component({
  selector: 'ui-translate-form',
  templateUrl: './ui-translate-form.component.html',
  styleUrl: './ui-translate-form.component.scss'
})
export class UiTranslateFormComponent<T extends { LanguageId: number }> {
  protected readonly BlinkIcon = BlinkIcon;

  @Input() enabled = true;

  @Input() config: UiTranslateFormConfig;

  // @Input() set config(config: UiTranslateFormConfig) {
  //   this._config = config;
  //   // this.init();
  // }
  //
  // get config() {
  //   return this._config;
  // }

  _translations: T[];

  @Input() set translations(translations: T[]) {
    console.log('set los translationos')
    this._translations = translations.filter(t => t.LanguageId !== 1);
    this.init();
  }

  get translations() {
    return [...this._translations];
  }

  @Input() additionalTranslations: { [key: number]: any[] };

  allLanguages: BlinkLanguage[];
  languageSelectList: { id: number, name: string, language: BlinkLanguage }[] = [];
  selectedLanguageIds: number[] = [];

  internalTranslations: UiTranslateFormTranslation[] = [];

  quilModules: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      ['clean']                                         // remove formatting button
    ]
  };

  @Output() translationAdded = new EventEmitter();
  @Output() translationRemoved = new EventEmitter();

  private isValidSubject = new ReplaySubject<boolean>(1);

  updateIsValid() {
    const isValid = this.internalTranslations.every(it =>
      it.items.every(i => !i.config.required || i.translatedText)
      && (!this.additionalTranslations || this.additionalTranslations[it.language.Id]?.every(at => !!at['Name']))
    );
    this.isValidSubject.next(isValid);
  }

  isValid$ = this.isValidSubject.asObservable();

  identifyTranslation(index: number, item: UiTranslateFormTranslation) {
    return item.language?.Id;
  }

  constructor(private languageRepository: BlinkLanguageRepository,
              private blinkTranslateApi: BlinkTranslateApi,
              private loadingService: UiLoadingService,
              public t: GlobalTranslateService) {
    (window as any).UiTranslateForm = this;
  }

  async init() {
    const languages = await firstValueFrom(this.languageRepository.languages$)
    this.allLanguages = languages.filter(l => l.Code !== 'de');
    this.languageSelectList = this.allLanguages
      .map(l => ({
        name: l.Name,
        id: l.Id,
        language: l
      }));

    const allTranslations = [...this.translations];


    this.config.availableLanguageIds?.forEach(avaiableLanguageId => {
      if (!this.translations.find(t => t.LanguageId === avaiableLanguageId) && avaiableLanguageId !== 1) {
        // const language = this.allLanguages.find(al => al.Id === avaiableLanguageId)
        if (!this.translations) {
          this.translations = [];
        }
        const originTranslation: any = {
          LanguageId: avaiableLanguageId
        };
        this.config.items.forEach(ci => {
          originTranslation[ci.property] = '';
        });
        allTranslations.push(originTranslation);
      }
    })

    console.log('allTranslations', allTranslations);


    this.internalTranslations = allTranslations.map(it => {
      return {
        language: this.allLanguages.find(l => l.Id === it.LanguageId),
        items: this.config.items.map(ci => ({
          config: ci,
          translatedText: it[ci.property]
        }))
      }
    });
    this.selectedLanguageIds = this.internalTranslations.map(t => t.language.Id);
    this.updateIsValid();
  }

  resetTranslations(properties: string[], translateAutomatically = false) {
    console.log('ui translate bums reset ', properties);
    properties.forEach(property => this.resetTranslation(property));
    if (translateAutomatically) {
      this.translateAutomatically();
    }
  }

  resetTranslation(property: string) {
    this.internalTranslations = this.internalTranslations.map(it => ({
      ...it,
      items: it.items.map(iti => {

        const item = { ...iti };
        if (item.config.property === property) {
          item.translatedText = '';
        }

        return item;

      })
    }))
  }

  addTranslationFor(lang: { id: number; name: string; language: BlinkLanguage; }, translateAutomatically = true) {
    console.log('adTranslation', lang);
    const newTranslation: UiTranslateFormTranslation = {
      language: lang.language,
      items: this.config.items.map(ci => ({
        config: ci,
        translatedText: ''
      }))
    };
    this.internalTranslations.push(newTranslation);
    this.translationAdded.emit();
    this.updateIsValid();
    if (translateAutomatically) {
      this.translateAutomatically();
    }
  }

  selectAllLanguages() {
    const addableLanguages = this.allLanguages.filter(
      al => !this.internalTranslations.some(t => al.Id === t.language.Id)
    );
    const selectedLanguageIds = [...this.selectedLanguageIds]
    addableLanguages.forEach(l => {
      selectedLanguageIds.push(l.Id);
      this.addTranslationFor({ id: l.Id, name: l.Name, language: l }, false);
    });
    this.selectedLanguageIds = selectedLanguageIds;
    this.translateAutomatically();
  }


  translateAutomatically() {
    const translateRequest: DeeplRequest = {
      TranslateFrom: 'de',
      Texts: this.config.items.map(ci => ci.referenceText()),
      TranslateTo: []
    }

    this.internalTranslations.forEach(t => {
      translateRequest.TranslateTo.push(t.language.Code);
      if (this.additionalTranslations) {
        this.additionalTranslations[t.language.Id]?.forEach(vt => {
          translateRequest.Texts.push(vt.referenceName);
        });
      }
    });
    translateRequest.Texts = translateRequest.Texts.filter(t => !!t);
    this.loadingService.showReadLoaderUntilCompleted(this.blinkTranslateApi.multiTranslate(translateRequest))
      .subscribe(translated => {
        const findTranslation = (code: string, text: string) =>
          translated.find(ti => ti.OriginalText === text)
            ?.Translations.find(t => t.TranslatedTo === code)
            ?.Text;
        this.internalTranslations = this.internalTranslations.map(translation => {
          translation = { ...translation };
          translation.items = translation.items.map(ti => {
            const newTranslation = findTranslation(translation.language.Code, ti.config.referenceText());
            return {
              ...ti,
              translatedText: ti.translatedText || newTranslation || ''
            }
          });
          if (this.additionalTranslations) {
            this.additionalTranslations[translation.language.Id] =
              this.additionalTranslations[translation.language.Id].map(vt => ({
                ...vt,
                Name: vt.Name || findTranslation(translation.language.Code, vt.referenceName)
              }));
          }
          return translation;
        })
      })
  }

  removeAllTranslations() {
    this.selectedLanguageIds = [];
    this.internalTranslations = [];
    if (this.additionalTranslations) {
      this.additionalTranslations = {};
    }
    console.log('additional', this.additionalTranslations)
    this.translationRemoved.emit();
    this.updateIsValid();
  }

  removeTranslationFor(lang: { id: number }) {
    const translation = this.internalTranslations
      .find(t => t.language.Id === lang.id);
    this.removeTranslation(translation);
  }

  removeTranslation(translation: UiTranslateFormTranslation) {
    this.selectedLanguageIds = this.selectedLanguageIds
      .filter(lId => lId !== translation.language.Id);
    this.internalTranslations = this.internalTranslations
      .filter(t => t.language.Code !== translation.language.Code);
    this.translationRemoved.emit(translation.language.Id);
    this.updateIsValid();
  }

  getTranslations(): T[] {
    return this.internalTranslations.map(t => {
      const originTranslation: any = {
        LanguageId: t.language.Id
      };
      this.config.items.forEach(ci => {
        const translatedItem = t.items.find(i => i.config.property === ci.property);
        originTranslation[ci.property] = translatedItem?.translatedText || '';
      });
      return originTranslation;
    })
  }
}
