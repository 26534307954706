import { Injectable } from '@angular/core';
import { AlertController, AlertInput, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { UiButton, uiCancelButton } from '../shared/button';
import { UiDialogConfirmComponent } from './ui-dialog-confirm/ui-dialog-confirm.component';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UiDialogFormComponent } from './ui-dialog-form/ui-dialog-form.component';
import { TranslateService } from '@ngx-translate/core';
import { UiFileTransferProgress } from './ui-dialog-file-transfer/file-transfer-progress';
import { UiDialogFileTransferProgressComponent } from './ui-dialog-file-transfer/file-transfer-progress.component';
import { BehaviorSubject } from 'rxjs';
import { UiDialogPdfComponent } from './ui-dialog-pdf/ui-dialog-pdf.component';
import { GlobalTranslateService } from '@blink/util';
import { AbstractControl } from '@angular/forms';
import * as dayjs from 'dayjs';
import { BlinkIcon } from '../ui-icon';
import { UiDialogPopoverComponent } from './ui-dialog-popover/ui-dialog-popover.component';
import { UiHandlerAction } from '../shared/action';
import { UiDialogSignatureComponent } from './ui-dialog-signature/ui-dialog-signature.component';

export interface ConfirmOptions {
  header: string;
  message: string;
  confirm?: string;
  inputs?: AlertInput[]
}

export interface UiConfirmDialogOptions {
  title: string;
  text: string;
  /**
   * Object will be passed to translate as args
   */
  textOptions?: { [key: string]: string };
  buttons: UiButton[];
  utilDataTestId?: string;
  backOptions?: {
    showBackButton: boolean;
    backButtonIcon: BlinkIcon;
    backButtonClick: () => void;
  }
}

export interface UiFormDialogOptions {
  title: string;
  fields: FormlyFieldConfig[];
  model?: any;
  small?: boolean;
  action: UiButton;
}

export function startAndEndDateValidator(control: AbstractControl) {
  const { startDate, endDate } = control.value;

  // avoid displaying the message error when values are empty
  if (!startDate || !endDate) {
    return null;
  }

  const dayStartDate = dayjs(startDate);
  const dayEndDate = dayjs(endDate);

  if (dayStartDate.isBefore(dayEndDate) || dayStartDate.isSame(dayEndDate, 'day')) {
    return null;
  }

  return { startAndEndDate: { message: 'Start date should be before end date' } };
}

@Injectable({
  providedIn: 'root'
})
export class UiDialogService {

  constructor(private modalController: ModalController,
              private toastController: ToastController,
              private alertController: AlertController,
              private translate: TranslateService,
              private t: GlobalTranslateService,
              private popoverController: PopoverController) {
  }

  confirm(options: UiConfirmDialogOptions) {
    this.modalController.create({
      component: UiDialogConfirmComponent,
      componentProps: { options },
      backdropDismiss: false,
      cssClass: 'ui-modal-auto-height ui-modal-small'
    }).then(modal => modal.present());
  }

  alert(message: string, header?: string, handler?: () => void) {

    // This only works if there are ~200ms between to calls of alert
    // cause of the asynchronous nature of alert it would be required to
    // refactor it with async/await and await all calls in the application
    this.alertController.getTop().then(async (alert) => {
      if (alert) {
        await alert.dismiss();
      }
    });

    this.alertController.create({
        header: this.translate.instant(header),
        message: this.translate.instant(message),
        buttons: [{
          text: this.translate.instant('typedGlobal.ok'),
          handler: handler
        }],
        backdropDismiss: false
      }
    ).then(alert => alert.present());
  }

  form(options: UiFormDialogOptions) {
    const cssClasses = ['ui-modal-auto-height'];
    if (options.small) {
      cssClasses.push('ui-modal-small');
    }

    this.modalController.create({
      component: UiDialogFormComponent,
      componentProps: { options },
      backdropDismiss: false,
      cssClass: cssClasses.join(' ')
    }).then(modal => modal.present());
  }

  fileTransferProgress(progresses: UiFileTransferProgress[]) {
    const progressSubject = new BehaviorSubject<UiFileTransferProgress[]>(progresses);

    this.modalController.create({
      component: UiDialogFileTransferProgressComponent,
      componentProps: {
        progress$: progressSubject.asObservable()
      },
      backdropDismiss: false,
      cssClass: 'ui-modal-auto-height ui-modal-small'
    }).then(modal => modal.present());

    return (fileName: string, bytes: number, loadedBytes: number) => {
      const vms = progressSubject.getValue();
      const index = vms.findIndex(x => {
        return x.fileName === fileName
      });
      vms[index] = { fileName, bytes, loadedBytes };

      progressSubject.next(vms);
    };
  }

  showPdf(base64Src: string, title = 'PDF', showDownloadButton = false) {
    this.modalController.create({
      component: UiDialogPdfComponent,
      componentProps: {
        base64Src,
        title,
        showDownloadButton,
        languageKey: this.t.getCurrentLanguageKey()
      },
      cssClass: 'ui-modal-full'
    }).then(modal => modal.present());
  }

  async openSignatureModal(): Promise<string | null> {
    const modal = await this.modalController.create({
      component: UiDialogSignatureComponent
    });
    void modal.present();
    const signature = await modal.onWillDismiss();

    if (signature) {
      return signature.data;
    }
    return null;
  }

  toast(message: string, color: 'dark' | 'success' | 'danger' = 'dark') {
    return this.toastController.create({
      message: this.translate.instant(message),
      duration: 2500,
      color: color,
      cssClass: 'me-toast-custom-class'
    }).then(toast => toast.present());
  }

  showOfflineInfo() {
    this.confirm({
      title: this.t.offline,
      text: this.t.youAreOffline,
      buttons: [uiCancelButton()]
    })
  }

  errorOccurred(errorCode = 0) {
    console.log('error occurred, errorCode: ' + errorCode);
    this.alert(
      this.translate.instant(errorCode ? `typedGlobal.ERROR.ERROR_${errorCode}` : 'typedGlobal.ERROR_OCCURRED'),
      this.translate.instant('typedGlobal.error')
    );
  }

  async quickActions(e: Event, quickActions: UiHandlerAction<null>[], showBackdrop = true) {
    return this.popoverController.create({
      component: UiDialogPopoverComponent,
      componentProps: {
        quickActions
      },
      event: e,
      showBackdrop,
      animated: true
    }).then(async (popover) => {
      await popover.present();
      return new Promise(resolve => {
        popover.onDidDismiss().then((response) => {
          if (response.role === 'handled') {
            resolve(response.data);
          }
        });
      });
    });
  }

  openContextMenu(ev, pageObject: { page: string, id: number }) {
    const openInTab = () => {
      const url = `${window.location.origin}/#/${pageObject.page}/${pageObject.id}`;
      window.open(url, '_blank');
    }

    this.quickActions(ev, [{
      label: 'typedGlobal.contextMenu.openInNewTab',
      handler: () => openInTab()
    }])

    ev.preventDefault();
  }
}
