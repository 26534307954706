import { Component, Input, OnInit } from '@angular/core';
import { BlinkLanguageRepository } from '@blink/api';
import { BlinkLanguage } from '@blink/shared-blink-types';
import { Observable } from 'rxjs';

@Component({
  selector: 'ui-language-flag',
  template: `
    <img *ngIf="language$ | async as language" src="assets/imgs/language-flags/flag_{{language?.Code}}.png"
         alt="{{language.Name}} flag"
    />`,
  styles: `
    :host {
      display: inline-block;
      width: 25px;
      height: 25px;
    }
  `
})
export class UiLanguageFlagComponent implements OnInit{
  @Input() languageId: number;

  language$: Observable<BlinkLanguage>;

  constructor(private languageRepository: BlinkLanguageRepository) {
  }

  ngOnInit() {
    this.language$ = this.languageRepository.language$(this.languageId);
  }
}
